import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import ImageUpload from "./ImageUpload";

const FormControlImageUpload = ({
  id,
  label,
  image,
  setImage,
  labelTitle,
  isRequired,
  trackingNumber,
}) => {
  console.log("image", image);

  return (
    <>
      <FormControl mb={0} isRequired={isRequired}>
        <FormLabel fontSize="sm">{label}</FormLabel>
        <ImageUpload
          id={id}
          image={image}
          setImage={setImage}
          label={labelTitle}
          trackingNumber={trackingNumber ? trackingNumber : ""}
        />
      </FormControl>
    </>
  );
};

export default FormControlImageUpload;
