import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../../../../landing/layout/Header";
import { useNavigate } from "react-router-dom";
import { getUserRegisteredCoursesPublicAction } from "../../../../../actions/userActions";
import {
  Spinner,
  Flex,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useToast,
  Avatar,
  Tooltip,
  Box,
  Icon,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  backgroundLight,
  newBorder,
  newDarkBlue,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import noServices from "../../../../../assets/images/empty-box.png";
import AccountTabs from "../../partials/AccountTabs";
import { shade } from "polished";
import Faq from "../../../../general/partials/Faq";
import Footer from "../../../../landing/layout/Footer";
import StatusButton from "../../../../../components/buttons/StatusButton";
// import MoreButton from "../../../../../components/buttons/MoreButton";
import { useMediaQuery } from "react-responsive";
import { FaInfo } from "react-icons/fa6";
import { TbHash, TbSchool, TbUser } from "react-icons/tb";
import moment from "moment";

const UserEvents = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // STATE
  const [isEmployee, setIsEmployee] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State to track the active tab index

  const [courses, setCourses] = useState([]);
  const [adjustedCourses, setAdjustedCourses] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userRegisteredCoursesPublic = useSelector(
    (state) => state.userRegisteredCoursesPublic
  );
  const {
    loading: loadingCourses,
    error: errorCourses,
    coursesRegistered,
  } = userRegisteredCoursesPublic;

  // TABS

  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // HANDLERS
  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(tabs[index].path);
  };

  // USE EFFECT

  useEffect(() => {
    // console.log("HELLO");
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserRegisteredCoursesPublicAction(userInfo._id));

      setCourses(coursesRegistered);
    }
  }, [userInfo]);

  useEffect(() => {
    if (errorCourses) {
      toast({
        title: "Error",
        description: errorCourses,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [errorCourses]);

  useEffect(() => {
    if (coursesRegistered && coursesRegistered.length > 0) {
      const userOffsetMinutes = new Date().getTimezoneOffset();

      const adjusted = coursesRegistered.map((course) => {
        const adjustedStartDate = moment(course.course.startDate)
          .add(userOffsetMinutes, "minutes")
          .toDate();
        const adjustedEndDate = moment(course.course.endDate)
          .add(userOffsetMinutes, "minutes")
          .toDate();

        return { ...course, adjustedStartDate, adjustedEndDate };
      });

      setAdjustedCourses(adjusted);
    }
  }, [coursesRegistered]);

  // CUSTOM
  const isSameDay = (start, end) => {
    return dayjs(start).isSame(dayjs(end), "day");
  };

  const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
    return (
      <Flex align="center">
        <Flex
          w="1.25rem"
          h="1.25rem"
          align="center"
          justify="center"
          bg={white}
          mr={2}
          borderRadius="50%"
          flexShrink="0"
        >
          <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
        </Flex>
        {text && (
          <Text
            fontSize="sm"
            fontWeight="500"
            textTransform={capitalize ? "capitalize" : "none"}
            opacity={0.6}
            mr={1}
            whiteSpace="nowrap"
          >
            {text}:
          </Text>
        )}

        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          maxW={maxW}
          isTruncated={maxW ? true : false}
        >
          {label}
        </Text>
      </Flex>
    );
  };

  // LOGS

  // console.log("coursesRegistered", coursesRegistered);

  // console.log("courses", courses);

  // console.log("adjustedCourses", adjustedCourses);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountEvents")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountEvents")}
        />
      </Helmet>

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        <AccountTabs tabs={tabs} />

        {!isEmployee ? (
          <>
            <Flex
              w="100%"
              justify="center"
              gap="2rem"
              p={["3rem 0 3rem", "3rem 0 3rem", "3rem 0 0"]}
              bg={backgroundLight}
            >
              <Flex
                w="100%"
                maxW={["100%", "100%", "1200px"]}
                p={["0 1.5rem", "0 1.5rem", "0 0rem"]}
                direction="column"
                m="0rem auto 0"
                minH={["initial", "initial", "80vh"]}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  minH={["initial", "initial", "3rem"]}
                >
                  <Heading
                    fontSize="1.125rem"
                    fontWeight="600"
                    color={textPrimary}
                  >
                    {userInfo && userInfo.clientType === "individual"
                      ? t("clientAccount.myEvents")
                      : t("clientAccount.myEventsCompany")}{" "}
                    <Text as="span" fontWeight="500">
                      ({coursesRegistered.length})
                    </Text>
                  </Heading>

                  <Flex></Flex>
                </Flex>

                {loadingCourses ? (
                  <Flex
                    w="100%"
                    p={["8rem 0", "8rem 0", "8rem 0"]}
                    align="center"
                    justify="center"
                    bg={white}
                    borderRadius="1rem"
                    minH="60vh"
                  >
                    <Spinner size="xl" color={primary} />
                  </Flex>
                ) : (
                  <Stack gap="1rem" w="100%" m="1rem 0">
                    {adjustedCourses &&
                      adjustedCourses !== undefined &&
                      adjustedCourses.map((course) => (
                        <Flex
                          key={course && course._id && course._id}
                          bg={white}
                          w="100%"
                          p={["1rem 1rem", "1rem 1rem", "1rem 2rem"]}
                          h={["initial", "initial", "90px"]}
                          borderRadius="1rem"
                          align="center"
                          justify="space-between"
                          direction={["column", "column", "row"]}
                          transition="all 0.2s"
                          onClick={() => {
                            navigate(
                              `/account/events/${
                                course && course._id && course._id
                              }`
                            );
                          }}
                          _hover={{
                            cursor: "pointer",
                            shadow: "sm",
                            transform: "scale(1.02)",
                          }}
                        >
                          {!isMobile && (
                            <Flex
                              align="center"
                              w={["100%", "100%", "initial"]}
                            >
                              <Stack
                                gap="0.25rem"
                                mr={["1rem", "1rem", "3.5rem"]}
                                //  w="130px"
                              >
                                <Heading
                                  fontSize={["xs", "xs", "sm"]}
                                  fontWeight="600"
                                  textTransform="capitalize"
                                  color={textPrimary}
                                >
                                  {isSameDay(
                                    course.adjustedStartDate,
                                    course.adjustedEndDate
                                  )
                                    ? dayjs(course.adjustedStartDate).format(
                                        "MMMM D"
                                      )
                                    : `${dayjs(course.adjustedStartDate).format(
                                        "MMM D"
                                      )} - ${dayjs(
                                        course.adjustedEndDate
                                      ).format("MMM D")}`}
                                </Heading>

                                <Text
                                  fontSize="sm"
                                  color={textSecondary}
                                  fontWeight="500"
                                >
                                  {dayjs(course.adjustedStartDate).format(
                                    "YYYY"
                                  )}
                                </Text>
                              </Stack>

                              <Flex
                                w="48px"
                                h="48px"
                                borderRadius="1rem"
                                overflow="hidden"
                                mr="1rem"
                                flexShrink="0"
                                display={["none", "none", "flex"]}
                              >
                                <Image
                                  w="100%"
                                  h="100%"
                                  objectFit="cover"
                                  src={
                                    course &&
                                    course.course &&
                                    course.course.image &&
                                    course.course.image
                                  }
                                  alt={
                                    course &&
                                    course.course &&
                                    course.course.title &&
                                    course.course.title
                                  }
                                />
                              </Flex>

                              <Flex gap="0.25rem" direction="column">
                                {userInfo &&
                                userInfo.clientType === "individual" ? (
                                  <Heading
                                    fontSize="15px"
                                    fontWeight="600"
                                    maxW={["100px", "100px", "initial"]}
                                    isTruncated
                                  >
                                    {course &&
                                      course.course &&
                                      course.course.title &&
                                      course.course.title}
                                  </Heading>
                                ) : (
                                  <Heading
                                    fontSize="sm"
                                    fontWeight="600"
                                    maxW={["100px", "100px", "initial"]}
                                    isTruncated
                                  >
                                    {course.attendeeFirstName +
                                      " " +
                                      course.attendeeLastName}
                                  </Heading>
                                )}

                                {userInfo &&
                                userInfo.clientType === "individual" ? (
                                  <Text
                                    color={textSecondary}
                                    fontSize={["xs", "xs", "sm"]}
                                    fontWeight="500"
                                    maxW={["100px", "100px", "initial"]}
                                    isTruncated
                                  >
                                    {isMobile
                                      ? ""
                                      : t("common.attendee") + ": "}
                                    <Text
                                      as="span"
                                      color={primary}
                                      fontWeight="500"
                                      fontSize={["xs", "xs", "sm"]}
                                    >
                                      {course.attendeeFirstName +
                                        " " +
                                        course.attendeeLastName}
                                    </Text>
                                  </Text>
                                ) : (
                                  <Text
                                    color={textSecondary}
                                    fontSize={["xs", "xs", "sm"]}
                                    fontWeight="500"
                                    maxW={["100px", "100px", "initial"]}
                                    isTruncated
                                  >
                                    {isMobile ? "" : t("course.course") + ": "}
                                    <Text
                                      as="span"
                                      color={primary}
                                      fontWeight="500"
                                      fontSize={["xs", "xs", "sm"]}
                                    >
                                      {course &&
                                        course.course &&
                                        course.course.title &&
                                        course.course.title}
                                    </Text>
                                  </Text>
                                )}
                              </Flex>
                            </Flex>
                          )}

                          {!isMobile && (
                            <Flex align="center">
                              <Flex
                                gap="0.25rem"
                                direction="column"
                                mr={[0, 0, "2rem"]}
                                display={["none", "none", "flex"]}
                              >
                                <Text
                                  color={textSecondary}
                                  fontSize="xs"
                                  fontWeight="500"
                                >
                                  {t("course.speakers")}:
                                </Text>
                                <Flex>
                                  {course.course.speakers.length > 1 ? (
                                    <Flex
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      position="relative"
                                      pl="1rem"
                                      minW="150px"
                                    >
                                      {course.course.speakers.map((speaker) => (
                                        <Box
                                          marginLeft="-0.75rem"
                                          key={speaker._id}
                                        >
                                          <Tooltip
                                            bg={newDarkBlue}
                                            p={4}
                                            borderRadius="1rem"
                                            label={
                                              <Text
                                                fontSize="sm"
                                                fontWeight="500"
                                                color={white}
                                              >
                                                {speaker.firstName +
                                                  " " +
                                                  speaker.lastName}
                                              </Text>
                                            }
                                            key={speaker._id}
                                          >
                                            <Avatar
                                              w="1.5rem"
                                              h="1.5rem"
                                              src={speaker.image || ""}
                                              name={
                                                speaker.firstName +
                                                " " +
                                                speaker.lastName
                                              }
                                              border={`1px solid ${newBorder}`}
                                            />
                                          </Tooltip>
                                        </Box>
                                      ))}
                                    </Flex>
                                  ) : course.course.speakers.length === 1 ? (
                                    <Flex alignItems="center" minW="150px">
                                      <Avatar
                                        h="1.5rem"
                                        w="1.5rem"
                                        src={
                                          course.course.speakers[0].image || ""
                                        }
                                        name={
                                          course.course.speakers.firstName +
                                          " " +
                                          course.course.speakers[0].lastName
                                        }
                                        border={`1px solid ${newBorder}`}
                                      />
                                      <Text
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textSecondary}
                                        ml={2}
                                        isTruncated // This will add ellipsis
                                        maxW="110px"
                                        noOfLines={1} // This will limit the text to two lines
                                        whiteSpace="normal"
                                      >
                                        {course &&
                                          course.course.speakers &&
                                          course.course.speakers &&
                                          course.course.speakers[0].firstName &&
                                          course.course.speakers[0]
                                            .firstName[0] +
                                            "." +
                                            course.course.speakers[0].lastName}
                                      </Text>
                                    </Flex>
                                  ) : (
                                    // You can render a "No Data" component or Lottie animation here
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                    >
                                      -
                                    </Text>
                                  )}
                                </Flex>
                              </Flex>

                              <Stack
                                gap="0.25rem"
                                mr="3rem"
                                display={["none", "none", "flex"]}
                              >
                                <Text
                                  color={textSecondary}
                                  fontSize="xs"
                                  fontWeight="500"
                                  m="0 0 0.25rem"
                                >
                                  {t("common.priceAttendee")}:
                                </Text>

                                <Text
                                  color={textSecondary}
                                  fontSize="sm"
                                  fontWeight="500"
                                >
                                  {course && course.price && course.price !== 0
                                    ? course.price + " €"
                                    : t("common.free")}
                                </Text>
                              </Stack>
                              {/* {course.status} */}

                              <Flex gap="1rem" align="center">
                                <Tooltip
                                  p="1rem 1rem"
                                  label={
                                    <Flex direction="column" gap="0.5rem">
                                      <TooltipEntry
                                        label={course.course.title}
                                        icon={TbSchool}
                                        capitalize
                                      />
                                      <TooltipEntry
                                        label={
                                          course.attendeeFirstName +
                                          " " +
                                          course.attendeeLastName
                                        }
                                        icon={TbUser}
                                        capitalize
                                      />

                                      <Flex
                                        w="100%"
                                        h="1px"
                                        bg={textSecondary}
                                        m="0.25rem 0"
                                      />

                                      <TooltipEntry
                                        label={t(`status.${course.status}`)}
                                        icon={TbUser}
                                        maxW="160px"
                                        text={t("clientAccount.attendeeStatus")}
                                      />

                                      <TooltipEntry
                                        label={dayjs(course.updatedAt).format(
                                          "YYYY-MM-DD, HH:mm"
                                        )}
                                        icon={TbUser}
                                        maxW="250px"
                                        text={t("common.statusUpdatedAt")}
                                      />

                                      {course &&
                                        course.cancelReason &&
                                        course.cancelReason !== null && (
                                          <TooltipEntry
                                            label={
                                              course &&
                                              course.cancelReason &&
                                              course.cancelReason
                                            }
                                            icon={TbUser}
                                            maxW="160px"
                                            text={t(
                                              "implantFailureReports.cancelReason"
                                            )}
                                          />
                                        )}
                                    </Flex>
                                  }
                                  borderRadius="1rem"
                                >
                                  <Button
                                    h="2.5rem"
                                    w="2.5rem"
                                    bg={backgroundLight}
                                    border={`1px solid ${newBorder}`}
                                    borderRadius="0.875rem"
                                    display={["none", "none", "flex"]}
                                  >
                                    <Icon
                                      as={FaInfo}
                                      fontSize="0.75rem"
                                      color={textSecondary}
                                    />
                                  </Button>
                                </Tooltip>
                                <StatusButton
                                  isUnclickable
                                  currentStatus={course && course.status}
                                  options="attendees"
                                />
                              </Flex>
                            </Flex>
                          )}

                          {/* MOBILE START */}

                          {isMobile && (
                            <Flex w="100%" direction="column">
                              <Flex
                                w="100%"
                                justify="space-between"
                                direction="column"
                              >
                                <Flex
                                  w="100%"
                                  borderRadius="1rem"
                                  overflow="hidden"
                                  flexShrink="0"
                                >
                                  <Image
                                    w="100%"
                                    h="100%"
                                    objectFit="cover"
                                    src={
                                      course &&
                                      course.course &&
                                      course.course.image &&
                                      course.course.image
                                    }
                                    // src="https://www.mymegagen.com/uploads/image-1725954074450.png"
                                    alt={
                                      course &&
                                      course.course &&
                                      course.course.title &&
                                      course.course.title
                                    }
                                  />
                                </Flex>
                                <Flex
                                  w="100%"
                                  direction="column"
                                  p="0 0 0 1rem"
                                  gap="0.5rem"
                                >
                                  <Flex w="100%" p="1.5rem 0 0">
                                    <Text
                                      fontSize="1.125rem"
                                      fontWeight="600"
                                      color={textPrimary}
                                      lineHeight="1.2"
                                    >
                                      {course &&
                                        course.course &&
                                        course.course.title &&
                                        course.course.title}
                                    </Text>
                                  </Flex>

                                  <Flex w="100%" p="1rem 0 0">
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      textTransform="capitalize"
                                      mr={2}
                                    >
                                      {t("publicCourses.date")}:{" "}
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textPrimary}
                                      textTransform="capitalize"
                                    >
                                      {dayjs(
                                        course &&
                                          course.course &&
                                          course.course.startDate &&
                                          course.course.startDate
                                      ).format("D MMMM, YYYY")}{" "}
                                    </Text>
                                  </Flex>

                                  <Flex w="100%">
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      textTransform="capitalize"
                                      mr={2}
                                    >
                                      {t("common.attendee")}:{" "}
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textPrimary}
                                    >
                                      {course.attendeeFirstName +
                                        " " +
                                        course.attendeeLastName}
                                    </Text>
                                  </Flex>

                                  <Flex w="100%">
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      mr={2}
                                    >
                                      {t("form.email")}:{" "}
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textPrimary}
                                      maxW="200px"
                                      isTruncated
                                    >
                                      {course.attendeeEmail}
                                    </Text>
                                  </Flex>

                                  <Flex w="100%">
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      mr={2}
                                    >
                                      {t("attendee.price")}:{" "}
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textPrimary}
                                      maxW="200px"
                                      isTruncated
                                    >
                                      {course &&
                                      course.price &&
                                      course.price !== 0
                                        ? course.price + " €"
                                        : t("common.free")}
                                    </Text>
                                  </Flex>

                                  <Flex w="100%" p="1rem 0 1rem">
                                    <Box m="0 auto">
                                      <Text
                                        fontSize="sm"
                                        fontWeight="500"
                                        textAlign="center"
                                        m="0 auto 0.25rembg "
                                        color={textSecondary}
                                      >
                                        {t("clientAccount.attendeeStatus")}:
                                      </Text>
                                      <StatusButton
                                        isUnclickable
                                        currentStatus={course && course.status}
                                        options="attendees"
                                      />
                                    </Box>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                          )}

                          {/* MOBILE END */}
                        </Flex>
                      ))}
                  </Stack>
                )}

                {coursesRegistered &&
                  coursesRegistered.length === 0 &&
                  !loadingCourses && (
                    <Flex
                      w="100%"
                      align="center"
                      justify="center"
                      p="6rem 0"
                      bg={white}
                      borderRadius="1rem"
                      direction="column"
                      border={`0px solid ${newBorder}`}
                    >
                      <Flex
                        justify="center"
                        align="center"
                        direction="column"
                        p="3rem 0"
                      >
                        <Image
                          h="4rem"
                          w="auto"
                          m="0rem 0 0"
                          src={noServices}
                        />
                        <Text
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="500"
                          m="1rem auto 1rem"
                          maxW="300px"
                          textAlign="center"
                        >
                          {t("clientAccount.noReportsText")}
                        </Text>
                        <Button
                          bg={primary}
                          color={white}
                          size="lg"
                          fontSize="sm"
                          borderRadius="10rem"
                          onClick={() => {
                            navigate("/events");
                          }}
                          _hover={{
                            bg: shade(0.3, primary),
                          }}
                        >
                          {t("clientAccount.noEventsButton")}
                        </Button>
                      </Flex>

                      {/* <Button
            bg={primary}
            color={white}
            size="lg"
            fontSize="sm"
            borderRadius="10rem"
            onClick={() => {
              navigate("/implant-fail-form");
            }}
          >
            {t("clientAccount.newReport")}
          </Button> */}
                    </Flex>
                  )}
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex w="100%" minH="100vh" bg={backgroundLight}>
            <Flex
              w="100%"
              maxW="1200px"
              p="0 0rem"
              direction="column"
              m="4rem auto 0"
              bg={backgroundLight}
              align="center"
            >
              <Heading
                fontSize="1.5rem"
                fontWeight="600"
                color={textPrimary}
                m="0 0 0rem"
              >
                {t("clientAccount.isEmployeeTitle")}
              </Heading>
              <Text
                color={textSecondary}
                fontSize="sm"
                fontWeight="500"
                textAlign="center"
                m="0.5rem 0 0"
              >
                {t("clientAccount.isEmployeeText")}
              </Text>
              <Button
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                bg={primary}
                color={white}
                m="1rem 0 0"
                onClick={() => navigate("/admin/dashboard")}
              >
                {t("clientAccount.isEmployeeButton")}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        bg={white}
        borderTop={`1px solid ${newBorder}`}
        w="100%"
        p="3rem 0"
        borderBottom={`1px solid ${newBorder}`}
      >
        <Flex w="100%" maxW={["100%", "100%", "1200px"]} m="0 auto">
          <Faq />
        </Flex>
      </Flex>

      <Footer />
    </>
  );
};

export default UserEvents;
