import React, { useState, useEffect } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import BarChartFixture from "../../../../components/stats/BarChartFixture";
import {
  primary,
  textPrimary,
  textSecondary,
  buttonRedNew,
  buttonRed,
  buttonGreen,
  buttonYellow,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import BarChartNewFixture from "../../../../components/stats/BarChartNewFixture";

const NewFixtureCountHq = ({ loading, error, data }) => {
  const { t } = useTranslation();

  const statusOrder = [
    "mini",
    "anyone",
    "anyridge",
    "bluediamond",
    "st",
    "ezplus",
    "rescue",
  ];
  const statusColors = {
    Mini: buttonRedNew,
    Anyone: buttonRed,
    Anyridge: buttonGreen,
    Bluediamond: buttonYellow,
    St: buttonYellow,
    Ezplus: buttonYellow,
    Rescue: buttonYellow,
  };

  if (loading) {
    return (
      <Flex>
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!data || data.length === 0) {
    return (
      <Flex>
        <Text>No data available.</Text>
      </Flex>
    );
  }

  const totalFixtureCount = data.reduce((total, item) => total + item.count, 0);

  return (
    <Flex direction="column" w="100%">
      {/* <Flex p="0 0rem 0.5rem">
        <Text fontSize="1rem" fontWeight="600" color={textPrimary}>
          {t("record.failedImplants")}{" "}
          <Text as="span" fontSize="md" fontWeight="500" color={textSecondary}>
            ({totalFixtureCount})
          </Text>
        </Text>
      </Flex> */}
      <BarChartNewFixture
        data={data}
        statusOrder={statusOrder}
        statusColors={statusColors}
        backgroundLight="gray.200"
        textSecondary="gray.500"
        white="white"
      />
    </Flex>
  );
};

export default NewFixtureCountHq;
