import React from "react";
import { FixedSizeList as List } from "react-window";
import { Box, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FaSortUp, FaSortDown } from "react-icons/fa6";
import {
  hoverItemColor,
  textLightBlue,
  textSecondary,
  bgLight,
  newBorder,
} from "../../utils/colors";
import NoItems from "./NoItems";

const TableContainerWindowed = ({
  loading,
  data,
  columns,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  noItemsButtonDisabled,
  onRowClick,
  pageSize,
  sortField,
  sortOrder,
  handleSortChange,
}) => {
  const rowHeight = 73; // Row height in pixels
  const headerHeight = 50; // Header height in pixels
  // const totalHeight = headerHeight + rowHeight * pageSize;
  const totalHeight =
    headerHeight + rowHeight * Math.min(data?.length || 0, pageSize);

  console.log("rowHeight", rowHeight);
  console.log("headerHeight", headerHeight);
  console.log("pageSize", pageSize);

  console.log("totalHeight", totalHeight);

  const calculateGridTemplateColumns = (columns, totalWidth) => {
    const fixedWidths = columns
      .filter((col) => col.width)
      .reduce((acc, col) => acc + parseInt(col.width, 10), 0);

    const flexibleColumnsCount = columns.filter((col) => !col.width).length;
    const remainingWidth = totalWidth - fixedWidths;
    const dynamicWidth =
      flexibleColumnsCount > 0 ? remainingWidth / flexibleColumnsCount : 0;

    return columns
      .map((col) => (col.width ? `${col.width}px` : `${dynamicWidth}px`))
      .join(" ");
  };

  if (loading) {
    return (
      <Flex w="100%" justify="center" align="center" p="2rem">
        <Spinner size="lg" color="primary.500" />
      </Flex>
    );
  }

  if (!data || data.length === 0) {
    return (
      <NoItems
        noItemsButtonDisabled={noItemsButtonDisabled}
        noItemsButtonHandler={noItemsButtonHandler}
        noItemsButtonLabel={noItemsButtonLabel}
        noItemsDescription={noItemsDescription}
        noItemsTitle={noItemsTitle}
      />
    );
  }

  const renderRow = ({ index, style, gridTemplateColumns }) => (
    <Flex
      align="center"
      key={data[index]._id}
      style={{
        ...style, // Necessary for react-window's positioning
        borderBottom: `1px solid ${newBorder}`,
        display: "grid",
        gridTemplateColumns,
        padding: "0", // Reset padding here
      }}
      onClick={() => onRowClick(data[index]._id)}
      _hover={{ bg: hoverItemColor, cursor: "pointer" }}
    >
      {columns.map((column, colIndex) => (
        <Box
          pl={colIndex === 0 ? "2rem" : 0}
          pr={colIndex === columns.length - 1 ? "2rem" : 0}
          key={column.accessor}
          textAlign="center"
        >
          {column.Cell
            ? column.Cell({ row: { original: data[index] } })
            : data[index][column.accessor]}
        </Box>
      ))}
    </Flex>
  );

  const renderSortIcon = (columnAccessor) => {
    if (sortField === columnAccessor) {
      return sortOrder === "asc" ? (
        <Icon
          aria-label="sorted ascending"
          as={FaSortUp}
          fontSize="0.875rem"
          color={textSecondary}
          position="relative"
          top="3px"
          ml={1}
        />
      ) : (
        <Icon
          aria-label="sorted descending"
          as={FaSortDown}
          fontSize="0.875rem"
          color={textSecondary}
          position="relative"
          top="-4px"
          ml={1}
        />
      );
    }
    return null;
  };

  return (
    <Box w="100%" overflow="visible" minH={`${totalHeight}px`} h="100%">
      <AutoSizer>
        {({ width, height }) => {
          const gridTemplateColumns = calculateGridTemplateColumns(
            columns,
            width
          );

          return (
            <>
              {/* Table Header */}
              <Flex
                h={`${headerHeight}px`}
                w="100%"
                align="center"
                bg={bgLight}
                display="grid"
                gridTemplateColumns={gridTemplateColumns}
                overflow="visible"
              >
                {columns.map((column, colIndex) => (
                  <Box
                    key={column.accessor}
                    fontWeight="bold"
                    cursor="pointer"
                    textAlign="center"
                    onClick={() => handleSortChange(column.accessor)}
                    bg={bgLight}
                    h={`${headerHeight}px`}
                  >
                    <Flex
                      align="center"
                      h="100%"
                      pl={colIndex === 0 ? "2rem" : 0}
                      pr={colIndex === columns.length - 1 ? "2rem" : 0}
                      fontSize="0.688rem"
                      fontWeight="600"
                      color={textLightBlue}
                      textTransform="uppercase"
                    >
                      {typeof column.Header === "function"
                        ? column.Header()
                        : column.Header}
                      {renderSortIcon(column.accessor)}
                    </Flex>
                  </Box>
                ))}
              </Flex>

              {/* Table Rows */}
              <List
                height={height - headerHeight} // Adjust dynamically to use remaining height
                itemCount={data.length}
                itemSize={rowHeight}
                width={width}
                style={{ overflow: "visible" }}
              >
                {({ index, style }) =>
                  renderRow({ index, style, gridTemplateColumns })
                }
              </List>
            </>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export default TableContainerWindowed;
