import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  buttonRedText,
  primary,
  newBorder,
  buttonRed,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const ConfirmDeleteModal = ({
  isOpen,
  onClose,
  confirmAction,
  isWarning,
  warningName,
  warningDescription,
  warningDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center" p="1rem 0" borderRadius="1.5rem">
        <ModalHeader
          color={textPrimary}
          borderBottom={`1px solid ${newBorder}`}
        >
          {t("common.deleteItemConfirmationTitle")}
        </ModalHeader>
        <ModalCloseButton top="1.5rem" right="1.5rem" />
        <ModalBody
          p="1.5rem 2rem 2.5rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
        >
          <Text fontSize="md" fontWeight="500">
            {isWarning
              ? t("common.deleteItemConfirmationAlt")
              : t("common.deleteItemConfirmation")}

            {warningName && (
              <Text
                as="span"
                fontSize="md"
                fontWeight="500"
                color={primary}
                ml="0.25rem"
              >
                {warningName}?
              </Text>
            )}
          </Text>

          {warningDescription && (
            <Flex
              align="center"
              justify="center"
              direction="column"
              m="1.5rem 0 0"
            >
              <Text
                fontSize="sm"
                fontWeight="600"
                color={buttonRedText}
                mb="0.5rem"
              >
                {t("common.deleteItemConfirmationWarning")}
              </Text>
              <Text fontSize="sm" fontWeight="500" color={buttonRedText}>
                {warningDescription}
              </Text>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderTop={`1px solid ${newBorder}`}
        >
          <Button
            colorScheme="red"
            size="lg"
            borderRadius="10rem"
            onClick={(e) => {
              e.stopPropagation(); // Stop row click propagation
              confirmAction(e); // Pass the event to confirmAction
            }}
            m={2}
            isDisabled={warningDisabled}
          >
            {t("common.yes")}
          </Button>
          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            m={2}
          >
            {t("common.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteModal;
