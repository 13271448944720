import React, { useState, useEffect } from "react";
import Table from "../../../../components/shared/Table";

import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import SearchInput from "../../../../components/input/SearchInput";
// import TableContainerWindowed from "../../../../components/shared/Table/TableContainerWindowed";
import { columns } from "../columns/recordsColumns";
import TableContainerWindowed from "../../../../components/table/TableContainerWindowed";
import TablePaginationNew from "../../../../components/table/TablePaginationNew";

const RecordList = ({
  loading,
  data,
  searchOpen,
  searchTerm,
  onChangeSearchTerm,
  page,
  setPage,
  setPageSize,
  pageSize,
  pageSizes,
  pages,
  sortField,
  sortOrder,
  handleSortChange,
  handleRowClick,
  deleteHandler,
  handleUpdateStatus,
  noItemsHandler,
  openSendRecordHandler,
}) => {
  // GENERAL
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (data && data.length > 0) {
      console.log("data", data);
    }
  }, [data]);

  return (
    <>
      {/* MAIN */}
      <Box w="100%" minH="100%" bg="white" borderRadius="1.5rem">
        <Flex direction="column" w="100%">
          <Flex direction="column" w="100%">
            {/* {searchOpen && (
              <SearchInput
                placeholder={t("transaction.search")}
                onChange={onChangeSearchTerm}
                searchName={searchTerm}
              />
            )} */}
            {!loading ? (
              <>
                <TableContainerWindowed
                  loading={loading}
                  data={data}
                  columns={columns(
                    t,
                    isMobile,
                    deleteHandler,
                    handleUpdateStatus,
                    openSendRecordHandler
                  )}
                  noItemsTitle={t("record.noRecordsTitle")}
                  noItemsDescription={t("record.noRecordsDescription")}
                  noItemsButtonHandler={noItemsHandler}
                  noItemsButtonLabel={t("record.noRecordsButton")}
                  noItemsButtonDisabled={false}
                  pageSize={pageSize}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  handleSortChange={handleSortChange}
                  headerHeight="36"
                  headerFontSize="10px"
                  sortIconSize="12px"
                  onRowClick={handleRowClick}
                />
                <Flex p="1rem 0" w="100%">
                  <TablePaginationNew
                    loading={loading}
                    page={page}
                    pages={pages}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    navigate={navigate}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    totalCount={data?.length || 0}
                  />
                </Flex>
              </>
            ) : (
              <Flex w="100%" justify="center" align="center" p="3rem">
                <Spinner size="lg" color="blue.500" />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default RecordList;
