import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box } from "@chakra-ui/react";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/recordSingleReportsColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getRecordDetailsAction,
  getRecordReportsAction,
  downloadRecordFileAction,
} from "../../../../actions/recordActions";
import RecordSingleReportList from "../list/RecordSingleReportList";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import TablePaginationNew from "../../../../components/table/TablePaginationNew";
import dayjs from "dayjs";
import {
  backgroundLight,
  greenVivid,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import FixtureCount from "../partials/FixtureCount";
import NewFixtureCount from "../partials/NewFixtureCount";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { TbFileTypeZip, TbMailCheck } from "react-icons/tb";
import SendRecordModal from "../sidepanels/SendRecordModal";
import {
  GENERATE_REPORT_FILES_RESET,
  SEND_RECORD_RESET,
} from "../../../../constants/recordConstants";

const RecordSingle = () => {
  // TRANSLATION
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ID
  const id = useParams().id;

  // STATE
  const [recordId, setRecordId] = useState(null);

  // MODALS
  const [showSendRecordModal, setShowSendRecordModal] = useState(false);

  // SEARCH
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeSearchTerm = (e) => setSearchTerm(e.target.value);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 25];

  // SORTING
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const handleSortChange = useCallback(
    (column) => {
      const newSortOrder =
        sortField === column && sortOrder === "asc" ? "desc" : "asc";
      setSortField(column);
      setSortOrder(newSortOrder);
    },
    [sortField, sortOrder]
  );

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getRecordDetails = useSelector((state) => state.getRecordDetails);
  const { loading, error, recordDetails, pages } = getRecordDetails;

  const getRecordReports = useSelector((state) => state.getRecordReports);
  const {
    loading: loadingReports,
    error: errorReports,
    reports,
  } = getRecordReports;

  const sendRecord = useSelector((state) => state.sendRecord);
  const {
    loading: sendLoading,
    success: sendSuccess,
    error: sendError,
  } = sendRecord;

  // TOAST
  const toast = useToast();

  // HANDLERS
  const handleCloseSendRecordModal = () => {
    setShowSendRecordModal(false);

    dispatch({ type: SEND_RECORD_RESET });
    dispatch({ type: GENERATE_REPORT_FILES_RESET });
  };

  const openSendRecordHandler = (e, id) => {
    e.stopPropagation(); // Prevent the event from propagating to parent elements
    console.log("SEND", id);
    setRecordId(id);
    setShowSendRecordModal(true);
  };

  const handleDownloadZip = () => {
    dispatch(downloadRecordFileAction(id));
  };

  const handleRowClick = (value) => {
    navigate(`/admin/reports/records/${id}/implant-failure-report/${value}`);
  };

  // USE EFFECT
  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (id) {
      dispatch(getRecordDetailsAction(id));
      dispatch(
        getRecordReportsAction(
          id,
          page,
          pageSize,
          sortField,
          sortOrder,
          searchTerm
        )
      );
    }
  }, [
    dispatch,
    id,
    page,
    pageSize,
    sortField,
    sortOrder,
    searchTerm,
    sendSuccess,
  ]);

  // TOASTS
  useEffect(() => {
    if (sendSuccess) {
      // toast({
      //   title: t("record.sendRecordSuccess"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });

      dispatch({ type: SEND_RECORD_RESET });
    }

    if (sendError) {
      toast({
        title: t("record.sendRecordError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [sendSuccess, sendError]);

  // CUSTOM
  // Check if recordDetails and countsByFixture exist
  const fixtureCounts = recordDetails?.countsByFixture || [];
  const newFixtureCounts = recordDetails?.countsByNewFixture || [];

  // LOGS
  // console.log("id", id);
  // console.log("recordDetails", recordDetails);
  // console.log("reports", reports);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>
          {t("record.pageTitleRecordDetailsSingle")}{" "}
          {recordDetails && recordDetails.customId
            ? ` - ${recordDetails.customId}`
            : ""}
        </title>
        <meta
          name="description"
          content={t("record.pageTitleRecordDetailsSingle")}
        />
      </Helmet>

      {showSendRecordModal && (
        <SendRecordModal
          title={t("course.dublicateCourseTitle")}
          warningTitle={t("course.dublicateCourseWarningTitle")}
          warningLabel={t("course.dublicateCourseWarningLabel")}
          isOpen={showSendRecordModal}
          onClose={handleCloseSendRecordModal}
          confirmAction={() => {
            // confirmDuplicateCourse(courseId);
            setShowSendRecordModal(false);
          }}
          recordId={id}
          record={recordDetails}
        />
      )}

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={recordDetails && recordDetails.customId}
              type={
                recordDetails &&
                recordDetails.startDate &&
                dayjs(recordDetails.startDate).utc().format("YYYY, MMMM D") +
                  " - " +
                  dayjs(recordDetails.endDate).utc().format("YYYY, MMMM D")
              }
              loading={loading}
            >
              {recordDetails && recordDetails.status === "draft" && (
                <Button
                  bg={primary}
                  color={white}
                  borderRadius="10rem"
                  size="md"
                  fontSize="sm"
                  _hover={{ bg: textPrimary }}
                  onClick={openSendRecordHandler}
                  mr="0.5rem"
                >
                  {t("record.sendRecord")}
                </Button>
              )}

              {recordDetails && recordDetails.status === "sent" && (
                <Button
                  bg={primary}
                  color={white}
                  borderRadius="10rem"
                  size="md"
                  fontSize="sm"
                  _hover={{ bg: textPrimary }}
                  onClick={handleDownloadZip}
                  mr="0.5rem"
                >
                  <Flex
                    bg={greenVivid}
                    align="center"
                    justify="center"
                    h="1.5rem"
                    w="1.5rem"
                    borderRadius="10rem"
                    mr="0.5rem"
                  >
                    <Icon
                      as={TbFileTypeZip}
                      color={textPrimary}
                      fontSize="0.875rem"
                    />
                  </Flex>

                  {t("record.downloadZipFull")}
                </Button>
              )}

              {recordDetails && recordDetails.status === "sent" && (
                <Box>
                  <Tooltip
                    p="0.5rem 1rem"
                    borderRadius="1rem"
                    placement="top"
                    fontSize="xs"
                    label={
                      <Flex direction="column">
                        <Flex align="center">
                          <Text mr={1} opacity="0.8">
                            {t("record.recordSent")}:
                          </Text>
                          <Text>
                            {dayjs(recordDetails.sentDate).format(
                              "YYYY MMMM D, HH:ss"
                            )}
                          </Text>
                        </Flex>

                        <Flex align="center">
                          <Text mr={1} opacity="0.8">
                            {t("record.recipientEmail")}:
                          </Text>
                          <Text>{recordDetails.recipientEmail}</Text>
                        </Flex>

                        <Flex align="center">
                          <Text mr={1} opacity="0.8">
                            {t("record.sentBy")}:
                          </Text>
                          <Text>
                            {recordDetails.sentBy.firstName +
                              " " +
                              recordDetails.sentBy.lastName}
                          </Text>
                        </Flex>

                        <Flex align="center">
                          <Text mr={1} opacity="0.8">
                            {t("record.personInCharge")}:
                          </Text>
                          <Text>{recordDetails.personInCharge}</Text>
                        </Flex>
                      </Flex>
                    }
                  >
                    <Flex
                      align="center"
                      justify="center"
                      w="2.5rem"
                      h="2.5rem"
                      borderRadius="10rem"
                      // px="2.5rem"
                      mr="0.5rem"
                      bg={backgroundLight}
                      flexShrink={0}
                    >
                      <Icon as={TbMailCheck} color={textPrimary} />
                    </Flex>
                  </Tooltip>
                </Box>
              )}

              <StatusButton
                isUnclickable
                currentStatus={recordDetails && recordDetails.status}
                // onUpdateStatus={handleUpdateStatus}
                options="record"
              />
              {/* <MoreButton
                id={recordDetails && recordDetails._id}
                isBig
                // deleteHandler={deleteAttendeeHandler}
                // editHandler={(e) => {
                //   e.stopPropagation();
                //   openEditSidePanel(attendeeId);
                // }}
              /> */}
            </SinglePageTopBar>

            <Flex w="100%" m="2rem 0 0">
              <Flex p="0 2rem" align="flex-start" direction="column" gap={1.5}>
                <Box p="0rem 0rem 1rem">
                  <Text fontSize="1rem" fontWeight="600" color={textPrimary}>
                    {t("record.recordDetails")}
                  </Text>
                </Box>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    mr={1}
                  >
                    {t("record.recordDates")}:
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                    {dayjs(recordDetails && recordDetails?.startDate)
                      .utc()
                      .format("YYYY, MMMM D")}{" "}
                    -{" "}
                    {dayjs(recordDetails && recordDetails?.endDate)
                      .utc()
                      .format("YYYY, MMMM D")}
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    mr={1}
                  >
                    {t("form.country")}:
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                    {recordDetails && recordDetails?.country === "LT"
                      ? t("record.lithuania")
                      : recordDetails?.country}
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    mr={1}
                  >
                    {t("record.personInCharge")}:
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                    {recordDetails && recordDetails.personInCharge}
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    mr={1}
                  >
                    {t("record.dealerShort")}:
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                    {recordDetails && recordDetails.dealer}
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    mr={1}
                  >
                    {t("record.email")}:
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                    {recordDetails && recordDetails.email}
                  </Text>
                </Flex>
              </Flex>

              <Flex direction="column" p="0 0 0 4rem">
                <FixtureCount
                  loading={loading}
                  error={error}
                  recordDetails={recordDetails}
                />
              </Flex>

              <Flex direction="column" p="0 0 0 4rem">
                <NewFixtureCount
                  loading={loading}
                  error={error}
                  recordDetails={recordDetails}
                />
              </Flex>
            </Flex>

            <Box p="0 2rem" m="1.5rem 0 0">
              <Table.Top
                title={t("record.reports")}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t("record.reportsSearch")}
                searchName={searchTerm}
                // isFixedTitle
                isSmallTitle
              ></Table.Top>
              <Box borderRadius="1rem" overflow="hidden">
                <RecordSingleReportList
                  loading={loading}
                  data={reports || []}
                  searchOpen={searchOpen}
                  searchTerm={searchTerm}
                  onChangeSearchTerm={onChangeSearchTerm}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageSizes={pageSizes}
                  pages={pages}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  handleSortChange={handleSortChange}
                  handleRowClick={handleRowClick}
                />
              </Box>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default RecordSingle;
