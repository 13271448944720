export const CREATE_RECORD_REQUEST = "CREATE_RECORD_REQUEST";
export const CREATE_RECORD_SUCCESS = "CREATE_RECORD_SUCCESS";
export const CREATE_RECORD_FAIL = "CREATE_RECORD_FAIL";
export const CREATE_RECORD_RESET = "CREATE_RECORD_RESET";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAIL = "GET_RECORDS_FAIL";
export const GET_RECORDS_RESET = "GET_RECORDS_RESET";

export const GET_RECORD_DETAILS_REQUEST = "GET_RECORD_DETAILS_REQUEST";
export const GET_RECORD_DETAILS_SUCCESS = "GET_RECORD_DETAILS_SUCCESS";
export const GET_RECORD_DETAILS_FAIL = "GET_RECORD_DETAILS_FAIL";
export const GET_RECORD_DETAILS_RESET = "GET_RECORD_DETAILS_RESET";

export const DELETE_RECORD_REQUEST = "DELETE_RECORD_REQUEST";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAIL = "DELETE_RECORD_FAIL";
export const DELETE_RECORD_RESET = "DELETE_RECORD_RESET";

export const GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST =
  "GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST";
export const GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS =
  "GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS";
export const GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL =
  "GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL";
export const GET_PUBLIC_RECORD_MINIMAL_DETAILS_RESET =
  "GET_PUBLIC_RECORD_MINIMAL_DETAILS_RESET";

export const GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST =
  "GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST";
export const GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS =
  "GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS";
export const GET_PUBLIC_RECORD_FULL_DETAILS_FAIL =
  "GET_PUBLIC_RECORD_FULL_DETAILS_FAIL";
export const GET_PUBLIC_RECORD_FULL_DETAILS_RESET =
  "GET_PUBLIC_RECORD_FULL_DETAILS_RESET";

export const GENERATE_REPORT_FILES_REQUEST = "GENERATE_REPORT_FILES_REQUEST";
export const GENERATE_REPORT_FILES_SUCCESS = "GENERATE_REPORT_FILES_SUCCESS";
export const GENERATE_REPORT_FILES_FAIL = "GENERATE_REPORT_FILES_FAIL";
export const GENERATE_REPORT_FILES_RESET = "GENERATE_REPORT_FILES_RESET";

export const SEND_RECORD_REQUEST = "SEND_RECORD_REQUEST";
export const SEND_RECORD_SUCCESS = "SEND_RECORD_SUCCESS";
export const SEND_RECORD_FAIL = "SEND_RECORD_FAIL";
export const SEND_RECORD_RESET = "SEND_RECORD_RESET";

export const GET_RECORD_REPORTS_REQUEST = "GET_RECORD_REPORTS_REQUEST";
export const GET_RECORD_REPORTS_SUCCESS = "GET_RECORD_REPORTS_SUCCESS";
export const GET_RECORD_REPORTS_FAIL = "GET_RECORD_REPORTS_FAIL";
export const GET_RECORD_REPORTS_RESET = "GET_RECORD_REPORTS_RESET";

export const DOWNLOAD_RECORD_FILE_REQUEST = "DOWNLOAD_RECORD_FILE_REQUEST";
export const DOWNLOAD_RECORD_FILE_SUCCESS = "DOWNLOAD_RECORD_FILE_SUCCESS";
export const DOWNLOAD_RECORD_FILE_FAIL = "DOWNLOAD_RECORD_FILE_FAIL";
export const DOWNLOAD_RECORD_FILE_RESET = "DOWNLOAD_RECORD_FILE_RESET";

export const GET_RECORD_CUSTOMID_REQUEST = "GET_RECORD_CUSTOMID_REQUEST";
export const GET_RECORD_CUSTOMID_SUCCESS = "GET_RECORD_CUSTOMID_SUCCESS";
export const GET_RECORD_CUSTOMID_FAIL = "GET_RECORD_CUSTOMID_FAIL";
export const GET_RECORD_CUSTOMID_RESET = "GET_RECORD_CUSTOMID_RESET";

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL";
export const DOWNLOAD_FILE_RESET = "DOWNLOAD_FILE_RESET";
