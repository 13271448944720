import React from "react";
import {
  Text,
  Flex,
  Button,
  Icon,
  HStack,
  Stack,
  background,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import {
  backgroundLight,
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import { TbMail, TbMailCheck } from "react-icons/tb";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import ReactCountryFlag from "react-country-flag";

export const columns = (
  t,
  isMobile,
  deleteHandler,
  handleUpdateStatus,
  openSendRecordHandler
) => {
  const allColumns = [
    {
      Header: () => (
        <Flex h="100%">
          <Flex align="center" cursor="pointer">
            <Text textAlign="left">ID</Text>
          </Flex>
        </Flex>
      ),
      accessor: "customId",
      width: 86,
      Cell: ({ row }) => {
        const { customId } = row.original;
        return (
          <Text
            textAlign="left"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="xs"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Flex h="100%">
          <Flex align="center" cursor="pointer">
            <Text textAlign="left">
              {t("implantFailureReports.columnDate")}
            </Text>
          </Flex>
        </Flex>
      ),
      accessor: "createdAt",
      width: 110,
      Cell: ({ row }) => {
        const { createdAt } = row.original;
        return (
          <Text
            textAlign="left"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textPrimary}
            fontWeight="500"
            textTransform="capitalize"
          >
            {dayjs(createdAt).format("YYYY-MM-DD")}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Flex h="100%">
          <Flex align="center" cursor="pointer">
            <Text textAlign="left">{t("record.dealerShort")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "dealer",
      width: 200,
      Cell: ({ row }) => {
        const { dealer, country } = row.original;

        return (
          <Flex align="center">
            <Flex
              align="center"
              justify="center"
              overflow="hidden"
              mr={1.5}
              p="0.5rem"
              border={`1px solid ${newBorder}`}
              borderRadius="10rem"
              bg={white}
              flexShrink={0}
            >
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{
                  width: "1rem",
                  height: "1rem",
                  borderRadius: "4px",
                }}
                title={country}
              />
            </Flex>
            <Stack gap={0}>
              <Text
                textAlign="left"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize="sm"
                color={textPrimary}
                fontWeight="500"
                maxW="120px"
                isTruncated
              >
                {dealer}
              </Text>
              <Text
                textAlign="left"
                fontSize="xs"
                color={textSecondary}
                fontWeight="500"
                maxW="120px"
                isTruncated
              >
                {country === "LT" ? t("record.lithuania") : country}
              </Text>
            </Stack>
          </Flex>
        );
      },
    },

    {
      Header: () => (
        <Flex h="100%">
          <Flex align="center" cursor="pointer">
            <Text textAlign="left">{t("record.recordDates")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "startDate",
      // width: 260,
      Cell: ({ row }) => {
        const { startDate, endDate } = row.original;
        return (
          <Text
            textAlign="left"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textPrimary}
            fontWeight="500"
            textTransform="capitalize"
          >
            {dayjs(startDate).utc().format("YYYY MMMM D")} -{" "}
            {dayjs(endDate).utc().format("MMMM D")}
          </Text>
        );
      },
    },
    // {
    //   Header: () => (
    //     <Flex h="100%">
    //       <Flex align="center" cursor="pointer">
    //         <Text textAlign="left">{t("record.personInCharge")}</Text>
    //       </Flex>
    //     </Flex>
    //   ),
    //   accessor: "personInCharge",
    //   width: 170,
    //   Cell: ({ row }) => {
    //     const { personInCharge, email } = row.original;
    //     return (
    //       <Stack gap={0}>
    //         <Text
    //           textAlign="left"
    //           overflow="hidden"
    //           whiteSpace="nowrap"
    //           fontSize="sm"
    //           color={textPrimary}
    //           fontWeight="500"
    //           maxW="140px"
    //           isTruncated
    //         >
    //           {personInCharge}
    //         </Text>
    //         <Text
    //           textAlign="left"
    //           overflow="hidden"
    //           whiteSpace="nowrap"
    //           fontSize="xs"
    //           color={textSecondary}
    //           fontWeight="500"
    //           maxW="140px"
    //           isTruncated
    //         >
    //           {email}
    //         </Text>
    //       </Stack>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Flex h="100%">
          <Flex align="center" cursor="pointer">
            <Text textAlign="left">{t("record.reportCount")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "reports",
      Cell: ({ row }) => {
        const { reports } = row.original;
        return (
          <Text
            textAlign="left"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="xs"
            color={textPrimary}
            fontWeight="500"
          >
            {reports && reports.length}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Flex h="100%" w="100%">
          <Flex align="center" justify="flex-end" cursor="pointer" w="100%">
            <Text textAlign="right">{t("common.status")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "status",
      Cell: ({ row }) => {
        const {
          _id,
          status,
          sentDate,
          recipientEmail,
          sentBy,
          personInCharge,
        } = row.original;

        // console.log("sentDate", sentDate);

        const sender = sentBy ? sentBy.firstName + " " + sentBy.lastName : "";

        return (
          <>
            <HStack justify="flex-end" gap="0rem">
              {!sentDate ? (
                <Button
                  size="md"
                  fontSize="sm"
                  borderRadius="10rem"
                  bg={textPrimary}
                  color={white}
                  px="2.5rem"
                  mr="0.5rem"
                  onClick={(e) => openSendRecordHandler(e, _id)} // Pass `e` and `id`
                  _hover={{ bg: primary, color: white }}
                  isDisabled={sentDate}
                >
                  {sentDate ? t("record.sendRecord") : t("record.sendRecord")}
                </Button>
              ) : (
                <Tooltip
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  placement="top"
                  fontSize="xs"
                  label={
                    <Flex direction="column">
                      <Flex align="center">
                        <Text mr={1} opacity="0.8">
                          {t("record.recordSent")}:
                        </Text>
                        <Text>
                          {dayjs(sentDate).format("YYYY MMMM D, HH:ss")}
                        </Text>
                      </Flex>

                      <Flex align="center">
                        <Text mr={1} opacity="0.8">
                          {t("record.recipientEmail")}:
                        </Text>
                        <Text>{recipientEmail}</Text>
                      </Flex>

                      <Flex align="center">
                        <Text mr={1} opacity="0.8">
                          {t("record.sentBy")}:
                        </Text>
                        <Text>{sender}</Text>
                      </Flex>

                      <Flex align="center">
                        <Text mr={1} opacity="0.8">
                          {t("record.personInCharge")}:
                        </Text>
                        <Text>{personInCharge}</Text>
                      </Flex>
                    </Flex>
                  }
                >
                  <Flex
                    align="center"
                    justify="center"
                    w="2.5rem"
                    h="2.5rem"
                    borderRadius="10rem"
                    // px="2.5rem"
                    mr="0.5rem"
                    bg={backgroundLight}
                    flexShrink={0}
                  >
                    <Icon as={TbMailCheck} color={textPrimary} />
                  </Flex>
                </Tooltip>
              )}
              <StatusButton
                isUnclickable
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="record"
              />
              <MoreButton
                id={_id}
                deleteHandler={() => {
                  deleteHandler(_id); // Call delete handler
                }}
                isWarning
              />
            </HStack>
          </>
        );
      },
    },
  ];

  // If on a mobile device, filter out columns as needed
  if (isMobile) {
    return allColumns.filter((column) =>
      ["pair", "type", "amount", "price"].includes(column.accessor)
    );
  }

  return allColumns;
};
