import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlClientTypeRegisterReport = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  placeholder,
  w,
}) => {
  const { t } = useTranslation();

  const options = [
    { value: "business_clinic", label: t("landingMegagen.registerTypeClinic") },
    { value: "individual", label: t("landingMegagen.registerTypeDentist") },
    { value: "business_lab", label: t("landingMegagen.registerTypeLab") },
  ];

  console.log("value:", value); // This should log the value of the selected option ("client", "dentist", or "lab
  const selectedOption = options.find((option) => option.value === value);
  console.log("Selected option:", selectedOption); // This should log the correct object from options

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={"sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable={false}
        value={options.find((option) => option.value === value)}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),

          menu: (base) => ({
            ...base,
            borderRadius: "1rem",
            overflow: "hidden",
            // margin: "0",
            // padding: "0",
            // border: `1px solid ${newBorder}`,
            // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }),
          menuControl: (base) => ({
            ...base,
            // margin: "0",
            // padding: "0",
            // border: `1px solid ${newBorder}`,
            // borderRadius: "1rem",
            // backgroundColor: white,
            // height: "3.5rem",
          }),

          menuList: (base) => ({
            ...base,
            margin: "0",
            padding: "0",
          }),

          option: (provided, state) => ({
            ...provided,
            padding: "0.75rem 1rem",
          }),

          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlClientTypeRegisterReport;
