import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Heading,
  Icon,
  Button,
  Spinner,
  Box,
  Image,
  HStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  white,
  backgroundLight,
  greenVivid,
} from "../../../../utils/colors";
import { TbFileDescription, TbFileTypeZip } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Table from "../../../../components/shared/Table";
import { columns } from "../columns/recordPublicHqReportsColumns";
import FixtureCount from "../partials/FixtureCount";
import FixtureCountHq from "../partials/FixtureCountHq";
import NewFixtureCountHq from "../partials/NewFixtureCountHq";
import { Link } from "react-router-dom";
import logonew2 from "../../../../assets/icons/logonew2.svg";
import { downloadRecordFileAction } from "../../../../actions/recordActions";
import { useDispatch, useSelector } from "react-redux";
import { DOWNLOAD_RECORD_FILE_RESET } from "../../../../constants/recordConstants";
import { useNavigate } from "react-router-dom";
import TableContainerHq from "../../../../components/table/TableContainerHq";
import SingleImplantFailureReportKoreaHq from "../../reports/single/SingleImplantFailureReportKoreaHq";
import logonew2white from "../../../../assets/icons/logonew2white.svg";
import { useMediaQuery } from "react-responsive";
import LanguageButton from "../../../../components/buttons/LanguageButton";

const PublicRecordFull = ({
  userInfo,
  recordFull,
  loading,
  error,
  countsByFixture,
  countsByNewFixture,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { i18n } = useTranslation();

  const handleSortChange = (field, order) => {
    console.log("field", field);
    console.log("order", order);
  };

  const [currentLanguage, setCurrentLanguage] = useState("ko");

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [newReportId, setNewReportId] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  // REDUX
  const dispatch = useDispatch();
  const downloadRecordFile = useSelector((state) => state.downloadRecordFile);
  const {
    loading: loadingDownload,
    error: errorDownload,
    success: successDownload,
    data,
  } = downloadRecordFile;

  // HANDLERS

  const handleRowClick = (value) => {
    if (!value) {
      console.error("Invalid report ID:", value);
      toast({
        title: "Error",
        description: "Invalid report data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    console.log("Value passed to setNewReportId:", value); // Logs the new value being set
    setNewReportId(value); // Updates state asynchronously
    // navigate(`/hq/reports/${recordFull._id}/${value}`);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  // USE EFFECT

  // Effect to find and set the selected report
  useEffect(() => {
    if (newReportId) {
      const report = recordFull.reports.find((r) => r._id === newReportId);
      setSelectedReport(report);
      console.log("Selected Report:", report);
    }
  }, [newReportId, recordFull.reports]);

  // Log the updated value of newReportId using useEffect
  useEffect(() => {
    if (newReportId) {
      console.log("Updated newReportId:", newReportId); // Logs the updated state value
    }
  }, [newReportId]);

  const handleDownloadZip = () => {
    dispatch(downloadRecordFileAction(recordFull._id));
  };

  // CUSTOM
  const DataEntry = ({ label, value }) => {
    return (
      <Flex direction="column">
        <Text
          fontSize="0.8rem"
          fontWeight="500"
          color={textSecondary}
          m="0 0 0rem"
        >
          {label}
        </Text>
        <Text
          fontSize="15px"
          fontWeight="500"
          color={textPrimary}
          // bg={backgroundLight}
          // borderRadius="1rem"
          // p="0.75rem 1rem"
        >
          {value}
        </Text>
      </Flex>
    );
  };

  //
  // const totalFixtureCount = data.reduce((total, item) => total + item.count, 0);/

  // get total fixture count
  const totalFixtureCount = countsByFixture.reduce(
    (total, item) => total + item.count,
    0
  );
  // get total new fixture count
  const totalNewFixtureCount = countsByNewFixture.reduce(
    (total, item) => total + item.count,
    0
  );

  // TOASTS
  useEffect(() => {
    if (errorDownload) {
      toast({
        title: t("record.downloadError"),
        description: errorDownload,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (successDownload) {
      // const fileName = `${recordFull.customId}-files.zip`;
      toast({
        title: t("koreport.downloadSuccess"),
        // description: fileName,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      dispatch({ type: DOWNLOAD_RECORD_FILE_RESET });
    }
  }, [errorDownload, successDownload]);

  // FILENAME
  const fileName = `${recordFull.customId}-files.zip`;

  // LOG

  // console.log("Reports data passed to Table.Container:", recordFull.reports);
  // console.log("newReportId", newReportId);

  // console.log("recordFull", recordFull);

  // console.log("userInfo", userInfo);

  console.log("isMobile", isMobile);

  return (
    <>
      {/* lets make modal that is like a page, full screen overlay */}
      {selectedReport && (
        <Modal
          isOpen={!!selectedReport}
          onClose={() => {
            setNewReportId(null);
            setSelectedReport(null);
          }}
          size="full"
        >
          <ModalOverlay />
          <ModalContent
            bg="white"
            overflow="hidden"
            p={0}
            display="flex"
            flexDirection="column"
          >
            <ModalBody p={0} overflowY="auto">
              <SingleImplantFailureReportKoreaHq
                report={selectedReport}
                close={() => {
                  setNewReportId(null);
                  setSelectedReport(null);
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Flex
        w="100%"
        direction="column"
        m="0 auto"
        p={["calc(100px + 0rem) 0 0rem", "calc(100px + 2rem) 0 0rem"]}
        position="relative"
        overflow="hidden"
      >
        {/* <Box
          position="absolute"
          top="2rem"
          left={["2rem", "2rem"]}
          zIndex={200}
        >
          <Link to="https://www.mymegagen.com" target="_blank">
            <HStack gap={0}>
              <Image src={logonew2} />
            </HStack>
          </Link>
        </Box> */}
        <Flex
          position="absolute"
          top="1rem"
          left={["50%", "3%"]}
          transform={["translate(-50%, 0)", "translate(0, 0)"]}
          zIndex={900}
          w={["100%", "initial"]}
          align="center"
          justify="space-between"
          p={["0 1.5rem", "0"]}
        >
          <Link to="https://www.mymegagen.com" target="_blank">
            <HStack gap={0} alignItems="flex-end">
              <Image src={logonew2} />
            </HStack>
          </Link>

          <Box
            position={["relative ", "absolute"]}
            zIndex={900}
            display={["block", "none"]}
          >
            <LanguageButton
              currentLanguage={currentLanguage}
              languages={["en", "lt", "ko"]}
              onChangeLanguage={handleChangeLanguage}
              onMenuClick={handleMenuClick}
              isWhite
            />
          </Box>
        </Flex>

        <Box
          position={["relative ", "absolute"]}
          zIndex={900}
          display={["none", "block"]}
          right="3%"
          top="1rem"
        >
          <LanguageButton
            currentLanguage={currentLanguage}
            languages={["en", "lt", "ko"]}
            onChangeLanguage={handleChangeLanguage}
            onMenuClick={handleMenuClick}
            isWhite
          />
        </Box>

        <Flex align="center" justify="center">
          <Flex align="center" m={["0 auto 2rem", "0 auto 4rem"]}>
            <Flex
              align="center"
              justify="center"
              bg={primary}
              borderRadius="50%"
              p={["0.369rem", "0.5rem"]}
              mr={2}
            >
              <Icon
                as={TbFileDescription}
                color={white}
                fontSize={["1rem", "1.125rem"]}
              />
            </Flex>
            <Heading
              fontSize={["1.25rem", "1.5rem"]}
              fontWeight="600"
              color={textPrimary}
              textAlign="center"
            >
              {t("koreport.record")} - {recordFull.customId}
            </Heading>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          maxW={["100%", "800px"]}
          m="0 auto"
          justify={["center", "space-between"]}
          gap="2rem"
          direction={["column", "row"]}
          p={["0 1.5rem", "initial"]}
        >
          <Flex w={["100%", "50%"]} direction="column">
            <Text
              fontSize="1rem"
              fontWeight="600"
              color={textPrimary}
              m="0 0 0.5rem"
            >
              {t("koreport.senderInfo")}
            </Text>
            <Flex
              w="100%"
              direction="column"
              gap="1rem"
              border={`1px solid ${newBorder}`}
              p="1.5rem"
              borderRadius="1rem"
            >
              <DataEntry
                label={t("koreport.country")}
                value={
                  recordFull.country === "LT" ? "Lithuania" : recordFull.country
                }
              />
              <DataEntry
                label={t("koreport.personInCharge")}
                value={recordFull.personInCharge}
              />
              <DataEntry
                label={t("koreport.senderEmail")}
                value={recordFull.email}
              />
            </Flex>
          </Flex>
          <Flex w={["100%", "50%"]} direction="column">
            <Text
              fontSize="1rem"
              fontWeight="600"
              color={textPrimary}
              m="0 0 0.5rem"
            >
              {t("koreport.reportInfo")}
            </Text>
            <Flex
              w="100%"
              direction="column"
              gap="1rem"
              border={`1px solid ${newBorder}`}
              p="1.5rem"
              borderRadius="1rem"
            >
              <DataEntry
                label={t("koreport.recipientEmail")}
                value={recordFull.recipientEmail}
              />

              <DataEntry
                label={t("koreport.recordDate")}
                // value={recordFull.createdAt}
                value={dayjs(recordFull.createdAt).utc().format("YYYY, MMMM D")}
              />
              <DataEntry
                label={t("koreport.recordDates")}
                // value={recordFull.startDate + " - " + recordFull.endDate}
                value={
                  dayjs(recordFull.startDate).utc().format("YYYY, MMMM D") +
                  " - " +
                  dayjs(recordFull.endDate).utc().format("YYYY MMMM D")
                }
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          maxW={["80%", "800px"]}
          m="4rem auto 0"
          bg={backgroundLight}
          borderRadius="1.5rem"
          p="1.5rem"
          align="center"
          justify="center"
          direction={["column", "row"]}
        >
          <Flex
            align={["center", "flex-start"]}
            direction={["column", "row"]}
            justify={["center", "initial"]}
          >
            <Flex
              align="center"
              justify="center"
              w="4rem"
              h="4rem"
              bg={greenVivid}
              borderRadius="50%"
              color={textPrimary}
              mr="1rem"
              flexShrink="0"
            >
              <Icon as={TbFileTypeZip} fontSize="1.5rem" />
            </Flex>
            <Flex
              align={["center", "flex-start"]}
              justify={["center", "flex-start"]}
              direction="column"
              m={["1rem  0 0", "0"]}
            >
              <Text
                fontSize="1rem"
                color={textPrimary}
                fontWeight="600"
                isTruncated
              >
                {t("koreport.download")}
                <Text as="span" color={primary} mx={1}>
                  {fileName}
                </Text>
                {t("koreport.file")}
              </Text>
              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                m={["0.25rem 0 0", "initial"]}
                maxW={["100%", "80%"]}
                textAlign={["center", "initial"]}
              >
                {t("koreport.zipText")}
              </Text>
            </Flex>
          </Flex>

          <Flex align="center" justify="flex-end" m={["1rem 0 0", "0"]}>
            <Button
              bg={primary}
              color={white}
              borderRadius="10rem"
              size="lg"
              fontSize="md"
              _hover={{ bg: textPrimary }}
              onClick={handleDownloadZip}
            >
              {t("koreport.downloadZip")}
            </Button>
          </Flex>
        </Flex>

        {/* TABLE  */}
        {!loading ? (
          <Flex
            w="100%"
            direction="column"
            maxW={["100%", "900px"]}
            m="0 auto"
            p={["0 1.5rem", "0 0 0rem"]}
          >
            {/* <Table.Top
            title={t("record.reports")}
            // onChange={onChangeSearchTerm}
            placeholder={t("record.reportsSearch")}
            // searchName={searchTerm}
            // isFixedTitle
            isSmallTitle
          /> */}

            <Flex w="100%" align="center" justify="center" m="3rem auto 1rem">
              <Text
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                textAlign="center"
              >
                {t("record.reports")}
              </Text>
            </Flex>
            <Box
              w="100%"
              border="1px solid"
              borderColor={newBorder}
              borderRadius="1.5rem"
              overflow="hidden"
            >
              <TableContainerHq
                loading={loading}
                data={recordFull.reports}
                // error={error}
                columns={columns(t, handleSortChange)}
                noItemsTitle={t("noItems.noAttendees")}
                noItemsDescription={t("noItems.noAttendeesText")}
                noItemsButtonLabel={t("course.noAttendeesCourseButtonLabel")}
                // noItemsButtonHandler={openAddAttendeeSidePanel}
                // sortField={sortField}
                // sortOrder={sortOrder}
                className="fullRecord"
                onRowClick={(report) => {
                  if (isMobile) {
                    console.log("no link in  mobile");
                  } else {
                    console.log("Row clicked, full report data:", report); // Log the clicked row
                    if (!report || !report._id) {
                      console.error(
                        "Invalid report data or missing _id:",
                        report
                      );
                    } else {
                      handleRowClick(report._id);
                    }
                  }
                }}
              />
            </Box>
          </Flex>
        ) : (
          <Flex bg="yellow">
            <Spinner />
          </Flex>
        )}

        {/* STATS  */}
        <Flex
          w="100%"
          justify="space-between"
          maxW={["90%", "900px"]}
          gap="2rem"
          m="3rem auto 0"
          direction={["column", "row"]}
        >
          <Flex
            w={["100%", "300px"]}
            direction="column"
            bg={backgroundLight}
            borderRadius="1.5rem"
            p="1.5rem"
          >
            <Flex align="flex-start">
              <Flex
                align="center"
                justify="center"
                w="2.5rem"
                h="2.5rem"
                bg={greenVivid}
                borderRadius="50%"
                color={textPrimary}
                mr="1rem"
                flexShrink="0"
              >
                <Icon as={TbFileTypeZip} fontSize="1.5rem" />
              </Flex>
              <Flex align="flex-start" justify="flex-start" direction="column">
                <Text fontSize="sm" color={textPrimary} fontWeight="600" m="0">
                  {t("koreport.recordFile")}
                </Text>
                <Text color={primary} fontSize="sm" fontWeight="600" m="0">
                  {fileName}
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" justify="flex-end" w="100%" p="1.5rem 0 1rem">
              <Button
                bg={primary}
                color={white}
                borderRadius="10rem"
                size="lg"
                fontSize="md"
                w="100%"
                _hover={{ bg: textPrimary }}
                onClick={handleDownloadZip}
              >
                {t("koreport.downloadZip")}
              </Button>
            </Flex>
            <Text
              fontSize="xs"
              fontWeight="500"
              color={textSecondary}
              textAlign="center"
            >
              {t("koreport.downloadText")}
            </Text>
          </Flex>
          <Flex w={["100%", "50%"]}>
            <Box w="100%">
              <Text
                fontSize="1rem"
                fontWeight="600"
                color={textPrimary}
                m="0 0 0.5rem"
              >
                {t("record.failedImplants")}
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="500"
                  color={textSecondary}
                  ml={1}
                >
                  ({totalFixtureCount})
                </Text>
              </Text>
              <Box
                border={`1px solid ${newBorder}`}
                borderRadius="1rem"
                p="1rem"
              >
                <FixtureCountHq
                  loading={loading}
                  error={error}
                  data={countsByFixture}
                />
              </Box>
            </Box>
          </Flex>

          <Flex w={["100%", "50%"]}>
            <Box w="100%">
              <Text
                fontSize="1rem"
                fontWeight="600"
                color={textPrimary}
                m="0 0 0.5rem"
              >
                {t("record.replacementFixtures")}
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="500"
                  color={textSecondary}
                  ml={1}
                >
                  ({totalNewFixtureCount})
                </Text>
              </Text>
              <Box
                border={`1px solid ${newBorder}`}
                borderRadius="1rem"
                p="1rem"
              >
                <NewFixtureCountHq
                  loading={loading}
                  error={error}
                  data={countsByNewFixture}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          borderTop={`1px solid ${newBorder}`}
          bg={white}
          align="center"
          justify="center"
          p={["3rem 0 6rem", "3rem 0"]}
          m="6rem 0 0"
        >
          <Link to="https://www.mymegagen.com" target="_blank">
            <Box>
              <Image src={logonew2} h="1.5rem" />

              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                mt="0.5rem"
                _hover={{ color: primary }}
              >
                www.mymegagen.com
              </Text>
            </Box>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default PublicRecordFull;
