import React, { useState, useEffect } from "react";
import { Flex, Box, Button, Heading, Text, useToast } from "@chakra-ui/react";
import {
  dark,
  white,
  textPrimary,
  textSecondary,
  primary,
  textWhite,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import VerificationInputs from "../../../components/input/VerificationInputs";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";
import successAnimation from "../../../assets/lottie/success.json";

const StepSuccess = ({ email }) => {
  // TRANSLATION
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  // STATE
  const [showAnimation, setShowAnimation] = useState(true);

  // REDUX
  const dispatch = useDispatch();

  // CUSTOM
  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  console.log("email", email);

  return (
    <Box mt={0} w="100%" p={["3rem 1.5rem 0", "3rem 1.5rem 0", "0"]}>
      <Flex align="center" justify="center" w="100%" mb={12} direction="column">
        {showAnimation && (
          <Flex m="0 auto" maxW="80px">
            {View}
          </Flex>
        )}

        <Heading
          fontSize={["1.5rem", "1.5rem", "1.875rem"]}
          fontWeight="700"
          color={[white, white, textPrimary]}
          m="1.5rem 0 1rem"
          maxW={["100%", "100%", "80%"]}
          textAlign="center"
        >
          {t("landingMegagen.successTitle")}
        </Heading>

        <Text
          color={[textWhite, textWhite, textSecondary]}
          fontWeight="500"
          w="100%"
          fontSize="md"
          maxW={["100%", "100%", "60%"]}
          textAlign="center"
        >
          {t("landingMegagen.successText")}
          {/* <Text
            as="span"
            fontWeight="500"
            color={[white, white, textSecondary]}
          >
            {email}
          </Text> */}
        </Text>
      </Flex>

      {/* <Text
        color={[textWhite, textWhite, textPrimary]}
        fontWeight="500"
        fontSize={["sm", "sm", "md"]}
        textAlign="center"
        m={["0 0 1rem", "0 0 1rem", "0 0 1.5rem"]}
      >
        {t("landingMegagen.enterVerificationCode")}
      </Text> */}

      <Flex w="100%" align="center" justify="center">
        <Button
          onClick={() => navigate("/login")}
          h="3.5rem"
          fontSize="md"
          borderRadius="10rem"
          bg={[white, white, dark]}
          m="0 auto"
          color={[primary, primary, white]}
          border={[
            `1px solid ${white}`,
            `1px solid ${white}`,
            `1px solid ${dark}`,
          ]}
          textAlign="center"
          w="60%"
          _hover={{
            bg: "rgba(13, 12, 34, 0.8)",
            color: white,
            border: `rgba(13, 12, 34, 0.8)`,
          }}
        >
          {t("common.signin")}
        </Button>
      </Flex>

      {/* <Flex direction="column" w="100%" m="1.5rem 0 0">
        <Text
          fontSize="xs"
          color={[textWhite, textWhite, textSecondary]}
          textAlign="center"
        >
          {t("common.signin")}

          <Text
            as="span"
            fontSize="sm"
            color={[white, white, textSecondary]}
            textAlign="center"
            mt={2}
            ml={1}
          >
            afsfas
          </Text>
        </Text>
      </Flex> */}

      {/* <Agreement /> */}
    </Box>
  );
};

export default StepSuccess;
