import React from "react";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import {
  primary,
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../utils/colors";
import { shade } from "polished";
import Lottie from "lottie-react";
import noItemsFound from "../../assets/lottie/no-items-found.json"; // Assuming you have a lottie file

const NoItems = ({
  isSinglePage,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  noItemsButtonDisabled,
}) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      margin="0 auto 4rem"
      p="0 0 4rem"
      w="100%"
      borderRadius="0.75rem"
      border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
    >
      {!isSinglePage && (
        <Box
          w="100%"
          h="3rem"
          bg={backgroundLight}
          borderBottom={`1px solid ${newBorder}`}
        ></Box>
      )}
      {/* <Image src={images.noData} alt="no Data" /> */}
      <Flex
        maxWidth="600px"
        margin="0 auto"
        overflow="hidden"
        h={isSinglePage ? "160px" : "260px"}
        justifyContent="center"
        alignItems="baseline"
      >
        <Lottie
          style={{
            width: "100%",
            height: "100%",
          }}
          animationData={noItemsFound}
          loop={true}
        />
      </Flex>

      <Heading
        fontSize="1.375rem"
        fontWeight="600"
        color={textPrimary}
        margin="1.5rem 0 0.5rem"
      >
        {noItemsTitle}
      </Heading>
      <Text color={textSecondary} maxW="40%" textAlign="center" m="0 auto">
        {noItemsDescription}
      </Text>

      {noItemsButtonHandler && (
        <Button
          size="lg"
          fontSize="md"
          borderRadius="10rem"
          bg={primary}
          color={white}
          m="2rem 0 0"
          onClick={noItemsButtonHandler}
          isDisabled={noItemsButtonDisabled}
          _hover={{
            bg: shade(0.3, primary),
          }}
        >
          {noItemsButtonLabel}
        </Button>
      )}
    </Flex>
  );
};

export default NoItems;
