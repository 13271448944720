import React, { useEffect } from "react";
import styled from "@emotion/styled";
import {
  Select,
  Box,
  HStack,
  Text,
  Flex,
  Button,
  Icon,
  IconButton,
  Spinner,
  background,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  primaryAlt,
  backgroundLight,
  newBorder,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

import {
  TbChevronsLeft,
  TbChevronLeft,
  TbChevronRight,
  TbChevronsRight,
} from "react-icons/tb";
// import DropdownButton from "../../buttons/DropdownButton";
import DropdownButtonNew from "../../components/buttons/DropdownButtonNew";

const TablePaginationNew = ({
  loading,
  page,
  pages,
  pageSize,
  pageSizes,
  setPage,
  setPageSize,
  totalCount,
}) => {
  const { t } = useTranslation();

  const canPreviousPage = page > 1;

  const canNextPage = page < pages;

  const dropdownOptions = [
    t("employee.doctorTreatmentPlansButton"),
    t("employee.coordinatorTreatmentPlansButton"),
  ];

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const goToLastPage = () => {
    gotoPage(pages);
  };

  // Handle page size change by directly accepting the size value
  const handlePageSizeChange = (size) => {
    setPageSize(size); // Set the new page size
    setPage(1); // Reset to the first page
  };

  // console.log("pageSizes", pageSizes);

  return (
    <>
      {!loading ? (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          padding="1rem 1.5rem"
          w="100%"
          borderTop={
            totalCount > 0 ? `0px solid ${newBorder}` : `1px solid ${newBorder}`
          }
        >
          <Box maxW="70%">
            <Flex alignItems="center" gap="0.5rem">
              <Text color={textSecondary} fontSize="0.8rem" mr="0">
                {t("common.show")}
              </Text>
              <DropdownButtonNew
                initialLabel={pageSize}
                items={pageSizes.map((size) => size.toString())}
                onSelectItem={(size) => handlePageSizeChange(Number(size))}
              />

              <Flex fontSize="0.8rem" color={textSecondary} whiteSpace="nowrap">
                <Text as="span">
                  {t("record.itemsInTotal")}
                  {": "}
                  {totalCount * pages}
                </Text>
              </Flex>
            </Flex>
          </Box>
          <HStack spacing={2}>
            <IconButton
              // w="40px"
              onClick={() => gotoPage(1)}
              isDisabled={!canPreviousPage}
              size="sm"
              fontSize="0.8rem"
              borderRadius="10rem"
              bg={backgroundLight}
              color={textSecondary}
              _hover={{
                bg: primaryAlt,
                color: white,
              }}
            >
              <Icon
                as={TbChevronsLeft}
                // color={textSecondary}
                // transition="all 0.2s"
                // _hover={{
                //   bg: primaryAlt,
                //   color: white,
                // }}
                style={{
                  pointerEvents: canPreviousPage ? "auto" : "none",
                }}
              />
            </IconButton>

            {page !== 1 && (
              <IconButton
                // w="40px"
                onClick={() => previousPage()}
                isDisabled={!canPreviousPage}
                size="sm"
                borderRadius="10rem"
                fontSize="0.8rem"
                bg={backgroundLight}
                color={textSecondary}
                _hover={{
                  bg: primaryAlt,
                  color: white,
                }}
              >
                <Icon
                  as={TbChevronLeft}
                  // color={textSecondary}

                  style={{
                    pointerEvents: canPreviousPage ? "auto" : "none",
                  }}
                />
              </IconButton>
            )}

            {page - 1 > 0 && (
              <IconButton
                onClick={() => gotoPage(page - 1)}
                isDisabled={!canPreviousPage}
                size="sm"
                borderRadius="10rem"
                fontSize="0.8rem"
                bg={backgroundLight}
                color={textSecondary}
                _hover={{
                  bg: primaryAlt,
                  color: white,
                }}
              >
                <Text fontSize="0.8rem">{page - 1}</Text>
              </IconButton>
            )}
            <Buttonas
              onClick={() => gotoPage(page)}
              className="active"
              size="sm"
              // h="2.25rem"
              borderRadius="10rem"
              fontSize="0.8rem"
              bg={backgroundLight}
              color={textSecondary}
              _hover={{
                bg: primaryAlt,
                color: white,
              }}
              flexShrink={0}
            >
              <Text fontSize="0.8rem">{page}</Text>
            </Buttonas>
            {page < pages && (
              <Buttonas
                onClick={() => gotoPage(page + 1)}
                isDisabled={!canNextPage}
                size="sm"
                borderRadius="10rem"
                fontSize="0.8rem"
                bg={backgroundLight}
                color={textSecondary}
                _hover={{
                  bg: primaryAlt,
                  color: white,
                }}
              >
                <Text fontSize="0.8rem">{page + 1}</Text>
              </Buttonas>
            )}

            {page !== pages && (
              <Buttonas
                // w="40px"
                onClick={() => nextPage()}
                isDisabled={!canNextPage}
                size="sm"
                borderRadius="10rem"
                fontSize="0.8rem"
                bg={backgroundLight}
                color={textSecondary}
                _hover={{
                  bg: primaryAlt,
                  color: white,
                }}
              >
                <Icon
                  as={TbChevronRight}
                  // color={textSecondary}
                  // transition="all 0.2s"
                  style={{
                    pointerEvents: canNextPage ? "auto" : "none",
                  }}
                />
              </Buttonas>
            )}

            {page !== pages && (
              <IconButton
                onClick={() => goToLastPage()}
                isDisabled={!canNextPage}
                size="sm"
                borderRadius="10rem"
                // color={textSecondary}
                fontSize="0.8rem"
                bg={backgroundLight}
                color={textSecondary}
                _hover={{
                  bg: primaryAlt,
                  color: white,
                }}
              >
                <Icon
                  as={TbChevronsRight}
                  // color={textSecondary}

                  style={{
                    pointerEvents: canPreviousPage ? "auto" : "none",
                  }}
                />
              </IconButton>
            )}
          </HStack>
        </Flex>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          padding="1rem 1.5rem"
          w="100%"
        >
          {/* <Spinner size="sm" color={primary} /> */}
        </Flex>
      )}
    </>
  );
};

const Buttonas = styled(IconButton)`
  /* width: 40px; */
  background: ${backgroundLight};
  color: ${textSecondary};

  &.active {
    background: ${primary};
    color: ${white};
  }
  &:hover {
    background: ${primaryAlt};
    color: ${white};
  }
`;

export default TablePaginationNew;
