import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Flex, Heading, Text, Button, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Header from "../../landing/layout/Header";
import {
  backgroundLight,
  white,
  textSecondary,
  textPrimary,
  primary,
  newBorder,
} from "../../../utils/colors";
import AccountTabs from "./partials/AccountTabs";
import NoUserAccount from "./partials/NoUserAccount";
import UserDetails from "./partials/UserDetails";
import { getUserClientDetails } from "../../../actions/userActions";
import UserReportsMini from "./partials/UserReportsMini";
import UserEventsMini from "./partials/UserEventsMini";
import Faq from "../../general/partials/Faq";
import Footer from "../../landing/layout/Footer";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);

const UserAccount = () => {
  // GENERAL
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // STATE
  const [isEmployee, setIsEmployee] = useState(false);
  const [topThreeCourses, setTopThreeCourses] = useState([]);

  // REDUX
  const dispatch = useDispatch();

  const userAuthLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userAuthLogin;

  const userGetClientDetails = useSelector(
    (state) => state.userGetClientDetails
  );

  const {
    loading: loadingClientDetails,
    error: errorClientDetails,
    client,
  } = userGetClientDetails;

  // TABS

  // Adjusting tabs based on the client type
  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // HANDLERS

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate("/login");
  //   }
  // }, [userInfo, navigate]);

  useEffect(() => {
    if (userInfo && userInfo.role === "employee") {
      setIsEmployee(true);
      navigate("/admin/dashboard");
    } else {
      setIsEmployee(false);
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (userInfo) {
      console.log("getUserClientDetails");
      dispatch(getUserClientDetails(userInfo._id));
    }
  }, [userInfo]);

  // In the component where you are handling the fetching and passing of data
  useEffect(() => {
    if (client && client.coursesRegistered) {
      const sortedAndFilteredCourses = client.coursesRegistered
        .filter((course) =>
          dayjs(course.course.startDate).utc().isAfter(dayjs())
        ) // Filter for future courses
        .sort((a, b) =>
          dayjs(a.course.startDate).diff(dayjs(b.course.startDate))
        ) // Sort by startDate
        .slice(0, 3); // Take the top 3

      // Set state or pass this directly to the component
      setTopThreeCourses(sortedAndFilteredCourses);
    }
  }, [client]);

  // CUSTOM

  // LOGS
  // console.log("userInfo", userInfo);
  // console.log("client", client);
  console.log("coursesRegistered", client && client.coursesRegistered);

  // // LOADING
  // if (loadingClientDetails) {
  //   return (
  //     <Flex w="100%" p="3rem 0">
  //       <Spinner size="lg" color={primary} />
  //     </Flex>
  //   );
  // }

  // RENDER
  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccount")}</title>
        <meta name="description" content={t("pageDescription.userAccount")} />
      </Helmet>

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        {!isEmployee ? (
          <Flex w="100%" direction="column">
            <AccountTabs tabs={tabs} />

            <Flex direction="column" w="100%" bg={backgroundLight}>
              <Flex
                w="100%"
                maxW={["100%", "100%", "1200px"]}
                m="0 auto"
                p={["3rem 1.5rem 3rem", "3rem 1.5rem 3rem", "3rem 0 6rem"]}
              >
                <Flex
                  w="100%"
                  justify="space-between"
                  gap={["1rem", "1rem", "2rem"]}
                  direction={["column", "column", "row"]}
                >
                  <Flex direction="column">
                    <Heading
                      fontSize="1rem"
                      fontWeight="600"
                      color={textPrimary}
                      m="0 0 1rem"
                    >
                      {t("clientAccount.userAccountDetailsTitle")}
                    </Heading>

                    <UserDetails
                      client={client}
                      loading={loadingClientDetails}
                    />
                  </Flex>

                  <Flex
                    w="100%"
                    direction="column"
                    bg={backgroundLight}
                    borderRadius="2rem"
                    p={["0rem 0rem 0rem", "0rem 0rem", "0rem 0rem"]}
                    gap="2rem"
                  >
                    <Flex w="100%" direction="column">
                      <Flex align="baseline" justify="space-between" w="100%">
                        <Text
                          fontSize="1rem"
                          fontWeight="600"
                          color={textPrimary}
                          m="0 0 1rem"
                        >
                          {t("clientAccount.userAccountReportsTitle")}{" "}
                          {client.reports && client.reports.length > 3 && (
                            <Text
                              as="span"
                              fontSize="0.8rem"
                              color={textSecondary}
                              fontWeight="500"
                            >
                              (
                              {isMobile
                                ? t("clientAccount.showing3ofAlt")
                                : t("clientAccount.showing3of")}{" "}
                              {client.reports && client.reports.length})
                            </Text>
                          )}
                        </Text>
                        <Button
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                          variant="link"
                          _hover={{
                            color: primary,
                            textDecor: "none",
                          }}
                          onClick={() => navigate("/account/forms")}
                        >
                          {t("button.viewAll")}
                        </Button>
                      </Flex>
                      {!loadingClientDetails ? (
                        <UserReportsMini reports={client && client.reports} />
                      ) : (
                        <Flex
                          w="100%"
                          align="center"
                          justify="center"
                          p="6rem 0"
                          bg={white}
                          borderRadius="1rem"
                          direction="column"
                          border={`1px solid ${newBorder}`}
                        >
                          <Spinner size="lg" color={primary} />
                        </Flex>
                      )}
                    </Flex>

                    <Flex w="100%" direction="column" m="0 0 0rem">
                      <Flex align="baseline" justify="space-between" w="100%">
                        <Text
                          fontSize="1rem"
                          fontWeight="600"
                          color={textPrimary}
                          m="0 0 1rem"
                          maxW="400px"
                          isTruncated
                        >
                          {userInfo && userInfo.clientType === "individual"
                            ? t("clientAccount.userAccountEventsTitle")
                            : t(
                                "clientAccount.userAccountEventsTitleCompany"
                              )}{" "}
                          {client.coursesRegistered &&
                            client.coursesRegistered.length > 3 && (
                              <Text
                                as="span"
                                fontSize="0.8rem"
                                color={textSecondary}
                                fontWeight="500"
                              >
                                (
                                {isMobile
                                  ? t("clientAccount.showing3ofAlt")
                                  : t("clientAccount.showing3of")}{" "}
                                {client.coursesRegistered &&
                                  client.coursesRegistered.length}
                                )
                              </Text>
                            )}
                        </Text>
                        <Button
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                          variant="link"
                          _hover={{
                            color: primary,
                            textDecor: "none",
                          }}
                          onClick={() => navigate("/account/events")}
                        >
                          {t("button.viewAll")}
                        </Button>
                      </Flex>

                      {!loadingClientDetails ? (
                        <UserEventsMini
                          userInfo={userInfo}
                          // courses={client && client.coursesRegistered}
                          courses={topThreeCourses}
                        />
                      ) : (
                        <Flex
                          w="100%"
                          align="center"
                          justify="center"
                          p="6rem 0"
                          bg={white}
                          borderRadius="1rem"
                          direction="column"
                          border={`1px solid ${newBorder}`}
                        >
                          <Spinner size="lg" color={primary} />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                bg={white}
                borderTop={`1px solid ${newBorder}`}
                w="100%"
                p="3rem 0"
                borderBottom={`1px solid ${newBorder}`}
              >
                <Flex w="100%" maxW="1200px" m="0 auto">
                  <Faq />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <NoUserAccount />
        )}
      </Flex>
      <Footer />
    </>
  );
};

export default UserAccount;
