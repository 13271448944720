import React from "react";
import { Button, Flex, Tooltip } from "@chakra-ui/react";
import {
  backgroundLight,
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";

const SendRecordModalFooter = ({
  onClose,
  confirmAction,
  generateReportFilesSuccess,
  currentStep,
  isEmailValid,
  handleSendRecord,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      align="center"
      justify="center"
      p="0rem 1.5rem 0.5rem"
      gap="1rem"
      m="2rem 0 0"
      borderTop={`0px solid ${newBorder}`}
    >
      <Button
        size="md"
        fontSize="sm"
        borderRadius="10rem"
        bg={backgroundLight}
        color={textSecondary}
        fontWeight="500"
        p="1rem 1.5rem"
        onClick={onClose}
        _hover={{ bg: primary, color: white }}
      >
        {t("button.back")}
      </Button>

      {currentStep === 1 && (
        <Button
          size="md"
          fontSize="sm"
          borderRadius="10rem"
          bg={textPrimary}
          color={white}
          p="1rem 1.5rem"
          onClick={confirmAction}
          _hover={{ bg: primary, color: white }}
          isDisabled={!generateReportFilesSuccess}
        >
          {t("common.continue")}
        </Button>
      )}

      {currentStep === 2 && (
        <Tooltip
          p="0.5rem 1rem"
          borderRadius="1rem"
          fontSize="xs"
          placement="top"
          label={t("record.recipientEmailNotValid")}
          isDisabled={isEmailValid}
        >
          <Button
            size="md"
            fontSize="sm"
            borderRadius="10rem"
            bg={textPrimary}
            color={white}
            p="1rem 1.5rem"
            onClick={handleSendRecord}
            _hover={{ bg: primary, color: white }}
            isDisabled={!isEmailValid}
          >
            {t("record.sendEmail")}
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

export default SendRecordModalFooter;
