import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react"; // Add these imports
import { useTable, useSortBy } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react"; // Make sure to import Lottie if you're using it
import {
  bgLight,
  backgroundLight,
  textLightBlue,
  primary,
  white,
  textPrimary,
  newBorder,
  textSecondary,
  buttonGreen,
} from "../../utils/colors"; // Assuming colors
import noItemsFound from "../../assets/lottie/no-items-found.json"; // Assuming you have a lottie file
import noServices from "../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";
import { tint, shade } from "polished";

const TableContainerHq = ({
  navigate,
  loading,
  error,
  isSinglePage,
  columns,
  data,
  className,
  navigateTo,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  noItemsButtonDisabled,
  onRowClick,
  toast,
  dispatch,
  deleteHandler,
  isNarrow,
  sortField,
  sortOrder,
  isHeaderWhite,
  noItemsAlt,
  newReportId,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  // Define a state variable to track if the component is in the initial loading state
  const [initialLoading, setInitialLoading] = useState(true);

  const { t } = useTranslation();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [{ id: sortField, desc: sortOrder === "desc" }],
        },
      },
      useSortBy
    );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <TriangleDownIcon aria-label="sorted descending" />
      ) : (
        <TriangleUpIcon aria-label="sorted ascending" />
      )
    ) : null;
  };

  const navigateFunction = (value) => {
    if (value) {
      navigate(`${navigateTo}/${value}`);
    }
  };

  useEffect(() => {
    // If `loading` is `false`, it means the data has been loaded (either successfully or with an error)
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  if (initialLoading) {
    // If the component is in the initial loading state, you can return a loader or null
    return (
      <Flex justify="center" align="center" w="100%" p={20}>
        <Spinner size="xl" color={primary} />
      </Flex>
    );
  }

  // console.log("newReportId", newReportId);

  const noItemsAlternative = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 2rem"
        p="0 0 0rem"
        w="100%"
        borderRadius="0.75rem"

        // border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        <Image h="initial" w="3rem" m="1rem 0 0" src={noServices} />
        <Text mt={2} color={textSecondary} fontSize="sm">
          {t("common.noData")}
        </Text>
      </Flex>
    );
  };

  const noItemsDefault = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 4rem"
        p="0 0 4rem"
        w="100%"
        borderRadius="0.75rem"
        border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        {!isSinglePage && (
          <Box
            w="100%"
            h="3rem"
            bg={backgroundLight}
            borderBottom={`1px solid ${newBorder}`}
          ></Box>
        )}
        {/* <Image src={images.noData} alt="no Data" /> */}
        <Flex
          maxWidth="600px"
          margin="0 auto"
          overflow="hidden"
          h={isSinglePage ? "160px" : "260px"}
          justifyContent="center"
          alignItems="baseline"
        >
          <Lottie
            style={{
              width: "100%",
              height: "100%",
            }}
            animationData={noItemsFound}
            loop={true}
          />
        </Flex>

        <Heading
          fontSize="1.375rem"
          fontWeight="600"
          color={textPrimary}
          margin="1.5rem 0 0.5rem"
        >
          {noItemsTitle}
        </Heading>
        <Text color={textSecondary} maxW="60%" textAlign="center" m="0 auto">
          {noItemsDescription}
        </Text>

        {noItemsButtonHandler && (
          <Button
            size="lg"
            fontSize="md"
            borderRadius="10rem"
            bg={primary}
            color={white}
            m="2rem 0 0"
            onClick={noItemsButtonHandler}
            isDisabled={noItemsButtonDisabled}
            _hover={{
              bg: shade(0.3, primary),
            }}
          >
            {noItemsButtonLabel}
          </Button>
        )}
      </Flex>
    );
  };

  // You can add a new condition here to handle the case when the data length is zero
  if (!data || (data.length === 0 && !loading)) {
    return <>{noItemsAlt ? noItemsAlternative() : noItemsDefault()}</>;
  }

  // const handleRowClick = (e, rowId) => {
  //   if (!onRowClick) {
  //     // Do nothing if onRowClick is not provided
  //     return;
  //   }
  //   // Exclude clicks on input elements like switches or buttons
  //   if (!["INPUT", "BUTTON"].includes(e.target.tagName)) {
  //     onRowClick(rowId);
  //   }
  // };
  const handleRowClick = (e, rowData) => {
    if (!onRowClick) return;

    // Avoid clicks on input or button elements
    if (!["INPUT", "BUTTON"].includes(e.target.tagName)) {
      onRowClick(rowData); // Pass the entire row data
    }
  };

  console.log("Data passed to Table.Container:", data);

  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" w="100%" p={20} minH="600px">
          <Spinner size="xl" color={primary} />
        </Flex>
      ) : error ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Text variant="danger">{error}</Text>
        </Flex>
      ) : (
        <Table {...getTableProps()} className={className}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    bg={isHeaderWhite ? white : bgLight}
                    color={textLightBlue}
                    fontSize="0.688rem"
                    fontWeight="600"
                    borderBottom={
                      isHeaderWhite ? "none" : `1px solid ${newBorder}`
                    }
                    p={isHeaderWhite ? 2 : 4}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Flex align="center">
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  onClick={(e) => handleRowClick(e, row.original)}
                  _hover={{
                    bg: onRowClick ? "#f8fbff" : "#f8fbff",
                    cursor: onRowClick ? "pointer" : "default",
                  }}
                  style={{
                    background:
                      row.original &&
                      row.original.trackingNumber &&
                      row.original.trackingNumber === newReportId
                        ? "#eaf3ff"
                        : "",
                    // background: tint(0.8, primary),
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td p={4} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default TableContainerHq;
