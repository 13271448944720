import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Text,
  Icon,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  textSecondary,
  backgroundLight,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { REPORT_IMPLANT_FAILURE_CREATE_RESET } from "../../../../constants/reportConstants";
import { createImplantFailureReport } from "../../../../actions/reportActions";
import { FaUserCircle, FaCheck } from "react-icons/fa";
import {
  TbDental,
  TbDentalBroken,
  TbRadioactiveFilled,
  TbArrowsLeftRight,
} from "react-icons/tb";
import Step0Admin from "../../../client/implant-fail-form/ImplantFailForm/partials/Step0Admin";
import Step1 from "../../../client/implant-fail-form/ImplantFailForm/partials/Step1";
import Step2 from "../../../client/implant-fail-form/ImplantFailForm/partials/Step2";
import Step3 from "../../../client/implant-fail-form/ImplantFailForm/partials/Step3";
import Step4 from "../../../client/implant-fail-form/ImplantFailForm/partials/Step4";
import StepSuccessAdmin from "../../../client/implant-fail-form/ImplantFailForm/partials/StepSuccessAdmin";
import FormControlClientSelect from "../../../../components/input/FormControlClientSelect";

const CreateImplantFailReport = ({ close, setNewReportId }) => {
  // GENERAL
  const { t } = useTranslation();
  const toast = useToast();

  // STEPS
  const steps = [
    {
      label: t("implantForm.step0TitleAlt"),
      description: t("implantForm.step0Text"),
      icon: FaUserCircle,
    },
    {
      label: t("implantForm.step1TitleAlt"),
      description: t("implantForm.step1Text"),
      icon: TbDental,
    },
    {
      label: t("implantForm.step2TitleAlt"),
      description: t("implantForm.step2Text"),
      icon: TbDentalBroken,
    },
    {
      label: t("implantForm.step3TitleAlt"),
      description: t("implantForm.step3Text"),
      icon: TbRadioactiveFilled,
    },
    {
      label: t("implantForm.step4TitleAlt"),
      description: t("implantForm.step4Text"),
      icon: TbArrowsLeftRight,
    },
  ];

  // STATE
  const fixtureOptions = [
    { value: "mini", label: "Mini", shortCode: "MN", core: false },
    { value: "st", label: "ST", shortCode: "ST", core: false },
    { value: "anyone", label: "AnyOne", shortCode: "AO", core: true },
    { value: "anyridge", label: "AnyRidge", shortCode: "AR", core: true },
    {
      value: "bluediamond",
      label: "Blue Diamond",
      shortCode: "BD",
      core: true,
    },
    { value: "ezplus", label: "EZ Plus", shortCode: "EZ", core: false },
    { value: "rescue", label: "Rescue", shortCode: "RS", core: false },
  ];

  const [client, setClient] = useState(null);
  const [showData, setShowData] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [stepSuccess, setStepSuccess] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userId, setUserId] = useState(null);
  const [clientType, setClientType] = useState("business");
  const [email, setEmail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [companyName, setCompanyName] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);

  const [fixture, setFixture] = useState(null);
  const [core, setCore] = useState(null);
  const [diameter, setDiameter] = useState("");
  const [length, setLength] = useState("");
  const [refCode, setRefCode] = useState("");
  const [lotNumber, setLotNumber] = useState("");

  const [tooth, setTooth] = useState(null);
  const [surgeonFirstName, setSurgeonFirstName] = useState("");
  const [surgeonLastName, setSurgeonLastName] = useState("");
  const [surgeryDate, setSurgeryDate] = useState("");
  const [failureDate, setFailureDate] = useState("");

  const [beforeXrayImage, setBeforeXrayImage] = useState("");
  const [afterXrayImage, setAfterXrayImage] = useState("");

  const [newFixture, setNewFixture] = useState(null);
  const [newCore, setNewCore] = useState(null);
  const [newDiameter, setNewDiameter] = useState("");
  const [newLength, setNewLength] = useState("");
  const [newRefCode, setNewRefCode] = useState("");

  const [reason, setReason] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const [author, setAuthor] = useState("");
  const [authorType, setAuthorType] = useState("employee");

  const [userTrackingNumber, setUserTrackingNumber] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureCreate = useSelector(
    (state) => state.reportImplantFailureCreate
  );

  const {
    loading: loadingCreate,
    error: errorCreate,
    success,
    trackingNumber,
  } = reportImplantFailureCreate;

  // VALIDATION
  //   const isTitleValid = title.length >= 1;
  //   const isStartDateValid = startDate !== "";
  //   const isEndDateValid = endDate !== "";

  // IS CONTINUE DISABLED
  //   const isContinueDisabled =
  //     !isTitleValid || !isStartDateValid || !isEndDateValid;

  // HANDLERS
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createImplantFailureReport(
        userId,
        clientType,
        email,
        firstName,
        lastName,
        companyName,
        companyCode,
        fixture,
        core,
        diameter,
        length,
        refCode,
        lotNumber,
        tooth,
        surgeonFirstName,
        surgeonLastName,
        surgeryDate,
        failureDate,
        beforeXrayImage,
        afterXrayImage,
        newFixture,
        newCore,
        newDiameter,
        newLength,
        newRefCode,
        reason,
        reasonText,
        author,
        authorType
      )
    );
  };

  const handleClientTypeChange = () => {
    if (clientType === "individual") {
      setClientType("company");
    } else {
      setClientType("individual");
    }
  };

  const handleFixtureChange = (value) => {
    setFixture(value);
    setCore(null);
    setDiameter("");
    setLength("");
    setRefCode("");
    setLotNumber();
  };

  const handleNewFixtureChange = (value) => {
    setNewFixture(value);
    setNewCore(null);
    setNewDiameter("");
    setNewLength("");
    setNewRefCode("");
  };

  // reset everything and start new form fresh
  const newFormHandler = () => {
    setActiveStep(0);
    setStepSuccess(false);
    setFixture(null);
    setCore(null);
    setDiameter("");
    setLength("");
    setRefCode("");
    setLotNumber("");
    setTooth(null);
    setSurgeonFirstName("");
    setSurgeonLastName("");
    setSurgeryDate("");
    setFailureDate("");
    setBeforeXrayImage("");
    setAfterXrayImage("");
    setNewFixture(null);
    setNewCore(null);
    setNewDiameter("");
    setNewLength("");
    setNewRefCode("");
    setReason(null);
    setReasonText("");
    setBeforeXrayImage("");
    setAfterXrayImage("");
  };

  /// USE EFFECT
  useEffect(() => {
    if (userInfo) {
      setIsLoggedIn(true);
      setUserId(userInfo._id);
      setAuthor(userInfo._id);
      setAuthorType("employee");
    } else {
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (client) {
      setUserId(client.value);
      setEmail(client.email);
      setFirstName(client.firstName);
      setLastName(client.lastName);
      setCompanyName(client.companyName);
      setCompanyCode(client.companyCode);
      setClientType(client.clientType);
    } else {
      setUserId(null);
      setEmail("");
      setFirstName("");
      setLastName("");
      setCompanyName("");
      setCompanyCode("");
    }
  }, [client]);

  // USE EFFECT
  useEffect(() => {
    if (success && trackingNumber) {
      console.log("trackingNumber", trackingNumber);
      setUserTrackingNumber(trackingNumber);
      setStepSuccess(true);

      toast({
        title: t("implantForm.successTitle"),
        description: t("implantForm.successText"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setFixture(null);
      setCore(null);
      setDiameter("");
      setLength("");
      setRefCode("");
      setLotNumber("");
      setTooth(null);
      setSurgeonFirstName("");
      setSurgeonLastName("");
      setSurgeryDate("");
      setFailureDate("");
      setBeforeXrayImage("");
      setAfterXrayImage("");
      setNewFixture(null);
      setNewCore(null);
      setNewDiameter("");
      setNewLength("");
      setNewRefCode("");
      setReason(null);
      setReasonText("");
      dispatch({ type: REPORT_IMPLANT_FAILURE_CREATE_RESET });
      // close();

      setNewReportId(trackingNumber);
    } else if (errorCreate) {
      toast({
        title: t("implantForm.errorTitle"),
        description: t("implantForm.errorText"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [success, errorCreate]);

  //LOGS
  // STEPS TOUCHED
  const [isStep3Touched, setIsStep3Touched] = useState(false);

  // STEPS VALIDATION

  //step 0
  const isFirstNameValid = firstName !== "";
  const isLastNameValid = lastName !== "";
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isEmailValid = isValidEmail(email);
  const isCompanyNameValid = companyName !== "";
  const isCompanyCodeValid = companyCode !== "";

  //step1
  const isFixtureValid = fixture !== null;
  const isCoreValid = core !== null || core !== "";
  const isDiameterValid = diameter !== "" && diameter !== null;
  const isLengthValid = length !== "" && length !== null;
  const isLotNumberValid = lotNumber !== "" && lotNumber !== null;

  //step2
  const isToothValid = tooth !== null;
  const isSurgeonFirstNameValid = surgeonFirstName !== "";
  const isSurgeonLastNameValid = surgeonLastName !== "";
  const isReasonValid = reason !== null;
  const isReasonTextValid =
    reason !== "Other" || (reason === "Other" && reasonText.trim() !== "");
  const isFailureDateValid = failureDate !== null && failureDate !== "";
  const isSuregeryDateValid = surgeryDate !== null && surgeryDate !== "";

  const isStep0ValidIndividual =
    isFirstNameValid && isLastNameValid && isEmailValid;

  const isStep0ValidCompany =
    isCompanyNameValid && isEmailValid && isCompanyCodeValid;

  //step3
  const isBeforeXrayImageValid = beforeXrayImage !== "";
  const isAfterXrayImageValid = afterXrayImage !== "";

  //step4
  const isNewFixtureValid = newFixture !== null;
  const isNewCoreValid = newCore !== null || newCore !== "";
  const isNewDiameterValid = newDiameter !== "" && newDiameter !== null;
  const isNewLengthValid = newLength !== "" && newLength !== null;

  // steps validation
  const isStep0Valid =
    clientType === "individual" ? isStep0ValidIndividual : isStep0ValidCompany;

  const isStep1Valid =
    isFixtureValid &&
    isCoreValid &&
    isDiameterValid &&
    isLengthValid &&
    isLotNumberValid;

  const isStep2Valid =
    isToothValid &&
    isSurgeonFirstNameValid &&
    isSurgeonLastNameValid &&
    isFailureDateValid &&
    isSuregeryDateValid &&
    isReasonValid &&
    isReasonTextValid; // include the reasonText validation here

  const isStep3Valid = isBeforeXrayImageValid && isAfterXrayImageValid;

  const isStep4Valid =
    isNewFixtureValid &&
    isNewCoreValid &&
    isNewDiameterValid &&
    isNewLengthValid;

  const stepValidations = [
    isStep0Valid,
    isStep1Valid,
    isStep2Valid,
    isStep3Valid,
    isStep4Valid,
    // Add other step validations here
  ];

  // LOGS

  // console.log("client", client);
  // console.log("clientType:", clientType);

  // console.log("author:", author);
  // console.log("authorType:", authorType);

  console.log("fixture:", fixture);
  console.log("refCode:", refCode);
  console.log("newFixture:", newFixture);
  console.log("newRefCode:", newRefCode);

  console.log("trackingNumber:", trackingNumber);

  console.log("userId:", userId);

  // RENDER
  return (
    <Flex direction="column" w="100%">
      {!stepSuccess && (
        <Flex
          bg={backgroundLight}
          w="100%"
          borderRadius="1rem"
          overflow="hidden"
        >
          {steps.map((step, index) => (
            <Flex
              key={index}
              w="100%"
              p="1rem 1rem"
              bg={backgroundLight}
              borderRadius="1rem"
              cursor={isLoggedIn ? "pointer" : "not-allowed"}
              // onClick={() => setActiveStep(index)}
              onClick={isLoggedIn ? () => setActiveStep(index) : null}
              align="center"
              justify="center"
              position="relative"

              // isDisabled={activeStep < index}
              // _disabled={{ opacity: "0.5", cursor: "not-allowed" }}
            >
              <Flex position="absolute" bottom="0px" w="100%" h="2px">
                <Flex
                  w="100%"
                  h="2px"
                  // bg={
                  //   activeStep === index
                  //     ? primary
                  //     : activeStep > index
                  //     ? "green"
                  //     : textSecondary
                  // }
                  bg={activeStep === index ? primary : "transparent"}
                />
              </Flex>

              <Flex align="center" justify="center" w="100%">
                {/* <Icon
                  as={step.icon}
                  color={activeStep === index ? textPrimary : textSecondary}
                  mr="2"
                /> */}
                <Text
                  fontSize="13px"
                  color={activeStep === index ? textPrimary : textSecondary}
                  fontWeight="500"
                  textAlign="center"
                >
                  {step.label}
                </Text>
                {stepValidations[index] && (
                  <Icon as={FaCheck} color="green" fontSize="0.5rem" ml={1.5} />
                )}
              </Flex>
              {/* Show check icon if the current step index is greater than the step being rendered */}
            </Flex>
          ))}
        </Flex>
      )}

      {!stepSuccess ? (
        <>
          <Flex w="100%" direction="column" m="1.5rem 0 0">
            {activeStep === 0 && (
              <>
                <Flex direction="column">
                  <Flex direction="column" w="100%" m="0rem 0 0">
                    <Step0Admin
                      userInfo={userInfo}
                      setActiveStep={setActiveStep}
                      clientType={clientType}
                      setClientType={setClientType}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      companyName={companyName}
                      setCompanyName={setCompanyName}
                      companyCode={companyCode}
                      setCompanyCode={setCompanyCode}
                      email={email}
                      setEmail={setEmail}
                      isValid={isStep0Valid}
                      isLoggedIn={isLoggedIn}
                      client={client}
                      setClient={setClient}
                    />
                  </Flex>
                </Flex>
              </>
            )}

            {activeStep === 1 && (
              <Step1
                handleFixtureChange={handleFixtureChange}
                fixtureOptions={fixtureOptions}
                setActiveStep={setActiveStep}
                userInfo={userInfo}
                fixture={fixture}
                setFixture={setFixture}
                core={core}
                setCore={setCore}
                diameter={diameter}
                setDiameter={setDiameter}
                length={length}
                setLength={setLength}
                refCode={refCode}
                setRefCode={setRefCode}
                lotNumber={lotNumber}
                setLotNumber={setLotNumber}
                setNewFixture={setNewFixture}
                setNewCore={setNewCore}
                setNewDiameter={setNewDiameter}
                setNewLength={setNewLength}
                isValid={isStep1Valid}
              />
            )}

            {activeStep === 2 && (
              <Step2
                setActiveStep={setActiveStep}
                userInfo={userInfo}
                tooth={tooth}
                setTooth={setTooth}
                surgeonFirstName={surgeonFirstName}
                setSurgeonFirstName={setSurgeonFirstName}
                surgeonLastName={surgeonLastName}
                setSurgeonLastName={setSurgeonLastName}
                surgeryDate={surgeryDate}
                setSurgeryDate={setSurgeryDate}
                failureDate={failureDate}
                setFailureDate={setFailureDate}
                reason={reason}
                setReason={setReason}
                reasonText={reasonText}
                setReasonText={setReasonText}
                isValid={isStep2Valid}
                minW="30px"
              />
            )}

            {activeStep === 3 && (
              <Step3
                setActiveStep={setActiveStep}
                trackingNumber={trackingNumber}
                beforeXrayImage={beforeXrayImage}
                setBeforeXrayImage={setBeforeXrayImage}
                afterXrayImage={afterXrayImage}
                setAfterXrayImage={setAfterXrayImage}
                isValid={isStep3Valid}
                setIsStep3Touched={setIsStep3Touched}
              />
            )}

            {activeStep === 4 && (
              <Step4
                handleNewFixtureChange={handleNewFixtureChange}
                fixtureOptions={fixtureOptions}
                newFixture={newFixture}
                setNewFixture={setNewFixture}
                newCore={newCore}
                setNewCore={setNewCore}
                newDiameter={newDiameter}
                setNewDiameter={setNewDiameter}
                newLength={newLength}
                setNewLength={setNewLength}
                setStepSuccess={setStepSuccess}
                fixture={fixture}
                core={core}
                diameter={diameter}
                length={length}
                newRefCode={newRefCode}
                setNewRefCode={setNewRefCode}
                lotNumber={lotNumber}
                handleSubmit={handleSubmit}
                isValid={isStep4Valid}
                isStep0Valid={isStep0Valid}
                isStep1Valid={isStep1Valid}
                isStep2Valid={isStep2Valid}
                isStep3Valid={isStep3Valid}
                isStep4Valid={isStep4Valid}
              />
            )}
          </Flex>
        </>
      ) : (
        <Flex>
          <StepSuccessAdmin
            setActiveStep={setActiveStep}
            userInfo={userInfo}
            userTrackingNumber={userTrackingNumber}
            newFormHandler={newFormHandler}
            clientEmail={email}
            close={close}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CreateImplantFailReport;
