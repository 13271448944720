import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  Button,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Flex,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";
import {
  primary,
  textSecondary,
  newBorder,
  white,
  backgroundLight,
  secondary,
  textPrimary,
} from "../../utils/colors";
import ReactCountryFlag from "react-country-flag";
import { shade } from "polished";

const LanguageButtonLocal = ({
  currentLanguage,
  languages,
  onChangeLanguage,
  onMenuClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuButtonRef = React.useRef();

  const getCountryCode = (languageCode) => {
    const languageToCountryMap = {
      en: "GB", // Mapping English to Great Britain
      lt: "LT", // Lithuanian remains the same
      ko: "KR", // Korean remains the same
    };

    return languageToCountryMap[languageCode] || languageCode.toUpperCase();
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = (language) => {
    onChangeLanguage(language); // Call the parent's function
    setIsOpen(false); // Close the menu
  };

  const isDisabledLanguage = (language) => {
    return false;
  };

  // console.log("isOpen", isOpen);
  // console.log("currentLanguage", currentLanguage);

  return (
    <Box p={0} borderRadius="0.75rem">
      <Box p={0} borderRadius="0.75rem" ml={2}>
        <Menu isLazy onOpen={handleMenuOpen} onClose={handleMenuClose}>
          <MenuButton
            as={Button}
            ref={menuButtonRef}
            bg={shade(0, backgroundLight)}
            color={textSecondary}
            border={`0px solid ${newBorder}`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="0.75rem"
            p="0rem 0.5rem"
            transition="0.2s all"
            onClick={() => setIsOpen(!isOpen)}
            _hover={{ bg: shade(0, backgroundLight), color: textSecondary }}
            _active={{ bg: shade(0, white), color: textPrimary }}
            cursor="pointer" // Add cursor style
            role="group"
          >
            <Flex align="center" textAlign="center" justify="center">
              <Box w="12px" h="initial" mr={2} pb="2px">
                <ReactCountryFlag
                  countryCode={getCountryCode(currentLanguage)}
                  svg
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "3px",
                    border: `1px solid ${newBorder}`,
                  }}
                  title={currentLanguage.toUpperCase()}
                />
              </Box>

              <Text
                textAlign="center"
                fontSize="0.75rem"
                p={0}
                m={0}
                fontWeight="600"
                letterSpacing="0.2px"
                lineHeight={1}
              >
                {currentLanguage.toUpperCase()}
              </Text>

              <Icon
                as={FaCaretDown}
                h="0.75rem"
                w={isOpen ? "0.75rem" : "0rem"}
                ml={1}
                opacity="0.5"
                transition="all 0.2s ease"
                transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                _groupHover={{
                  opacity: 1,
                  width: "0.75rem",
                  transition: "all 0.2s ease",
                }}
              />
            </Flex>
          </MenuButton>

          <MenuList
            p={0}
            m={0}
            borderRadius="1rem"
            overflow="hidden"
            maxWidth="100px"
            w="100px"
          >
            {languages.map((language) => (
              <MenuItem
                key={language}
                py={2}
                px={4}
                borderBottom={`1px solid ${newBorder}`}
                color={language === currentLanguage ? primary : textSecondary}
                transition="0.2s all"
                _hover={{
                  bg: isDisabledLanguage(language) ? null : primary,
                  color: isDisabledLanguage(language) ? null : "white",
                }}
                // isDisabled={isDisabledLanguage(language)}
                // onClick={() =>
                //   isDisabledLanguage(language)
                //     ? null
                //     : handleMenuItemClick(language)
                // }
                onClick={() => handleMenuItemClick(language)} // Allow all languages to be clickable
              >
                <Box w="16px" h="initial" mr={2} pb="2px">
                  <ReactCountryFlag
                    countryCode={getCountryCode(language)}
                    svg
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "3px",
                      border: `1px solid ${newBorder}`,
                    }}
                    title={language.toUpperCase()}
                  />
                </Box>
                <Text fontSize="xs" fontWeight="500" lineHeight="1">
                  {language.toUpperCase()}
                </Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};

export default LanguageButtonLocal;
