import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  Icon,
  useToast,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecordDetailsAction,
  generateReportFilesAction,
  sendRecordAction,
  downloadRecordFileAction,
} from "../../../../actions/recordActions";
import SendRecordModalFooter from "../partials/SendRecordModalFooter";
import SendRecordModalHeader from "../partials/SendRecordModalHeader";
import SendRecordModalTop from "../partials/SendRecordModalTop";
import SendReceordModalMain from "../partials/SendRecordModalMain";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import {
  greenVivid,
  newBorder,
  primary,
  textPrimary,
  white,
} from "../../../../utils/colors";
import SendRecordMessage from "../partials/SendRecordMessage";
import { TbFileTypeZip } from "react-icons/tb";
import { GENERATE_REPORT_FILES_RESET } from "../../../../constants/recordConstants";
import SendRecordSuccess from "../partials/SendRecordSuccess";

const SendRecordModal = ({ isOpen, onClose, record, recordId }) => {
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [currentStep, setCurrentStep] = useState(1);

  const [recipientEmail, setRecipientEmail] = useState("");
  const [sender, setSender] = useState("support@mymegagen.com");
  const [language, setLanguage] = useState("en");
  const [link, setLink] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  // LINK
  // if node env development set link to localhost
  useEffect(() => {
    if (recordId && process.env.NODE_ENV === "development") {
      setLink(`http://localhost:3000/hq/reports/${recordId}`);
    } else {
      setLink(`https://mymegagen.com/hq/reports/${recordId}`);
    }
  }, [recordId]);

  // SENDER
  useEffect(() => {
    if (record) {
      setSender(record.personInCharge);
    }
  }, [record]);

  // REDUX
  const dispatch = useDispatch();

  const getRecordDetails = useSelector((state) => state.getRecordDetails);
  const {
    loading: recordDetailsLoading,
    success: recordDetailsSuccess,
    error: recordDetailsError,
    recordDetails,
  } = getRecordDetails;

  const generateReportFiles = useSelector((state) => state.generateReportFiles);
  const {
    loading: generateReportFilesLoading,
    success: generateReportFilesSuccess,
    error: generateReportFilesError,
    zipFilePath,
    generatedFiles,
  } = generateReportFiles;

  const sendRecord = useSelector((state) => state.sendRecord);
  const {
    loading: sendLoading,
    success: sendSuccess,
    error: sendError,
  } = sendRecord;

  // HANDLERS
  const handleDownloadZip = () => {
    dispatch(downloadRecordFileAction(recordId));
  };

  const handleSendRecord = (id) => {
    console.log("SEND", id);
    dispatch(
      sendRecordAction(
        recordId, // Correctly passed as URL param
        recipientEmail,
        link,
        language,
        sender
      )
    );
  };

  const handleGenerateFiles = () => {
    dispatch(generateReportFilesAction(recordId));
  };

  const continueHandler = () => {
    setCurrentStep(2);
  };

  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
    // localStorage.setItem("language", lang);
  };

  // EXTRACT NAME PREFIX
  const extractFileName = (path) => {
    return path.split("/").pop();
  };

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isEmailValid = isValidEmail(recipientEmail);

  // TOASTS
  useEffect(() => {
    if (recordDetailsError) {
      toast({
        title: t("record.recordDetailsError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.log("RECORD DETAILS ERROR", recordDetailsError);
    }

    if (generateReportFilesError) {
      toast({
        title: t("record.generateReportFilesError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [recordDetailsError, generateReportFilesError]);

  // USE EFFECT
  useEffect(() => {
    if (recordId) {
      dispatch(getRecordDetailsAction(recordId));
    }
  }, [recordId]);

  useEffect(() => {
    dispatch({ type: GENERATE_REPORT_FILES_RESET });
  }, []);

  useEffect(() => {
    if (sendSuccess) {
      // toast({
      //   title: t("record.sendRecordSuccess"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });

      setShowSuccess(true);
    }
  }, [sendSuccess]);

  // useEffect(() => {
  //   if (generateReportFilesSuccess) {
  //     dispatch({ type: GENERATE_REPORT_FILES_RESET });
  //   }
  // }, [generateReportFilesSuccess]);

  // get if available local storage language and set it once
  useEffect(() => {
    const lang = localStorage.getItem("language");
    console.log("LOCAL STORAGE LANG", lang);
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  // LOG

  console.log("recordDetails", recordDetails);
  console.log("zipFilePath", zipFilePath);
  console.log("generatedFiles", generatedFiles);

  console.log("record", record);

  console.log("recipientEmail", recipientEmail);
  console.log("sender", sender);
  console.log("language", language);
  console.log("link", link);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="1.5rem"
        w="100%"
        minW="600px"
        overflow="visible"
      >
        <SendRecordModalHeader
          recordDetails={recordDetails}
          onClose={onClose}
          loading={recordDetailsLoading}
        />

        {!recordDetailsLoading && !sendLoading ? (
          <>
            {!showSuccess ? (
              <ModalBody p="0rem 1.5rem 1.5rem">
                {currentStep === 1 ? (
                  <SendRecordModalTop
                    recordDetails={recordDetails}
                    loading={recordDetailsLoading}
                  />
                ) : (
                  <Flex
                    w="100%"
                    direction="column"
                    align="center"
                    justify="center"
                    textAlign="center"
                    m="1.5rem 0 0.5rem"
                  >
                    <Text
                      fontSize="1.25rem"
                      fontWeight="600"
                      color={textPrimary}
                      lineHeight="1.5"
                      // m="1rem auto 0"
                      maxW="90%"
                    >
                      {t("record.sendRecordTitle")}
                    </Text>
                  </Flex>
                )}
                {/* MAIN */}

                {currentStep === 1 && (
                  <>
                    <SendReceordModalMain
                      loading={generateReportFilesLoading}
                      handleGenerateFiles={handleGenerateFiles}
                      zipFilePath={zipFilePath}
                      generatedFiles={generatedFiles}
                      generateReportFilesSuccess={generateReportFilesSuccess}
                      handleDownloadZip={handleDownloadZip}
                    />
                  </>
                )}

                {currentStep === 2 && (
                  <Flex
                    w="100%"
                    direction="column"
                    border={`1px solid ${newBorder}`}
                    borderRadius="1.5rem"
                    p="1rem 1.5rem"
                    m="0rem 0 0"
                  >
                    <Flex w="100%" direction="column">
                      <Flex w="100%" justify="space-between" gap="1rem">
                        <FormControlDefault
                          type="email"
                          isRequired
                          label={t("record.recipientEmail")}
                          value={recipientEmail}
                          placeholder={t("record.placeholderRecipientEmail")}
                          onChange={(e) => {
                            setRecipientEmail(e.target.value);
                          }}
                          w="50%"
                        />
                        <FormControlDefault
                          type="email"
                          isRequired
                          label={t("record.sender")}
                          value={sender}
                          placeholder={t("record.placeholderSender")}
                          onChange={(e) => {
                            setSender(e.target.value);
                          }}
                          w="50%"
                          isDisabled
                          noDisabledOpacity
                        />
                      </Flex>

                      <Flex w="100%" direction="column">
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textPrimary}
                          m="0 0 0.5rem"
                        >
                          {t("record.messageTextLabel")}
                        </Text>
                        <SendRecordMessage
                          recordDetails={recordDetails}
                          language={language}
                          handleChangeLanguage={handleChangeLanguage}
                        />
                      </Flex>

                      <Flex w="100%" direction="column" m="1rem 0">
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textPrimary}
                          m="0 0 0.5rem"
                        >
                          {t("record.recordAttachment")}
                        </Text>
                        <Flex align="center">
                          <Flex
                            align="center"
                            justify="center"
                            w="2rem"
                            h="2rem"
                            bg={greenVivid}
                            borderRadius="50%"
                            color={textPrimary}
                            mr="0.5rem"
                          >
                            <Icon as={TbFileTypeZip} fontSize="1rem" />
                          </Flex>
                          <Text
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                            isTruncated
                          >
                            {extractFileName(zipFilePath)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}

                {/* BOTTOM */}
                <SendRecordModalFooter
                  onClose={onClose}
                  confirmAction={continueHandler}
                  generateReportFilesSuccess={generateReportFilesSuccess}
                  currentStep={currentStep}
                  isEmailValid={isEmailValid}
                  handleSendRecord={handleSendRecord}
                />
              </ModalBody>
            ) : (
              <ModalBody p="0rem 1.5rem 1.5rem">
                <SendRecordSuccess
                  recipientEmail={recipientEmail}
                  recordDetails={recordDetails}
                  loading={recordDetailsLoading}
                  onClose={onClose}
                />
              </ModalBody>
            )}
          </>
        ) : (
          <Flex align="center" justify="center" w="100%" h="100%" p="6rem 2rem">
            <Spinner size="xl" color={primary} />
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SendRecordModal;
