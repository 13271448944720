import React, { useState } from "react";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useLottie } from "lottie-react";
import successAnimation from "../../../../assets/lottie/success.json";
import { useTranslation } from "react-i18next";
import {
  white,
  textPrimary,
  textSecondary,
  textWhite,
  primary,
  backgroundLight,
  newBorder,
} from "../../../../utils/colors";
import SendRecordModalTop from "./SendRecordModalTop";

const SendRecordSuccess = ({
  recipientEmail,
  recordDetails,
  loading,
  onClose,
}) => {
  const { t } = useTranslation();

  // STATE
  const [showAnimation, setShowAnimation] = useState(true);

  // CUSTOM
  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      w="100%"
      p="3rem 0"
    >
      {showAnimation && (
        <Flex m="0 auto" maxW="80px">
          {View}
        </Flex>
      )}

      <Heading
        fontSize={["1.5rem", "1.5rem", "1.75rem"]}
        fontWeight="700"
        color={[white, white, textPrimary]}
        m="1.5rem 0 1rem"
        maxW={["100%", "100%", "80%"]}
        textAlign="center"
      >
        {t("record.successTitle")}
      </Heading>

      <Text
        color={[textWhite, textWhite, textSecondary]}
        fontWeight="500"
        w="100%"
        fontSize="sm"
        maxW={["100%", "100%", "60%"]}
        textAlign="center"
        m="0 0 2rem"
      >
        {t("record.recipient")}:
        <Text
          as="span"
          fontWeight="500"
          color={[primary, primary, primary]}
          ml={2}
        >
          {recipientEmail}
        </Text>
      </Text>

      <SendRecordModalTop
        recordDetails={recordDetails}
        loading={loading}
        isSuccess
      />

      <Flex
        w="100%"
        align="center"
        justify="center"
        p="0rem 1.5rem 0.5rem"
        gap="1rem"
        m="2rem 0 0"
        borderTop={`0px solid ${newBorder}`}
      >
        <Button
          size="md"
          fontSize="sm"
          borderRadius="10rem"
          bg={primary}
          color={white}
          fontWeight="500"
          p="1rem 1.5rem"
          onClick={onClose}
          _hover={{ bg: textPrimary, color: white }}
        >
          {t("button.close")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SendRecordSuccess;
