import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Stack,
  Flex,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  buttonRedText,
  newBorder,
  backgroundLight,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaCircleInfo, FaXmark } from "react-icons/fa6";
import { white } from "../../utils/colors";

const ConfirmModalNew = ({
  isOpen,
  onClose,
  confirmAction,
  deleteName,
  warningTitle,
  warningLabel,
  title,
}) => {
  const { t } = useTranslation();

  console.log("deleteName", deleteName);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="1.5rem" w="100%" minW="480px">
        <ModalHeader p="1rem 1.5rem" borderBottom={`1px solid ${newBorder}`}>
          <Flex w="100%" align="center" justify="space-between">
            <Text fontSize="1rem" fontWeight="600" color={textPrimary}>
              {title}
            </Text>
            <IconButton
              size="sm"
              bg={backgroundLight}
              color={textSecondary}
              onClick={onClose}
              borderRadius="10rem"
              _hover={{ bg: primary, color: white }}
            >
              <Icon as={FaXmark} />
            </IconButton>
          </Flex>
        </ModalHeader>

        <ModalBody p="0rem 1.5rem 1.5rem">
          <Flex
            w="100%"
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            p={"1rem 2rem 2rem"}
          >
            {/* <Icon
              as={FaCircleInfo}
              fontSize="1.875rem"
              color={primary}
              position="relative"
              top="-1px"
              m="1rem 0 0"
            /> */}
            <Text
              fontSize="1.25rem"
              fontWeight="600"
              color={textPrimary}
              lineHeight="1.5"
              m="1rem auto 0"
              maxW="90%"
            >
              {warningTitle}
            </Text>

            {deleteName && (
              <Text
                fontSize="1.125rem"
                fontWeight="600"
                ml={1}
                color={buttonRedText}
              >
                {deleteName}
              </Text>
            )}

            {warningLabel && (
              <Text
                mt={3}
                as="span"
                fontSize="sm"
                fontWeight="500"
                color={buttonRedText}
              >
                {warningLabel}
              </Text>
            )}
          </Flex>

          <Flex
            w="100%"
            align="center"
            justify="center"
            p="0rem 1.5rem 0.5rem"
            gap="1rem"
            borderTop={`0px solid ${newBorder}`}
          >
            <Button
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              bg={backgroundLight}
              color={textSecondary}
              fontWeight="500"
              p="1rem 1.5rem"
              onClick={onClose}
              _hover={{ bg: primary, color: white }}
            >
              {t("button.back")}
            </Button>

            <Button
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              bg={textPrimary}
              color={white}
              p="1rem 1.5rem"
              onClick={confirmAction}
              _hover={{ bg: primary, color: white }}
            >
              {t("common.yes")}
            </Button>
          </Flex>
        </ModalBody>

        {/* <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderTop={`1px solid ${newBorder}`}
          gap={4}
        >
          <Button
            type="submit"
            colorScheme="red"
            size="lg"
            fontSize="md"
            p="0 2rem"
            borderRadius="10rem"
            onClick={confirmAction}
          >
            {t("common.yes")}
          </Button>
          <Button
            variant="outline"
            size="lg"
            fontSize="md"
            p="0 2rem"
            borderRadius="10rem"
            color={textSecondary}
            onClick={onClose}
            _hover={{
              bg: backgroundLight,
              color: textPrimary,
            }}
          >
            {t("common.no")}
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModalNew;
