import React from "react";
import {
  Text,
  HStack,
  Stack,
  Flex,
  Tooltip,
  Icon,
  Box,
  Heading,
  Button,
  IconButton,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the locale you want to use (if not already done)
import "dayjs/locale/lt";
import {
  white,
  textSecondary,
  textPrimary,
  buttonBlueText,
  buttonBlue,
  backgroundLight,
  newBorder,
} from "../../../../utils/colors";
import { FaInfo, FaSuitcase, FaUser } from "react-icons/fa6";
import StatusButton from "../../../../components/buttons/StatusButton"; // Update the path accordingly
import MoreButton from "../../../../components/buttons/MoreButton";
import { shade } from "polished";
import {
  TbDentalBroken,
  TbHash,
  TbUser,
  TbCalendar,
  TbRadioactive,
  TbExchange,
  TbDocument,
} from "react-icons/tb";
import { t } from "i18next";
import { FaExchangeAlt } from "react-icons/fa";

const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
  return (
    <Flex align="center">
      <Flex
        w="1.25rem"
        h="1.25rem"
        align="center"
        justify="center"
        bg={white}
        mr={2}
        borderRadius="50%"
        flexShrink="0"
      >
        <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
      </Flex>
      {text && (
        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          opacity={0.6}
          mr={1}
          whiteSpace="nowrap"
        >
          {text}:
        </Text>
      )}

      <Text
        fontSize="sm"
        fontWeight="500"
        textTransform={capitalize ? "capitalize" : "none"}
        maxW={maxW}
        isTruncated={maxW ? true : false}
      >
        {label}
      </Text>
    </Flex>
  );
};

export const columns = (translate, handleSortChange) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("createdAt")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("implantFailureReports.columnDateId")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "createdAt",
      width: 180,
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original) {
          // console.warn("Row original is undefined:", row);
          return <Text>No Data</Text>; // Render fallback
        }
        const { trackingNumber, createdAt } = row.original;

        // console.log("row.original", row.original);

        return (
          <Stack gap="0rem" w="150px" textAlign="left">
            <Heading fontSize="sm" fontWeight="600" textTransform="capitalize">
              {dayjs(createdAt).format("MMMM D")}
            </Heading>
            <Text color={textSecondary} fontSize="xs" fontWeight="500">
              {trackingNumber}
            </Text>
          </Stack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("koreport.columnSurgeon")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      width: 180,
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original) {
          console.warn("Row original is undefined:", row);
          return <Text>No Data</Text>; // Render fallback
        }

        const {
          surgeonFirstName,
          surgeonLastName,
          email,
          clientType,
          companyName,
        } = row.original;

        const name = `${surgeonFirstName} ${surgeonLastName}`;

        const showEmail = clientType === "individual" ? email : companyName;

        return (
          <HStack gap={2}>
            <Stack gap={0.5} overflow="hidden" textAlign="left">
              <Text
                fontSize="13px"
                color={textPrimary}
                fontWeight="500"
                isTruncated
                lineHeight={1}
                maxW="150px"
              >
                asfa {name}
              </Text>

              {email && (
                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxW="100px"
                >
                  {showEmail}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box
          onClick={() => handleSortChange("fixture")}
          textAlign="center"
          w="100%"
        >
          <Flex
            align="center"
            justify="center"
            textAlign="center"
            cursor="pointer"
          >
            <Text textAlign="center">
              {translate("implantFailureReports.columnExchange")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "fixture",
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original) {
          console.warn("Row original is undefined:", row);
          return <Text>No Data</Text>; // Render fallback
        }

        const {
          fixture,
          core,
          diameter,
          length,
          refCode,
          lotNumber,
          newFixture,
          newCore,
          newDiameter,
          newLength,
        } = row.original;

        return (
          <Flex justify="center">
            <Flex align="center" borderRadius="1rem" p="0.5rem 1rem">
              <Stack
                gap={0}
                overflow="hidden"
                w="155px"
                align="center"
                justify="center"
              >
                <Flex align="center" justify="center" textAlign="center">
                  {/* <Text
                    fontSize="13px"
                    color={textPrimary}
                    fontWeight="500"
                    isTruncated
                    lineHeight={1}
                  >
                    {(fixture && t(`implants.${fixture}`)) || "-"}
                  </Text> */}
                  <Text fontSize="13px" fontWeight="600" color={textPrimary}>
                    {t(`implants.${fixture}`)}
                    {core === null ? null : (
                      <>
                        {core === "regular" ||
                        core === "special" ||
                        core === "deep" ? (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({t(`coreSimple.${core}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            - (C{core})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                </Flex>

                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  textAlign="center"
                >
                  Ø{diameter}mm/L{length}{" "}
                </Text>
              </Stack>

              <Flex
                align="center"
                p="0.75rem"
                bg={backgroundLight}
                borderRadius="10rem"
                m="0 1rem"
              >
                <Icon as={FaExchangeAlt} boxSize={2.5} color={textSecondary} />
              </Flex>

              <Stack gap={0} overflow="hidden" w="155px">
                <Flex align="center" justify="center">
                  <Text fontSize="13px" fontWeight="600" color={textPrimary}>
                    {t(`implants.${newFixture}`)}
                    {newCore === null ? null : (
                      <>
                        {newCore === "regular" ||
                        newCore === "special" ||
                        newCore === "deep" ? (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({t(`coreSimple.${newCore}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            - (C{newCore})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                </Flex>

                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  textAlign="center"
                >
                  Ø{newDiameter}mm/L{newLength}{" "}
                </Text>
              </Stack>
            </Flex>
          </Flex>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      width: 280,
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original) {
          console.warn("Row original is undefined:", row);
          return <Text>No Data</Text>; // Render fallback
        }

        const {
          status,
          _id,
          createdAt,
          reason,
          reasonText,
          tooth,
          surgeryDate,
          failureDate,
          beforeXrayImage,
          afterXrayImage,
          surgeonFirstName,
          surgeonLastName,
          documentNumber,
          trackingNumber,
          lotNumber,
        } = row.original;

        // console.log("row.original", row.original);

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateSpeakerStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <Tooltip
              p="1rem 1rem"
              label={
                <Flex direction="column" gap="0.5rem">
                  <TooltipEntry
                    label={dayjs(createdAt).format("MMMM D, YYYY")}
                    icon={TbCalendar}
                    capitalize
                  />
                  <TooltipEntry label={trackingNumber} icon={TbHash} />
                  <Flex w="100%" h="1px" bg={textSecondary} m="0.25rem 0" />
                  {reason && reasonText && reason === "Other" ? (
                    <TooltipEntry
                      label={reasonText}
                      icon={TbDentalBroken}
                      maxW="160px"
                      text={t("implantForm.reason")}
                    />
                  ) : (
                    <TooltipEntry
                      label={t(`implantForm.${reason}`)}
                      icon={TbDentalBroken}
                      maxW="160px"
                      text={t("implantForm.reason")}
                    />
                  )}

                  <TooltipEntry
                    label={tooth}
                    icon={TbDentalBroken}
                    text={t("implantForm.teeth")}
                  />

                  <TooltipEntry
                    label={
                      surgeryDate === null
                        ? "-"
                        : dayjs(surgeryDate).format("YYYY-MM-DD")
                    }
                    icon={TbDentalBroken}
                    text={t("implantForm.surgeryDate")}
                  />

                  <TooltipEntry
                    label={
                      failureDate !== null
                        ? dayjs(failureDate).format("YYYY-MM-DD")
                        : "-"
                    }
                    icon={TbDentalBroken}
                    text={t("implantForm.failureDateAlt")}
                  />

                  <TooltipEntry
                    label={
                      beforeXrayImage
                        ? t("implantForm.exists")
                        : t("implantForm.noExists")
                    }
                    icon={TbRadioactive}
                    text={t("implantForm.beforeImage")}
                  />
                  <TooltipEntry
                    label={
                      afterXrayImage
                        ? t("implantForm.exists")
                        : t("implantForm.noExists")
                    }
                    icon={TbRadioactive}
                    text={t("implantForm.afterImage")}
                  />
                  <TooltipEntry
                    label={
                      surgeonFirstName &&
                      surgeonLastName &&
                      surgeonFirstName + " " + surgeonLastName
                    }
                    icon={TbUser}
                    text={t("implantForm.surgeon")}
                  />

                  <TooltipEntry
                    label={lotNumber ? lotNumber : "-"}
                    icon={TbHash}
                    maxW="250px"
                    text={t("implantForm.lotNumber")}
                  />
                </Flex>
              }
              borderRadius="1rem"
            >
              <IconButton
                size="md"
                bg={buttonBlue}
                border={`0px solid ${newBorder}`}
                borderRadius="10rem"
                display={["none", "none", "flex"]}
                mr="0.5rem"
              >
                <Icon as={FaInfo} fontSize="0.75rem" color={buttonBlueText} />
              </IconButton>
            </Tooltip>
          </HStack>
        );
      },
    },
  ];
};
