import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { mobile } from "../../../utils/breakpoints";
import { Heading, Text, Image, Button, Flex } from "@chakra-ui/react";
import { white, primary } from "../../../utils/colors";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
// import hero1 from "../../../assets/images/hero1.png";
import { shade } from "polished";
import bg1 from "../../../assets/images/bg1.png";
import VideoWrapper from "../components/VideoWrapper";
// import bg1Mobile from "../../../assets/images/bg1-mobile.png";

export const Hero = ({ userInfo }) => {
  let { t } = useTranslation("");
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: mobile });

  console.log("userInfo", userInfo);

  return (
    <>
      <Flex w="100%" h="100%" p="0" direction="column">
        <Flex
          w="100%"
          bg={white}
          position="relative"
          minH={["initial", "initial", "calc(100vh - 100px)"]}
          maxH="100%"
          overflow="hidden"
          top={["80px", "80px", "100px"]}
        >
          {!isMobile ? (
            <VideoWrapper />
          ) : (
            <Image
              src={bg1}
              w="100%"
              h="100%"
              objectFit="cover"
              position="absolute"
            />
          )}
          <Flex
            w="100%"
            direction="column"
            m="0 auto"
            maxW={["100%", "100%", "1200px"]}
            justify="center"
            position="relative"
            zIndex={2}
          >
            <Flex
              w="100%"
              align="center"
              justify="space-between"
              direction={["column-reverse", "column-reverse", "row"]}
              // bg={`url(${hero1}) no-repeat center center`}
            >
              <Flex
                w={["100%", "100%", "50%"]}
                direction="column"
                p={["4rem 1.5rem 4rem", "0rem 0", "0rem 0"]}
                position="relative"
                zIndex={2}
              >
                <Heading
                  as={motion.div}
                  initial={{ opacity: 0, y: 100, x: 0, skewX: 0, skewY: 0 }}
                  animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                  transition={{ ease: "easeOut", duration: 0.3, delay: 0.3 }}
                  m="0 0 1rem"
                  fontSize={["2.5rem", "2.5rem", "5rem"]}
                  lineHeight="1.1"
                  color={white}
                >
                  {t("landingMegagen.heroTitle")}
                </Heading>
                <Text
                  as={motion.div}
                  initial={{ opacity: 0, y: 100, x: 0, skewX: 0, skewY: 0 }}
                  animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                  transition={{ ease: "easeOut", duration: 0.3, delay: 0.3 }}
                  maxWidth={isMobile ? "100%" : "86%"}
                  fontSize="1.125rem"
                  fontWeight="500"
                  color={white}
                  opacity="0.8"
                  lineHeight="1.6"
                >
                  {t("landingMegagen.heroText")}
                </Text>
                <Flex
                  as={motion.div}
                  align="center"
                  m="2rem 0 0"
                  initial={{ opacity: 0, y: 100, x: 0, skewX: 0, skewY: 0 }}
                  animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                  transition={{ ease: "easeOut", duration: 0.3, delay: 0.3 }}
                >
                  <Button
                    size="lg"
                    fontSize="md"
                    bg={primary}
                    color={white}
                    borderRadius="10rem"
                    px="3rem"
                    w={["100%", "100%", "initial"]}
                    _hover={{ bg: shade(0.3, primary) }}
                    onClick={
                      userInfo
                        ? userInfo.role === "employee"
                          ? () => navigate("/admin/dashboard")
                          : () => navigate("/account")
                        : () => navigate("/register")
                    }
                  >
                    {userInfo
                      ? userInfo.role === "employee"
                        ? t("landingMegagen.menuAdmin")
                        : t("landingMegagen.menuMyPortal")
                      : t("landingMegagen.registerButton")}
                  </Button>
                </Flex>
              </Flex>
              <Flex w={["100%", "100%", "50%"]} h="100%">
                <Flex
                  w={["100%", "100%", "46%"]}
                  maxW={["100%", "100%", "46%"]}
                  position={["relative", "relative", "absolute"]}
                  right="0px"
                  top="0px"
                  borderTopLeftRadius={["0rem", "0rem", "2.5rem"]}
                  borderBottomLeftRadius={["0rem", "0rem", "2.5rem"]}
                  overflow="hidden"
                  h="100%"
                >
                  {/* <Image
                    as={motion.img}
                    initial={{ opacity: 0, y: 0, x: 100, skewX: 0, skewY: 0 }}
                    animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                    transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
                    src={hero1}
                    w="100%"
                    h="auto"
                    maxH={["60vh", "60vh", "initial"]}
                    objectFit="cover"
                    alt="hero"
                  /> */}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <div
            as={motion.div}
            initial={{ opacity: 0, y: 0, x: 0, skewX: 0, skewY: 0 }}
            animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 1.2 }}
          >
            {/* {isMobile ? null : <Gradient src={images.primary_gradient} />}
        {isMobile ? null : <GradientBg src={images.primary_gradient} />} */}
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default Hero;
