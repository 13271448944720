export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAIL = "COURSE_LIST_FAIL";

export const COURSE_DETAILS_REQUEST = "COURSE_DETAILS_REQUEST";
export const COURSE_DETAILS_SUCCESS = "COURSE_DETAILS_SUCCESS";
export const COURSE_DETAILS_FAIL = "COURSE_DETAILS_FAIL";
export const COURSE_DETAILS_RESET = "COURSE_DETAILS_RESET";

export const COURSE_UPDATE_REQUEST = "COURSE_UPDATE_REQUEST";
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS";
export const COURSE_UPDATE_FAIL = "COURSET_UPDATE_FAIL";
export const COURSE_UPDATE_RESET = "COURSE_UPDATE_RESET";

export const COURSE_CREATE_REQUEST = "COURSE_CREATE_REQUEST";
export const COURSE_CREATE_SUCCESS = "COURSE_CREATE_SUCCESS";
export const COURSE_CREATE_FAIL = "COURSE_CREATE_FAIL";
export const COURSE_CREATE_RESET = "COURSE_CREATE_RESET";

export const COURSE_DELETE_REQUEST = "COURSE_DELETE_REQUEST";
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS";
export const COURSE_DELETE_FAIL = "COURSE_DELETE_FAIL";
export const COURSE_DELETE_RESET = "COURSE_DELETE_RESET";

export const COURSE_ATTENDEES_LIST_REQUEST = "COURSE_LIST_ATTENDEES_REQUEST";
export const COURSE_ATTENDEES_LIST_SUCCESS = "COURSE_LIST_ATTENDEES_SUCCESS";
export const COURSE_ATTENDEES_LIST_FAIL = "COURSE_LIST_ATTENDEES_FAIL";

export const COURSE_ATTENDEE_ADD_REQUEST = "COURSE_ATTENDEE_ADD_REQUEST";
export const COURSE_ATTENDEE_ADD_SUCCESS = "COURSE_ATTENDEE_ADD_SUCCESS";
export const COURSE_ATTENDEE_ADD_FAIL = "COURSE_ATTENDEE_ADD_FAIL";
export const COURSE_ATTENDEE_ADD_RESET = "COURSE_ATTENDEE_ADD_RESET";

export const COURSE_ATTENDEE_REMOVE_REQUEST = "COURSE_ATTENDEE_ADD_REQUEST";
export const COURSE_ATTENDEE_REMOVE_SUCCESS = "COURSE_ATTENDEE_ADD_SUCCESS";
export const COURSE_ATTENDEE_REMOVE_FAIL = "COURSE_ATTENDEE_ADD_FAIL";
export const COURSE_ATTENDEE_REMOVE_RESET = "COURSE_ATTENDEE_ADD_RESET";

export const COURSE_SPEAKERS_LIST_REQUEST = "COURSE_SPEAKERS_LIST_REQUEST";
export const COURSE_SPEAKERS_LIST_SUCCESS = "COURSE_SPEAKERS_LIST_SUCCESS";
export const COURSE_SPEAKERS_LIST_FAIL = "COURSE_SPEAKERS_LIST_FAIL";
export const COURSE_SPEAKERS_LIST_RESET = "COURSE_SPEAKERS_LIST_RESET";

export const COURSE_SPEAKER_ADD_REQUEST = "COURSE_SPEAKER_ADD_REQUEST";
export const COURSE_SPEAKER_ADD_SUCCESS = "COURSE_SPEAKER_ADD_SUCCESS";
export const COURSE_SPEAKER_ADD_FAIL = "COURSE_SPEAKER_ADD_FAIL";
export const COURSE_SPEAKER_ADD_RESET = "COURSE_SPEAKER_ADD_RESET";

export const COURSE_SPEAKER_REMOVE_REQUEST = "COURSE_SPEAKER_REMOVE_REQUEST";
export const COURSE_SPEAKER_REMOVE_SUCCESS = "COURSE_SPEAKER_REMOVE_SUCCESS";
export const COURSE_SPEAKER_REMOVE_FAIL = "COURSE_SPEAKER_REMOVE_FAIL";
export const COURSE_SPEAKER_REMOVE_RESET = "COURSE_SPEAKER_REMOVE_RESET";

export const COURSE_STATUS_UPDATE_REQUEST = "COURSE_STATUS_UPDATE_REQUEST";
export const COURSE_STATUS_UPDATE_SUCCESS = "COURSE_STATUS_UPDATE_SUCCESS";
export const COURSE_STATUS_UPDATE_FAIL = "COURSE_STATUS_UPDATE_FAIL";
export const COURSE_STATUS_UPDATE_RESET = "COURSE_STATUS_UPDATE_RESET";

export const COURSE_ATTENDEE_STATUS_UPDATE_REQUEST =
  "COURSE_ATTENDEE_STATUS_UPDATE_REQUEST";
export const COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS =
  "COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS";
export const COURSE_ATTENDEE_STATUS_UPDATE_FAIL =
  "COURSE_ATTENDEE_STATUS_UPDATE_FAIL";
export const COURSE_ATTENDEE_STATUS_UPDATE_RESET =
  "COURSE_ATTENDEE_STATUS_UPDATE_RESET";

export const COURSE_DESCRIPTION_UPDATE_REQUEST =
  "COURSE_DESCRIPTION_UPDATE_REQUEST";

export const COURSE_DESCRIPTION_UPDATE_SUCCESS =
  "COURSE_DESCRIPTION_UPDATE_SUCCESS";
export const COURSE_DESCRIPTION_UPDATE_FAIL = "COURSE_DESCRIPTION_UPDATE_FAIL";
export const COURSE_DESCRIPTION_UPDATE_RESET =
  "COURSE_DESCRIPTION_UPDATE_RESET";

export const COURSE_TASKS_LIST_REQUEST = "COURSE_TASKS_LIST_REQUEST";
export const COURSE_TASKS_LIST_SUCCESS = "COURSE_TASKS_LIST_SUCCESS";
export const COURSE_TASKS_LIST_FAIL = "COURSE_TASKS_LIST_FAIL";
export const COURSE_TASKS_LIST_RESET = "COURSE_TASKS_LIST_RESET";

export const COURSE_TASK_ADD_REQUEST = "COURSE_TASK_ADD_REQUEST";
export const COURSE_TASK_ADD_SUCCESS = "COURSE_TASK_ADD_SUCCESS";
export const COURSE_TASK_ADD_FAIL = "COURSE_TASK_ADD_FAIL";
export const COURSE_TASK_ADD_RESET = "COURSE_TASK_ADD_RESET";

export const COURSE_TASK_UPDATE_REQUEST = "COURSE_TASK_UPDATE_REQUEST";
export const COURSE_TASK_UPDATE_SUCCESS = "COURSE_TASK_UPDATE_SUCCESS";
export const COURSE_TASK_UPDATE_FAIL = "COURSE_TASK_UPDATE_FAIL";
export const COURSE_TASK_UPDATE_RESET = "COURSE_TASK_UPDATE_RESET";

export const COURSE_ATTENDEE_PAID_STATUS_UPDATE_REQUEST =
  "COURSE_ATTENDEE_PAID_STATUS_REQUEST";
export const COURSE_ATTENDEE_PAID_STATUS_UPDATE_SUCCESS =
  "COURSE_ATTENDEE_PAID_STATUS_SUCCESS";
export const COURSE_ATTENDEE_PAID_STATUS_UPDATE_FAIL =
  "COURSE_ATTENDEE_PAID_STATUS_FAIL";
export const COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET =
  "COURSE_ATTENDEE_PAID_STATUS_RESET";

export const COURSE_TASKS_POSITIONS_UPDATE_REQUEST =
  "COURSE_TASKS_POSITIONS_UPDATE_REQUEST";
export const COURSE_TASKS_POSITIONS_UPDATE_SUCCESS =
  "COURSE_TASKS_POSITIONS_UPDATE_SUCCESS";
export const COURSE_TASKS_POSITIONS_UPDATE_FAIL =
  "COURSE_TASKS_POSITIONS_UPDATE_FAIL";
export const COURSE_TASKS_POSITIONS_UPDATE_RESET =
  "COURSE_TASKS_POSITIONS_UPDATE_RESET";

export const COURSE_UPDATE_PRICING_REQUEST = "COURSE_UPDATE_PRICING_REQUEST";
export const COURSE_UPDATE_PRICING_SUCCESS = "COURSE_UPDATE_PRICING_SUCCESS";
export const COURSE_UPDATE_PRICING_FAIL = "COURSE_UPDATE_PRICING_FAIL";
export const COURSE_UPDATE_PRICING_RESET = "COURSE_UPDATE_PRICING_RESET";

export const COURSE_UPDATE_IMAGE_REQUEST = "COURSE_UPDATE_IMAGE_REQUEST";
export const COURSE_UPDATE_IMAGE_SUCCESS = "COURSE_UPDATE_IMAGE_SUCCESS";
export const COURSE_UPDATE_IMAGE_FAIL = "COURSE_UPDATE_IMAGE_FAIL";
export const COURSE_UPDATE_IMAGE_RESET = "COURSE_UPDATE_IMAGE_RESET";

export const COURSE_TASK_REMOVE_REQUEST = "COURSE_TASK_REMOVE_REQUEST";
export const COURSE_TASK_REMOVE_SUCCESS = "COURSE_TASK_REMOVE_SUCCESS";
export const COURSE_TASK_REMOVE_FAIL = "COURSE_TASK_REMOVE_FAIL";
export const COURSE_TASK_REMOVE_RESET = "COURSE_TASK_REMOVE_RESET";

export const COURSE_TASK_SUBTASK_ADD_REQUEST =
  "COURSE_TASK_SUBTASK_ADD_REQUEST";
export const COURSE_TASK_SUBTASK_ADD_SUCCESS =
  "COURSE_TASK_SUBTASK_ADD_SUCCESS";
export const COURSE_TASK_SUBTASK_ADD_FAIL = "COURSE_TASK_SUBTASK_ADD_FAIL";
export const COURSE_TASK_SUBTASK_ADD_RESET = "COURSE_TASK_SUBTASK_ADD_RESET";

export const COURSE_TASK_SUBTASK_REMOVE_REQUEST =
  "COURSE_TASK_SUBTASK_REMOVE_REQUEST";
export const COURSE_TASK_SUBTASK_REMOVE_SUCCESS =
  "COURSE_TASK_SUBTASK_REMOVE_SUCCESS";
export const COURSE_TASK_SUBTASK_REMOVE_FAIL =
  "COURSE_TASK_SUBTASK_REMOVE_FAIL";
export const COURSE_TASK_SUBTASK_REMOVE_RESET =
  "COURSE_TASK_SUBTASK_REMOVE_RESET";

export const COURSE_ATTENDEE_PRICE_UPDATE_REQUEST =
  "COURSE_ATTENDEE_PRICE_UPDATE_REQUEST";
export const COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS =
  "COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS";
export const COURSE_ATTENDEE_PRICE_UPDATE_FAIL =
  "COURSE_ATTENDEE_PRICE_UPDATE_FAIL";
export const COURSE_ATTENDEE_PRICE_UPDATE_RESET =
  "COURSE_ATTENDEE_PRICE_UPDATE_RESET";

export const COURSE_PUBLIC_LIST_REQUEST = "COURSE_PUBLIC_LIST_REQUEST";
export const COURSE_PUBLIC_LIST_SUCCESS = "COURSE_PUBLIC_LIST_SUCCESS";
export const COURSE_PUBLIC_LIST_FAIL = "COURSE_PUBLIC_LIST_FAIL";
export const COURSE_PUBLIC_LIST_RESET = "COURSE_PUBLIC_LIST_RESET";

export const COURSE_PUBLIC_DETAILS_REQUEST = "COURSE_PUBLIC_DETAILS_REQUEST";
export const COURSE_PUBLIC_DETAILS_SUCCESS = "COURSE_PUBLIC_DETAILS_SUCCESS";
export const COURSE_PUBLIC_DETAILS_FAIL = "COURSE_PUBLIC_DETAILS_FAIL";
export const COURSE_PUBLIC_DETAILS_RESET = "COURSE_PUBLIC_DETAILS_RESET";

export const COURSE_ADD_ATTENDEE_PUBLIC_REQUEST =
  "COURSE_ADD_ATTENDEE_PUBLIC_REQUEST";
export const COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS =
  "COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS";
export const COURSE_ADD_ATTENDEE_PUBLIC_FAIL =
  "COURSE_ADD_ATTENDEE_PUBLIC_FAIL";
export const COURSE_ADD_ATTENDEE_PUBLIC_RESET =
  "COURSE_ADD_ATTENDEE_PUBLIC_RESET";

export const COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST =
  "COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST";
export const COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS =
  "COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS";
export const COURSE_VERIFY_ATTENDEE_EMAIL_FAIL =
  "COURSE_VERIFY_ATTENDEE_EMAIL_FAIL";
export const COURSE_VERIFY_ATTENDEE_EMAIL_RESET =
  "COURSE_VERIFY_ATTENDEE_EMAIL_RESET";

export const COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST =
  "COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST";
export const COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS =
  "COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS";
export const COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL =
  "COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL";
export const COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_RESET =
  "COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_RESET";

export const COURSE_DUPLICATE_REQUEST = "COURSE_DUPLICATE_REQUEST";
export const COURSE_DUPLICATE_SUCCESS = "COURSE_DUPLICATE_SUCCESS";
export const COURSE_DUPLICATE_FAIL = "COURSE_DUPLICATE_FAIL";
export const COURSE_DUPLICATE_RESET = "COURSE_DUPLICATE_RESET";
