import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box } from "@chakra-ui/react";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/recordSingleReportsColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getMinimalRecordDetailsHqAction,
  getFullRecordDetailsHqAction,
} from "../../../../actions/recordActions";
import RecordSingleReportList from "../list/RecordSingleReportList";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import TablePaginationNew from "../../../../components/table/TablePaginationNew";
import {
  buttonRedText,
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import VideoWrapper from "../../../landing/components/VideoWrapper";
import VerificationInputs from "../../../../components/input/VerificationInputs";
import { useMediaQuery } from "react-responsive";
import VerificationInputsAlt from "../../../../components/input/VerificationInputsAlt";
import { FaCircleInfo } from "react-icons/fa6";
import logonew2 from "../../../../assets/icons/logonew2.svg";
import logonew2white from "../../../../assets/icons/logonew2white.svg";
import PublicRecordFull from "./PublicRecordFull";
import { GET_PUBLIC_RECORD_FULL_DETAILS_RESET } from "../../../../constants/recordConstants";
import LanguageButton from "../../../../components/buttons/LanguageButton";
import dayjs from "dayjs";

const PublicHqRecordSingle = () => {
  // TRANSLATION
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { i18n } = useTranslation();

  // ID
  const id = useParams().id;

  // STATE
  const [email, setEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [validEmail, setValidEmail] = useState(
    localStorage.getItem("validEmail") || ""
  );
  const [validPinCode, setValidPinCode] = useState(
    localStorage.getItem("validPinCode") || ""
  );
  const [loginTimestamp, setLoginTimestamp] = useState(
    localStorage.getItem("loginTimestamp")
  );

  // LANGUAGE
  const [currentLanguage, setCurrentLanguage] = useState("ko");

  // SEARCH
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeSearchTerm = (e) => setSearchTerm(e.target.value);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 10, 25];

  // SORTING
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const handleSortChange = useCallback(
    (column) => {
      const newSortOrder =
        sortField === column && sortOrder === "asc" ? "desc" : "asc";
      setSortField(column);
      setSortOrder(newSortOrder);
    },
    [sortField, sortOrder]
  );

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getPublicRecordMinimalDetails = useSelector(
    (state) => state.getPublicRecordMinimalDetails
  );
  const { loading, error, record } = getPublicRecordMinimalDetails;

  const getPublicRecordFullDetails = useSelector(
    (state) => state.getPublicRecordFullDetails
  );
  const {
    loading: loadingFull,
    error: errorFull,
    recordFull,
    countsByFixture,
    countsByNewFixture,
  } = getPublicRecordFullDetails;

  // TOAST
  const toast = useToast();

  // HANDLERS

  const deleteHandler = (id) => {
    console.log("DELETE", id);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAccessRecord = () => {
    console.log("ACCESS RECORD");

    if (!email || !pinCode) {
      toast({
        title: t("record.enterEmailAndPin"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      console.log("EMAIL", email);
      console.log("PINCODE", pinCode);

      dispatch(getFullRecordDetailsHqAction(id, email, pinCode));
    }
  };

  // Clear stored credentials on logout
  const handleLogout = () => {
    setValidEmail("");
    setValidPinCode("");
    localStorage.removeItem("validEmail");
    localStorage.removeItem("validPinCode");
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  // USE EFFECT

  useEffect(() => {
    // Check localStorage for the language setting
    const savedLanguage = localStorage.getItem("language");

    if (savedLanguage) {
      // If a language is found, set it in i18n
      i18n.changeLanguage(savedLanguage);
    } else {
      // If no language is found, set default to Korean and save it
      const defaultLanguage = "ko"; // Korean
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem("language", defaultLanguage);
    }
  }, [i18n]);

  // Save valid credentials and record
  useEffect(() => {
    if (recordFull && email && pinCode) {
      setValidEmail(email);
      setValidPinCode(pinCode);

      // Save to localStorage
      localStorage.setItem("validEmail", email);
      localStorage.setItem("validPinCode", pinCode);

      toast({
        title: t("koreport.accessGranted"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [recordFull]);

  useEffect(() => {
    const now = Date.now();
    const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
    if (loginTimestamp && now - parseInt(loginTimestamp) > expirationTime) {
      // Expired session
      localStorage.removeItem("validEmail");
      localStorage.removeItem("validPinCode");
      localStorage.removeItem("loginTimestamp");
      setValidEmail("");
      setValidPinCode("");
    }
  }, [loginTimestamp]);

  // TOASTS
  useEffect(() => {
    if (error) {
      // toast({
      //   title: t("record.noRecordFound"),
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
    }

    if (errorFull) {
      toast({
        title: t("record.invalidVerificationCodeOrEmail"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      setPinCode("");
    }
  }, [error, errorFull]);

  // useEffect(() => {
  //   if (recordFull && email && pinCode) {
  //     // Save valid email and PIN code
  //     setValidEmail(email);
  //     setValidPinCode(pinCode);
  //     toast({
  //       title: t("koreport.accessGranted"),
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // }, [recordFull]);

  // USE EFFECT
  // useEffect(() => {
  //   setEmail(""); // Reset email
  //   setPinCode(""); // Reset pin code
  //   dispatch({ type: GET_PUBLIC_RECORD_FULL_DETAILS_RESET }); // Clear full record details
  //   dispatch(getMinimalRecordDetailsHqAction(id)); // Fetch minimal details for the new ID
  // }, [dispatch, id]);

  // useEffect(() => {
  //   // Reset the full record details to avoid seeing stale data
  //   dispatch({ type: GET_PUBLIC_RECORD_FULL_DETAILS_RESET });
  //   dispatch(getMinimalRecordDetailsHqAction(id)); // Fetch minimal details for the new ID
  // }, [dispatch, id]);

  useEffect(() => {
    console.log("id", id);
    dispatch(getMinimalRecordDetailsHqAction(id));
  }, [dispatch, id]);

  // LOGS

  // console.log("id");
  console.log("record", record);

  console.log("recordFull", recordFull);

  // CONDITIONAL RENDERING
  if (validEmail && validPinCode && recordFull) {
    return (
      <PublicRecordFull
        userInfo={{ email: validEmail }}
        recordFull={recordFull}
        countsByFixture={countsByFixture}
        countsByNewFixture={countsByNewFixture}
        loading={loadingFull}
        error={errorFull}
        // handleRowClick={(value) => navigate(`/hq/reports/${id}/${value}`)}
      />
    );
  }

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("record.record")}</title>
        <meta name="description" content={t("record.record")} />
      </Helmet>

      <>
        {!recordFull && (
          <Box
            w="100%"
            h="100%"
            minH="100vh"
            p={["0 0 6rem", "0"]}
            direction="column"
            position="relative"
            overflow="hidden"
          >
            {!userInfo && (
              <Flex
                position="absolute"
                top={["1rem", "3%"]}
                left={["50%", "3%"]}
                transform={["translate(-50%, 0)", "translate(0, 0)"]}
                zIndex={900}
                w={["100%", "initial"]}
                align="center"
                justify="space-between"
                p={["0 1.5rem", "0"]}
              >
                <Link to="https://www.mymegagen.com" target="_blank">
                  <HStack gap={0} alignItems="flex-end">
                    {isMobile ? (
                      <Image src={logonew2} />
                    ) : (
                      <Image src={logonew2white} />
                    )}
                  </HStack>
                </Link>

                <Box
                  position={["relative ", "absolute"]}
                  zIndex={900}
                  display={["block", "none"]}
                >
                  <LanguageButton
                    currentLanguage={currentLanguage}
                    languages={["en", "lt", "ko"]}
                    onChangeLanguage={handleChangeLanguage}
                    onMenuClick={handleMenuClick}
                    isWhite
                  />
                </Box>
              </Flex>
            )}

            <Box display={["none", "block"]}>
              <VideoWrapper />
            </Box>

            {!error && !loading && !loadingFull ? (
              <Flex
                position="absolute"
                align="center"
                justify="center"
                direction="column"
                bg={white}
                borderRadius="2rem"
                p={["3rem 1.5rem 2rem", "3rem 6rem 2rem"]}
                zIndex={2}
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
                minW={["100%", "600px"]}
                maxW={["100%", "600px"]}
              >
                <Box
                  position={["relative ", "absolute"]}
                  top={["initial", "1.5rem"]}
                  right="1.5rem"
                  zIndex={900}
                  display={["none", "block"]}
                >
                  <LanguageButton
                    currentLanguage={currentLanguage}
                    languages={["en", "lt", "ko"]}
                    onChangeLanguage={handleChangeLanguage}
                    onMenuClick={handleMenuClick}
                    isWhite
                  />
                </Box>

                <Flex
                  w="100%"
                  direction="column"
                  align="center"
                  justify="center"
                  p="0  0rem"
                >
                  <Icon
                    as={FaCircleInfo}
                    fontSize="2rem"
                    color={primary}
                    position="relative"
                    top="-1px"
                    m="0rem 0 1rem"
                  />
                  <Text
                    fontSize="1.5rem"
                    fontWeight="600"
                    color={textPrimary}
                    m="0 auto 0.5rem"
                    textAlign="center"
                    lineHeight="1.28"
                  >
                    {t("record.accessRecordTitle")}{" "}
                    <Text as="span" color={primary}>
                      {record && record.email}
                    </Text>
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    maxW="90%"
                    textAlign="center"
                    m="0 auto"
                  >
                    {t("record.accessRecordText")}
                  </Text>
                </Flex>
                <FormControl isRequired m="2rem 0 1rem">
                  <FormLabel
                    fontSize={isMobile ? "sm" : "0.875rem"}
                    htmlFor="email"
                    color={isMobile ? textPrimary : textPrimary}
                  >
                    {t("form.email")}
                  </FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    bg={white}
                    onChange={handleEmailChange}
                    required
                    // w="80%"
                    size="lg"
                    h="3.5rem"
                    fontSize="0.938rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="1rem"
                    placeholder={t("placeholder.email")}
                  />
                </FormControl>
                <FormControl isRequired m="0rem 0 1rem">
                  <FormLabel
                    fontSize={isMobile ? "sm" : "0.875rem"}
                    htmlFor="email"
                    color={isMobile ? textPrimary : textPrimary}
                  >
                    {t("record.verificationCode")}
                  </FormLabel>
                  <Flex align="center" justify="center" m="0 auto">
                    <VerificationInputsAlt
                      length={4}
                      onComplete={(value) => setPinCode(value)}
                    />
                  </Flex>
                </FormControl>

                <Flex w="100%" justify="center" align="center">
                  <Button
                    bg={textPrimary}
                    color={white}
                    size="lg"
                    fontSize="md"
                    borderRadius="10rem"
                    w={["100%", "initial"]}
                    fontWeight="600"
                    onClick={handleAccessRecord}
                    _hover={{ bg: primary }}
                  >
                    {t("record.accessRecord")}
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex
                position="absolute"
                align="center"
                justify="center"
                direction="column"
                bg={white}
                borderRadius="2rem"
                p="3rem 6rem 2rem"
                zIndex={2}
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
                minW="600px"
                maxW="600px"
                minH="560px"
              >
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
          </Box>
        )}

        {error && (
          <Flex
            position="absolute"
            align="center"
            justify="center"
            direction="column"
            bg={white}
            borderRadius="2rem"
            p="0rem 6rem 0rem"
            zIndex={2}
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            minW="600px"
            maxW="600px"
            minH="560px"
            textAlign="center"
          >
            <Icon
              as={FaCircleInfo}
              fontSize="3rem"
              color={buttonRedText}
              position="relative"
              top="-1px"
              m="-3rem auto 1.25rem"
            />
            <Text
              fontSize="1.25rem"
              fontWeight="600"
              color={textPrimary}
              m="0 0 0.25rem"
            >
              {t("record.noRecordFound")}
            </Text>
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              {t("record.noRecordFoundText")}
            </Text>
          </Flex>
        )}
      </>
    </>
  );
};

export default PublicHqRecordSingle;
