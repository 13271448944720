import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Flex, Text, Button, Icon, Box, useToast } from "@chakra-ui/react";
import { newBorder, white, textPrimary, primary } from "../../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
// import { getTransactions } from "../../../../actions/crypto/transactionActions";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import {
  getRecordsListAction,
  deleteRecordAction,
  sendRecordAction,
} from "../../../actions/recordActions";
import RecordList from "./list/RecordList";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/shared/Panel";
import Table from "../../../components/shared/Table";
import CreateRecord from "./sidepanels/CreateRecord";
import SidePanel from "../../../components/sidepanel/SidePanel";
import {
  CREATE_RECORD_RESET,
  DELETE_RECORD_RESET,
  GENERATE_REPORT_FILES_RESET,
  SEND_RECORD_RESET,
} from "../../../constants/recordConstants";
import SendRecordModal from "./sidepanels/SendRecordModal";

const Records = () => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  // STATE
  const [recordId, setRecordId] = useState(null);

  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);

  // MODALS
  const [showSendRecordModal, setShowSendRecordModal] = useState(false);

  // SEARCH
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeSearchTerm = (e) => setSearchTerm(e.target.value);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 25];

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("desc");
  const handleSortChange = useCallback(
    (column) => {
      const newSortOrder =
        sortField === column && sortOrder === "asc" ? "desc" : "asc";
      setSortField(column);
      setSortOrder(newSortOrder);
    },
    [sortField, sortOrder]
  );

  // REDUX
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getRecords = useSelector((state) => state.getRecords);
  const { loading, error, records, pages } = getRecords;

  const createRecord = useSelector((state) => state.createRecord);
  const {
    loading: createLoading,
    success: createSuccess,
    error: createError,
    record,
  } = createRecord;

  const deleteRecord = useSelector((state) => state.deleteRecord);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = deleteRecord;

  const sendRecord = useSelector((state) => state.sendRecord);
  const {
    loading: sendLoading,
    success: sendSuccess,
    error: sendError,
  } = sendRecord;

  // HANDLERS
  const handleSearchOpen = () => setSearchOpen(!searchOpen);

  const handleCloseSendRecordModal = () => {
    setShowSendRecordModal(false);

    dispatch({ type: SEND_RECORD_RESET });
    dispatch({ type: GENERATE_REPORT_FILES_RESET });
  };

  const handleUpdateStatus = (id, status) => {
    console.log("UPDATE", id, status);
  };

  const deleteHandler = (id) => {
    console.log("DELETE", id);
    dispatch(deleteRecordAction(id));
  };

  const handleRowClick = (value) => {
    navigate(`/admin/reports/records/${value}`);
  };

  const openSendRecordHandler = (e, id) => {
    e.stopPropagation(); // Prevent the event from propagating to parent elements
    console.log("SEND", id);
    setRecordId(id);
    setShowSendRecordModal(true);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    document.body.classList.remove("no-scroll");
  };

  // FETCH DATA

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch(
      getRecordsListAction(searchTerm, page, pageSize, sortField, sortOrder)
    );
  }, [
    dispatch,
    searchTerm,
    page,
    pageSize,
    sortField,
    sortOrder,
    deleteSuccess,
    createSuccess,
    sendSuccess,
  ]);

  // TOASTS

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: t("record.deleteRecordSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: DELETE_RECORD_RESET });
    }

    if (deleteError) {
      toast({
        title: t("record.deleteRecordError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (sendSuccess) {
      // toast({
      //   title: t("record.sendRecordSuccess"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });

      dispatch({ type: SEND_RECORD_RESET });
    }

    if (sendError) {
      toast({
        title: t("record.sendRecordError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (createError) {
      toast({
        title: t("record.createRecordError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (createSuccess) {
      // toast({
      //   title: t("record.createRecordSuccess"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });

      setSidePanelOpen(false);
      document.body.classList.remove("no-scroll");

      dispatch({ type: CREATE_RECORD_RESET });
    }
  }, [
    deleteSuccess,
    deleteError,
    sendSuccess,
    sendError,
    createError,
    createSuccess,
  ]);

  useEffect(() => {
    if (recordId) {
      console.log("recordId", recordId);
    }
  }, [recordId]);

  // LOGS
  // console.log("records", records);
  // console.log("created record", record);

  // console.log("recordId", recordId);

  return (
    <>
      {/* Helmet for SEO */}
      <Helmet>
        <title>{t("record.records")}</title>
        <meta name="description" content={t("record.records")} />
      </Helmet>

      {/* MODALS  */}

      {showSendRecordModal && (
        <SendRecordModal
          title={t("course.dublicateCourseTitle")}
          warningTitle={t("course.dublicateCourseWarningTitle")}
          warningLabel={t("course.dublicateCourseWarningLabel")}
          isOpen={showSendRecordModal}
          onClose={handleCloseSendRecordModal}
          confirmAction={() => {
            // confirmDuplicateCourse(courseId);
            setShowSendRecordModal(false);
          }}
          recordId={recordId}
          record={records.find((record) => record._id === recordId)}
        />
      )}

      {/* SIDEPANELS  */}

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("record.createRecordTitle")}
      >
        <CreateRecord close={closeSidePanelModal} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("record.records")}
                onChange={onChangeSearchTerm}
                placeholder={t("record.search")}
                searchName={searchTerm}
                buttonLabel={t("record.createRecord")}
                buttonHandler={openSidePanelModal}
                buttonDisabled={false}
                isFixedTitle={false}
              />
            </Box>

            <RecordList
              loading={loading}
              data={records}
              searchOpen={searchOpen}
              searchTerm={searchTerm}
              onChangeSearchTerm={onChangeSearchTerm}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageSizes={pageSizes}
              pages={pages}
              sortField={sortField}
              sortOrder={sortOrder}
              handleSortChange={handleSortChange}
              handleRowClick={handleRowClick}
              deleteHandler={deleteHandler}
              handleUpdateStatus={handleUpdateStatus}
              noItemsHandler={openSidePanelModal}
              openSendRecordHandler={openSendRecordHandler}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Records;
