import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import {
  backgroundLight,
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import FormControlDatePickerRangeDouble from "../../../../components/input/FormControlDatePickerRangeDouble";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import { shade } from "polished";
import { createRecordAction } from "../../../../actions/recordActions";

const CreateRecord = ({ close }) => {
  // GENERAL
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [personInCharge, setPersonInCharge] = useState("");
  const [email, setEmail] = useState("");
  const [dealer, setDealer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [country, setCountry] = useState("LT");

  // TOUCHED
  const [startDateTouched, setStartDateTouched] = useState(false);
  const [endDateTouched, setEndDateTouched] = useState(false);

  // ERROR
  const [isStartDateError, setIsStartDateError] = useState(false);
  const [isEndDateError, setIsEndDateError] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // HANDLERS
  const handleSubmit = (e) => {
    e.preventDefault();

    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate).toISOString();
      const formattedEndDate = new Date(endDate).toISOString();

      dispatch(
        createRecordAction(
          personInCharge,
          email,
          dealer,
          country,
          formattedStartDate,
          formattedEndDate
        )
      );
    } else {
      toast({
        title: "Error",
        description: "Please select valid start and end dates.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isStartDateValid = startDate !== null && startDate !== "";
  const isEndDateValid = endDate !== null && endDate !== "";
  const isPersonInChargeValid = personInCharge !== "";
  const isEmailValid = isValidEmail(email);
  const isDealerValid = dealer !== "";
  const isCountryValid = country !== "";

  const isContinueDisabled = !(
    isStartDateValid &&
    isEndDateValid &&
    isPersonInChargeValid &&
    isEmailValid &&
    isDealerValid &&
    isCountryValid
  );

  // LOGS
  // console.log({ personInCharge: personInCharge });
  // console.log("isPersonInChargeValid", isPersonInChargeValid);
  // console.log({ email: email });
  // console.log("isEmailValid", isEmailValid);
  // console.log({ dealer: dealer });
  // console.log("isDealerValid", isDealerValid);
  // console.log({ country: country });
  // console.log("isCountryValid", isCountryValid);

  // RENDER
  return (
    <Flex direction="column" w="100%">
      <FormControlDatePickerRangeDouble
        isRequired
        type="date"
        label={t("record.recordReportDates")}
        placeholder={t("record.placeholderRecordReportDates")}
        placeholderStart={t("record.placeholderRecordReportDatesStart")}
        onChange={(start, end) => handleDateRangeChange(start, end)}
        startDate={startDate}
        endDate={endDate}
        onOpen={() => {}}
        w="50%"
        // setDefaultStartTime={handleSetStartTimeToDefault}
        // isTouched={startDate !== "" && endDate !== "" && !isEndDateValid}
        startDateTouched={startDateTouched}
        endDateTouched={endDateTouched}
        isError={isStartDateError || isEndDateError}
        errorMessage={t("formError.courseDateInvalid")}
        setStartDateTouched={setStartDateTouched}
        setEndDateTouched={setEndDateTouched}
      />

      <Flex w="100%" align="center" justify="space-between" gap="1rem">
        <FormControlDefault
          type="text"
          isRequired
          label={t("record.personInCharge")}
          value={personInCharge}
          placeholder={t("record.placeholderPersonInCharge")}
          onChange={(e) => {
            setPersonInCharge(e.target.value);
          }}
          // onBlur={() => setTitleTouched(true)}
          // isTouched={titleTouched}
          // isError={isTitleError}
          w="50%"
          // errorMessage={t("formError.titleRequired")}
        />

        <FormControlDefault
          type="email"
          isRequired
          label={t("form.email")}
          value={email}
          placeholder={t("placeholder.email")}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          // onBlur={() => setTitleTouched(true)}
          // isTouched={titleTouched}
          // isError={isTitleError}
          w="50%"
          // errorMessage={t("formError.titleRequired")}
        />
      </Flex>

      <Flex w="100%" align="center" justify="space-between" gap="1rem">
        <FormControlCountry
          label={t("form.country")}
          value={country}
          onChange={(selectedOption) => {
            setCountry(selectedOption);
          }}
          w={["100%", "50%", "50%"]}
          errorMessage={t("formError.countryRequired")}
          isDisabled
        />
        <FormControlDefault
          type="text"
          isRequired
          label={t("record.dealer")}
          value={dealer}
          placeholder={t("record.placeholderDealer")}
          onChange={(e) => {
            setDealer(e.target.value);
          }}
          w="50%"
        />
      </Flex>

      <Text
        fontSize="xs"
        fontWeight="500"
        color={textSecondary}
        m="1rem 0"
        maxW="80%"
      >
        <Text
          as="span"
          fontWeight="600"
          color={textPrimary}
          textTransform="uppercase"
          mr={1}
        >
          {t("record.disclaimerShort")}:
        </Text>
        {t("record.createRecordInfo")}{" "}
        <Text as="span" color={primary}>
          {" "}
          {t("status.closed")}
        </Text>
        .
      </Text>

      <Flex
        mt={4}
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="2rem 0 0"
      >
        <Flex justify="flex-end">
          <Tooltip
            bg={white}
            borderRadius="1rem"
            p="1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                  {t("record.createRecordValidationError")}
                </Text>
              </Flex>
            }
            isDisabled={!isContinueDisabled}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
              onClick={handleSubmit}
            >
              {t("record.createRecord")}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateRecord;
