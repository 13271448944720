import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_RESET,
  COURSE_CREATE_REQUEST,
  COURSE_CREATE_SUCCESS,
  COURSE_CREATE_FAIL,
  COURSE_CREATE_RESET,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
  COURSE_DELETE_RESET,
  COURSE_ATTENDEES_LIST_REQUEST,
  COURSE_ATTENDEES_LIST_SUCCESS,
  COURSE_ATTENDEES_LIST_FAIL,
  COURSE_ATTENDEE_ADD_REQUEST,
  COURSE_ATTENDEE_ADD_SUCCESS,
  COURSE_ATTENDEE_ADD_FAIL,
  COURSE_ATTENDEE_ADD_RESET,
  COURSE_ATTENDEE_REMOVE_REQUEST,
  COURSE_ATTENDEE_REMOVE_SUCCESS,
  COURSE_ATTENDEE_REMOVE_FAIL,
  COURSE_ATTENDEE_REMOVE_RESET,
  COURSE_SPEAKERS_LIST_REQUEST,
  COURSE_SPEAKERS_LIST_SUCCESS,
  COURSE_SPEAKERS_LIST_FAIL,
  COURSE_SPEAKERS_LIST_RESET,
  COURSE_SPEAKER_ADD_REQUEST,
  COURSE_SPEAKER_ADD_SUCCESS,
  COURSE_SPEAKER_ADD_FAIL,
  COURSE_SPEAKER_ADD_RESET,
  COURSE_SPEAKER_REMOVE_REQUEST,
  COURSE_SPEAKER_REMOVE_SUCCESS,
  COURSE_SPEAKER_REMOVE_FAIL,
  COURSE_SPEAKER_REMOVE_RESET,
  COURSE_STATUS_UPDATE_REQUEST,
  COURSE_STATUS_UPDATE_SUCCESS,
  COURSE_STATUS_UPDATE_FAIL,
  COURSE_STATUS_UPDATE_RESET,
  COURSE_ATTENDEE_STATUS_UPDATE_REQUEST,
  COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS,
  COURSE_ATTENDEE_STATUS_UPDATE_FAIL,
  COURSE_ATTENDEE_STATUS_UPDATE_RESET,
  COURSE_DESCRIPTION_UPDATE_REQUEST,
  COURSE_DESCRIPTION_UPDATE_SUCCESS,
  COURSE_DESCRIPTION_UPDATE_FAIL,
  COURSE_DESCRIPTION_UPDATE_RESET,
  COURSE_TASKS_LIST_REQUEST,
  COURSE_TASKS_LIST_SUCCESS,
  COURSE_TASKS_LIST_FAIL,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_REQUEST,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_SUCCESS,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_FAIL,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET,
  COURSE_TASK_ADD_REQUEST,
  COURSE_TASK_ADD_SUCCESS,
  COURSE_TASK_ADD_FAIL,
  COURSE_TASK_ADD_RESET,
  COURSE_TASK_UPDATE_REQUEST,
  COURSE_TASK_UPDATE_SUCCESS,
  COURSE_TASK_UPDATE_FAIL,
  COURSE_TASK_UPDATE_RESET,
  COURSE_TASKS_POSITIONS_UPDATE_REQUEST,
  COURSE_TASKS_POSITIONS_UPDATE_SUCCESS,
  COURSE_TASKS_POSITIONS_UPDATE_FAIL,
  COURSE_TASKS_POSITIONS_UPDATE_RESET,
  COURSE_UPDATE_PRICING_REQUEST,
  COURSE_UPDATE_PRICING_SUCCESS,
  COURSE_UPDATE_PRICING_FAIL,
  COURSE_UPDATE_PRICING_RESET,
  COURSE_UPDATE_IMAGE_REQUEST,
  COURSE_UPDATE_IMAGE_SUCCESS,
  COURSE_UPDATE_IMAGE_FAIL,
  COURSE_UPDATE_IMAGE_RESET,
  COURSE_TASK_REMOVE_REQUEST,
  COURSE_TASK_REMOVE_SUCCESS,
  COURSE_TASK_REMOVE_FAIL,
  COURSE_TASK_REMOVE_RESET,
  COURSE_TASK_SUBTASK_ADD_REQUEST,
  COURSE_TASK_SUBTASK_ADD_SUCCESS,
  COURSE_TASK_SUBTASK_ADD_FAIL,
  COURSE_TASK_SUBTASK_ADD_RESET,
  COURSE_TASK_SUBTASK_REMOVE_REQUEST,
  COURSE_TASK_SUBTASK_REMOVE_SUCCESS,
  COURSE_TASK_SUBTASK_REMOVE_FAIL,
  COURSE_TASK_SUBTASK_REMOVE_RESET,
  COURSE_ATTENDEE_PRICE_UPDATE_REQUEST,
  COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS,
  COURSE_ATTENDEE_PRICE_UPDATE_FAIL,
  COURSE_ATTENDEE_PRICE_UPDATE_RESET,
  COURSE_PUBLIC_LIST_REQUEST,
  COURSE_PUBLIC_LIST_SUCCESS,
  COURSE_PUBLIC_LIST_FAIL,
  COURSE_PUBLIC_LIST_RESET,
  COURSE_PUBLIC_DETAILS_REQUEST,
  COURSE_PUBLIC_DETAILS_SUCCESS,
  COURSE_PUBLIC_DETAILS_FAIL,
  COURSE_PUBLIC_DETAILS_RESET,
  COURSE_ADD_ATTENDEE_PUBLIC_REQUEST,
  COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS,
  COURSE_ADD_ATTENDEE_PUBLIC_FAIL,
  COURSE_ADD_ATTENDEE_PUBLIC_RESET,
  COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST,
  COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS,
  COURSE_VERIFY_ATTENDEE_EMAIL_FAIL,
  COURSE_VERIFY_ATTENDEE_EMAIL_RESET,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_RESET,
  COURSE_DUPLICATE_REQUEST,
  COURSE_DUPLICATE_SUCCESS,
  COURSE_DUPLICATE_FAIL,
  COURSE_DUPLICATE_RESET,
} from "../constants/courseConstants";

export const courseListReducer = (
  state = { courses: [], pageSize: 10 },
  action
) => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return { loading: true, courses: [], pageSize: state.pageSize };
    case COURSE_LIST_SUCCESS:
      return {
        loading: false,
        courses: action.payload.courses,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: state.pageSize,
      };
    case COURSE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        pageSize: state.pageSize,
      };
    default:
      return state;
  }
};

export const courseDetailsReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case COURSE_DETAILS_SUCCESS:
      return { loading: false, course: action.payload };
    case COURSE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const courseUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_UPDATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case COURSE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_UPDATE_RESET:
      return { course: {} };
    default:
      return state;
  }
};

export const courseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_CREATE_REQUEST:
      return { loading: true };
    case COURSE_CREATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case COURSE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_REQUEST:
      return { loading: true };
    case COURSE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseAttendeesListReducer = (
  state = { attendees: [] },
  action
) => {
  switch (action.type) {
    case COURSE_ATTENDEES_LIST_REQUEST:
      return { loading: true, attendees: [] };
    case COURSE_ATTENDEES_LIST_SUCCESS:
      return { loading: false, attendees: action.payload };
    case COURSE_ATTENDEES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const courseAttendeeAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ATTENDEE_ADD_REQUEST:
      return { loading: true };
    case COURSE_ATTENDEE_ADD_SUCCESS:
      return { loading: false, success: true, attendee: action.payload };
    case COURSE_ATTENDEE_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ATTENDEE_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const courseAttendeeRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ATTENDEE_REMOVE_REQUEST:
      return { loading: true };
    case COURSE_ATTENDEE_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_ATTENDEE_REMOVE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ATTENDEE_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseSpeakersListReducer = (state = { speakers: [] }, action) => {
  switch (action.type) {
    case COURSE_SPEAKERS_LIST_REQUEST:
      return { loading: true, speakers: [] };
    case COURSE_SPEAKERS_LIST_SUCCESS:
      return { loading: false, speakers: action.payload };
    case COURSE_SPEAKERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_SPEAKERS_LIST_RESET:
      return { speakers: [] };
    default:
      return state;
  }
};

export const courseSpeakerAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_SPEAKER_ADD_REQUEST:
      return { loading: true };
    case COURSE_SPEAKER_ADD_SUCCESS:
      return { loading: false, success: true, speaker: action.payload };
    case COURSE_SPEAKER_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_SPEAKER_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const courseSpeakerRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_SPEAKER_REMOVE_REQUEST:
      return { loading: true };
    case COURSE_SPEAKER_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_SPEAKER_REMOVE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_SPEAKER_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseAttendeeStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ATTENDEE_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_ATTENDEE_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ATTENDEE_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseDescriptionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DESCRIPTION_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_DESCRIPTION_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_DESCRIPTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_DESCRIPTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTasksListReducer = (state = { tasks: [] }, action) => {
  switch (action.type) {
    case COURSE_TASKS_LIST_REQUEST:
      return { loading: true, tasks: [] };
    case COURSE_TASKS_LIST_SUCCESS:
      return { loading: false, tasks: action.payload };
    case COURSE_TASKS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const courseAttendeePaidStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ATTENDEE_PAID_STATUS_UPDATE_REQUEST:
      console.log("UPDATE_ATTENDEE_PAID_STATUS_REQUEST dispatched");
      return { loading: true };
    case COURSE_ATTENDEE_PAID_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_ATTENDEE_PAID_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTaskAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASK_ADD_REQUEST:
      return { loading: true };
    case COURSE_TASK_ADD_SUCCESS:
      return { loading: false, success: true, task: action.payload };
    case COURSE_TASK_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASK_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTaskUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASK_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_TASK_UPDATE_SUCCESS:
      return { loading: false, success: true, task: action.payload };
    case COURSE_TASK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASK_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTasksPositionsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASKS_POSITIONS_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_TASKS_POSITIONS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_TASKS_POSITIONS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASKS_POSITIONS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseUpdatePricingReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_UPDATE_PRICING_REQUEST:
      return { loading: true };
    case COURSE_UPDATE_PRICING_SUCCESS:
      return { loading: false, success: true };
    case COURSE_UPDATE_PRICING_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_UPDATE_PRICING_RESET:
      return {};
    default:
      return state;
  }
};

export const courseUpdateImageReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_UPDATE_IMAGE_REQUEST:
      return { loading: true };
    case COURSE_UPDATE_IMAGE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_UPDATE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_UPDATE_IMAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTaskRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASK_REMOVE_REQUEST:
      return { loading: true };
    case COURSE_TASK_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_TASK_REMOVE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASK_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseTaskSubtaskAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASK_SUBTASK_ADD_REQUEST:
      return { loading: true };
    case COURSE_TASK_SUBTASK_ADD_SUCCESS:
      return { loading: false, success: true, subtask: action.payload };
    case COURSE_TASK_SUBTASK_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASK_SUBTASK_ADD_RESET:
      return {};

    default:
      return state;
  }
};

export const courseTaskSubtaskRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_TASK_SUBTASK_REMOVE_REQUEST:
      return { loading: true };
    case COURSE_TASK_SUBTASK_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_TASK_SUBTASK_REMOVE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_TASK_SUBTASK_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseAttendeePriceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ATTENDEE_PRICE_UPDATE_REQUEST:
      return { loading: true };
    case COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_ATTENDEE_PRICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ATTENDEE_PRICE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const coursePublicListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case COURSE_PUBLIC_LIST_REQUEST:
      return { loading: true, courses: [] };
    case COURSE_PUBLIC_LIST_SUCCESS:
      return { loading: false, courses: action.payload };
    case COURSE_PUBLIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_PUBLIC_LIST_RESET:
      return { courses: [] };
    default:
      return state;
  }
};

export const coursePublicDetailsReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_PUBLIC_DETAILS_REQUEST:
      return { loading: true, course: {} };
    case COURSE_PUBLIC_DETAILS_SUCCESS:
      return { loading: false, course: action.payload };
    case COURSE_PUBLIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_PUBLIC_DETAILS_RESET:
      return { course: {} };
    default:
      return state;
  }
};

export const courseAddAttendeePublicReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ADD_ATTENDEE_PUBLIC_REQUEST:
      return { loading: true };
    case COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS:
      return {
        loading: false,
        success: true,
        attendeeId: action.payload.attendee._id,
      };
    case COURSE_ADD_ATTENDEE_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_ADD_ATTENDEE_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const courseVerifyAttendeeEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST:
      return { loading: true };
    case COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case COURSE_VERIFY_ATTENDEE_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_VERIFY_ATTENDEE_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const courseVerifyAttendeeEmailTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST:
      return { loading: true };
    case COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS:
      return {
        loading: false,
        success: true,
        attendee: action.payload.attendee,
      };
    case COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_RESET:
      return {};
    default:
      return state;
  }
};

export const courseDuplicateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DUPLICATE_REQUEST:
      return { loading: true };
    case COURSE_DUPLICATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case COURSE_DUPLICATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_DUPLICATE_RESET:
      return {};
    default:
      return state;
  }
};
