import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  textPrimary,
  errorRed,
  primary,
  backgroundLight,
  textSecondary,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const FormControlRefCode = ({
  isRequired,
  label,
  value,
  placeholder,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
  isDisabled,
  onFocus,
  onBlur,
  fixture,
  diameter,
  core,
  length,

  setValue,
}) => {
  const { t } = useTranslation();

  const [prefix, setPrefix] = useState("");
  const [codeDiameter, setCodeDiameter] = useState("");
  const [codeLength, setCodeLength] = useState("");
  const [codeCore, setCodeCore] = useState("");

  // // Convert your options array to an object for direct access
  // const placeholderOptions = {
  //   anyridge: "AR-###",
  //   "blue-diamond": "BD-###",
  //   "any-one": "AO-###",
  //   st: "ST-###",
  // };

  // USE EFFECT
  // useEffect(() => {
  //   if (length !== null) {
  //     setRefCode(prefix + codeDiameter + codeLength + codeCore);
  //   } else {
  //     setRefCode("");
  //   }
  // }, [prefix, codeDiameter, codeLength, codeCore, length]);

  useEffect(() => {
    if (fixture === "mini") {
      setPrefix("MIF-");
    } else if (fixture === "st") {
      setPrefix("ST-");
    } else if (fixture === "anyone") {
      setPrefix("IF-");
    } else if (fixture === "anyridge") {
      setPrefix("AR-");
    } else if (fixture === "bluediamond") {
      setPrefix("ARO-");
    } else if (fixture === "ezplus") {
      setPrefix("EZIR-");
    } else if (fixture === "rescue") {
      setPrefix("RSWIR-");
    } else {
      setPrefix(""); // Clear code if fixture doesn't match any condition
    }
  }, [fixture, core, diameter]);

  useEffect(() => {
    if (fixture === "anyridge" && (core === "2.8" || core === "3.3")) {
      setPrefix("FANIHX-");
    } else if (fixture === "anyridge" && core === "3.8") {
      setPrefix("AR38-");
    } else if (
      fixture === "anyridge" &&
      core === "4.0" &&
      (diameter === "5.0" || diameter === "5.5")
    ) {
      setPrefix("FANIHX-");
    } else if (fixture === "anyridge" && core === "4.0" && diameter === "6.0") {
      setPrefix("AR40-");
    } else if (fixture === "anyridge" && core === "4.3") {
      setPrefix("AR43-");
    } else if (
      fixture === "anyridge" &&
      core === "4.8" &&
      (diameter === "6.0" ||
        diameter === "6.5" ||
        diameter === "7.0" ||
        diameter === "7.5" ||
        diameter === "8.0")
    ) {
      setPrefix("FANIHX-");
    } else if (fixture === "anyridge" && core === "4.8" && diameter === "5.5") {
      setPrefix("AR48-");
    }
  }, [fixture, core, diameter]);

  useEffect(() => {
    setCodeDiameter("");

    if (diameter === "3.0") {
      setCodeDiameter("30");
    } else if (diameter === "3.3") {
      setCodeDiameter("33");
    } else if (diameter === "3.5") {
      setCodeDiameter("35");
    } else if (diameter === "3.7") {
      setCodeDiameter("37");
    } else if (diameter === "4.0") {
      setCodeDiameter("40");
    } else if (diameter === "4.1") {
      setCodeDiameter("41");
    } else if (diameter === "4.4") {
      setCodeDiameter("44");
    } else if (diameter === "4.5") {
      setCodeDiameter("45");
    } else if (diameter === "4.8") {
      setCodeDiameter("48");
    } else if (diameter === "5.0") {
      setCodeDiameter("50");
    } else if (diameter === "5.5") {
      setCodeDiameter("55");
    } else if (diameter === "6.0") {
      setCodeDiameter("60");
    } else if (diameter === "6.5") {
      setCodeDiameter("65");
    } else if (diameter === "7.0") {
      setCodeDiameter("70");
    } else if (diameter === "7.5") {
      setCodeDiameter("75");
    } else if (diameter === "8.0") {
      setCodeDiameter("80");
    } else if (diameter === "8.5") {
      setCodeDiameter("85");
    } else if (diameter === "9.0") {
      setCodeDiameter("90");
    } else {
      setCodeDiameter("");
    }
  }, [diameter]);

  useEffect(() => {
    setCodeLength("");

    if (length === "5.0") {
      setCodeLength("05");
    } else if (length === "6.0") {
      setCodeLength("06");
    } else if (length === "7.0") {
      setCodeLength("07");
    } else if (length === "8.5") {
      setCodeLength("08");
    } else if (length === "10.0") {
      setCodeLength("10");
    } else if (length === "11.5") {
      setCodeLength("11");
    } else if (length === "13.0") {
      setCodeLength("13");
    } else if (length === "13.5") {
      setCodeLength("13");
    } else if (length === "15.0") {
      setCodeLength("15");
    } else if (length === "18.0") {
      setCodeLength("18");
    } else {
      setCodeLength("");
    }
  }, [length]);

  useEffect(() => {
    setCodeCore("");

    if (fixture === "anyone" && core === "deep") {
      setCodeCore("DC");
    } else if (fixture === "anyone" && core === "special") {
      setCodeCore("SC");
    } else if (
      fixture === "anyridge" &&
      core === "4.0" &&
      (diameter === "5.0" || diameter === "5.5")
    ) {
      setCodeCore("SC");
    } else if (fixture === "bluediamond" && core === "deep") {
      setCodeCore("DC");
    } else if (fixture === "ezplus") {
      setCodeCore("N");
    } else if (fixture) {
      setCodeCore("C");
    }
  }, [fixture, core, diameter]);

  useEffect(() => {
    setValue(prefix + codeDiameter + codeLength + codeCore);
  }, [prefix, codeDiameter, codeLength, codeCore, setValue]);

  // LOGS
  // console.log("fixture", fixture);
  // console.log("core", core);
  // console.log("diameter", diameter);

  //RENDER
  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Input
        type="text"
        bg={white}
        value={prefix + codeDiameter + codeLength + codeCore}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        size={isSmall ? "md" : "lg"}
        fontSize={isSmall ? "sm" : "0.938rem"}
        fontWeight="500"
        color={textPrimary}
        border={`1px solid ${newBorder}`}
        borderRadius="0.75rem"
        boxShadow="none !important"
        _focus={{
          border:
            isError && isTouched
              ? `1px solid ${errorRed}`
              : `1px solid ${primary}`,
          boxShadow: "none",
        }}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
};

export default FormControlRefCode;
