import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Stack,
  Text,
  Heading,
  Button,
  Image,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  white,
  textSecondary,
  primary,
  newBorder,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import StatusButton from "../../../../components/buttons/StatusButton";
import noServices from "../../../../assets/images/empty-box.png";
import { shade } from "polished";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { FaInfo } from "react-icons/fa6";
import { TbSchool, TbUser } from "react-icons/tb";
import moment from "moment";

const UserEventsMini = ({ userInfo, courses }) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [adjustedCourses, setAdjustedCourses] = useState([]);

  useEffect(() => {
    const userOffsetMinutes = new Date().getTimezoneOffset();

    const adjusted = courses.map((course) => {
      const adjustedStartDate = moment(course.course.startDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();
      const adjustedEndDate = moment(course.course.endDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();

      return { ...course, adjustedStartDate, adjustedEndDate };
    });

    setAdjustedCourses(adjusted);
  }, [courses]);

  // CUSTOM
  const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
    return (
      <Flex align="center">
        <Flex
          w="1.25rem"
          h="1.25rem"
          align="center"
          justify="center"
          bg={white}
          mr={2}
          borderRadius="50%"
          flexShrink="0"
        >
          <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
        </Flex>
        {text && (
          <Text
            fontSize="sm"
            fontWeight="500"
            textTransform={capitalize ? "capitalize" : "none"}
            opacity={0.6}
            mr={1}
            whiteSpace="nowrap"
          >
            {text}:
          </Text>
        )}

        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          maxW={maxW}
          isTruncated={maxW ? true : false}
        >
          {label}
        </Text>
      </Flex>
    );
  };

  // CUSTOM
  const isSameDay = (start, end) => {
    return dayjs(start).isSame(dayjs(end), "day");
  };

  // LOGS

  console.log("courses", courses);
  console.log("adjustedCourses", adjustedCourses);

  return (
    <Flex
      w={["100%", "100%", "initial"]}
      overflow={["hidden", "hidden", "initial"]}
    >
      <Stack gap="0.75rem" w="100%" m="0rem 0">
        {adjustedCourses &&
        adjustedCourses !== undefined &&
        adjustedCourses.length > 0 ? (
          adjustedCourses.map((course) => {
            let adjustedStartDate = "";
            let adjustedEndDate = "";

            // console.log("adjustedStartDate", adjustedStartDate);
            // console.log("adjustedEndDate", adjustedEndDate);

            return (
              <Flex
                key={course._id}
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Flex
                  bg={white}
                  // bg="green"
                  w="100%"
                  p={["1rem 1.5rem", "1rem 1.5rem", "1rem 2rem"]}
                  h={"90px"}
                  borderRadius="1rem"
                  align="center"
                  justify="space-between"
                  borderBottom={`0px solid ${newBorder}`}
                  transition="all 0.2s"
                  onClick={() => {
                    navigate(`/account/events/${course._id}`);
                  }}
                  _hover={{
                    cursor: "pointer",
                    shadow: "sm",
                    transform: "scale(1.02)",
                  }}
                >
                  <Flex align="center">
                    <Stack
                      gap={["0.25rem", "0.25rem", "0.25rem"]}
                      mr={["1rem", "1rem", "1rem"]}
                      w="90px"
                    >
                      <Heading
                        fontSize={["xs", "xs", "sm"]}
                        fontWeight="600"
                        textTransform="capitalize"
                        color={textPrimary}
                      >
                        {isSameDay(
                          course.adjustedStartDate,
                          course.adjustedEndDate
                        )
                          ? dayjs(course.adjustedStartDate).format("MMMM D")
                          : `${dayjs(course.adjustedStartDate).format(
                              "MMM D"
                            )} - ${dayjs(course.adjustedEndDate).format(
                              "MMM D"
                            )}`}
                      </Heading>

                      <Text
                        fontSize="sm"
                        color={textSecondary}
                        fontWeight="500"
                      >
                        {dayjs(course.adjustedStartDate).format("YYYY")}
                      </Text>
                    </Stack>

                    <Flex
                      w="48px"
                      h="48px"
                      borderRadius="1rem"
                      overflow="hidden"
                      mr="1rem"
                      bg={backgroundLight}
                      flexShrink="0"
                      display={["none", "none", "flex"]}
                    >
                      <Image
                        w="100%"
                        h="100%"
                        objectFit="cover"
                        src={
                          course &&
                          course.course &&
                          course.course.image &&
                          course.course.image
                        }
                        alt={
                          course &&
                          course.course &&
                          course.course.title &&
                          course.course.title
                        }
                      />
                    </Flex>

                    <Flex gap="0.25rem" direction="column">
                      {userInfo && userInfo.clientType === "individual" ? (
                        <Heading
                          fontSize="sm"
                          fontWeight="600"
                          maxW={["100px", "100px", "260px"]}
                          isTruncated
                        >
                          {course &&
                            course.course &&
                            course.course.title &&
                            course.course.title}
                        </Heading>
                      ) : (
                        <Heading
                          fontSize="sm"
                          fontWeight="600"
                          maxW={["100px", "100px", "260px"]}
                          isTruncated
                        >
                          {course.attendeeFirstName +
                            " " +
                            course.attendeeLastName}
                        </Heading>
                      )}

                      {userInfo && userInfo.clientType === "individual" ? (
                        <Text
                          color={textSecondary}
                          fontSize={["xs", "xs", "sm"]}
                          fontWeight="500"
                          maxW={["100px", "100px", "260px"]}
                          isTruncated
                        >
                          {isMobile ? "" : t("common.attendee") + ": "}
                          <Text
                            as="span"
                            color={primary}
                            fontWeight="500"
                            fontSize={["xs", "xs", "sm"]}
                          >
                            aa
                            {course.attendeeFirstName +
                              " " +
                              course.attendeeLastName}
                          </Text>
                        </Text>
                      ) : (
                        <Text
                          color={textSecondary}
                          fontSize={["xs", "xs", "sm"]}
                          fontWeight="500"
                          maxW={["100px", "100px", "200px"]}
                          isTruncated
                        >
                          {isMobile ? "" : t("course.course") + ": "}
                          <Text
                            as="span"
                            color={primary}
                            fontWeight="500"
                            fontSize={["xs", "xs", "sm"]}
                          >
                            {course &&
                              course.course &&
                              course.course.title &&
                              course.course.title}
                          </Text>
                        </Text>
                      )}
                    </Flex>
                  </Flex>

                  <Flex align="center">
                    <Stack
                      gap="0.25rem"
                      mr="3rem"
                      display={["none", "none", "flex"]}
                    >
                      <Text
                        color={textSecondary}
                        fontSize="xs"
                        fontWeight="500"
                      >
                        {t("common.priceAttendee")}:
                      </Text>

                      <Text
                        color={textSecondary}
                        fontSize="sm"
                        fontWeight="500"
                      >
                        {course && course.price && course.price !== 0
                          ? course.price + " €"
                          : t("common.free")}
                      </Text>
                    </Stack>
                    {/* {course.status} */}

                    <Flex gap="1rem" align="center">
                      <Tooltip
                        p="1rem 1rem"
                        label={
                          <Flex direction="column" gap="0.5rem">
                            <TooltipEntry
                              label={course.course.title}
                              icon={TbSchool}
                              capitalize
                            />
                            <TooltipEntry
                              label={
                                course.attendeeFirstName +
                                " " +
                                course.attendeeLastName
                              }
                              icon={TbUser}
                              capitalize
                            />

                            <Flex
                              w="100%"
                              h="1px"
                              bg={textSecondary}
                              m="0.25rem 0"
                            />

                            <TooltipEntry
                              label={t(`status.${course.status}`)}
                              icon={TbUser}
                              maxW="160px"
                              text={t("clientAccount.attendeeStatus")}
                            />

                            <TooltipEntry
                              label={dayjs(course.updatedAt).format(
                                "YYYY-MM-DD, HH:mm"
                              )}
                              icon={TbUser}
                              maxW="250px"
                              text={t("common.statusUpdatedAt")}
                            />

                            {course &&
                              course.cancelReason &&
                              course.cancelReason !== null && (
                                <TooltipEntry
                                  label={
                                    course &&
                                    course.cancelReason &&
                                    course.cancelReason
                                  }
                                  icon={TbUser}
                                  maxW="160px"
                                  text={t("implantFailureReports.cancelReason")}
                                />
                              )}
                          </Flex>
                        }
                        borderRadius="1rem"
                      >
                        <Button
                          h="2.5rem"
                          w="2.5rem"
                          bg={backgroundLight}
                          border={`1px solid ${newBorder}`}
                          borderRadius="0.875rem"
                          display={["none", "none", "flex"]}
                        >
                          <Icon
                            as={FaInfo}
                            fontSize="0.75rem"
                            color={textSecondary}
                          />
                        </Button>
                      </Tooltip>
                      <StatusButton
                        isUnclickable
                        currentStatus={course.status}
                        options="attendees"
                      />
                    </Flex>

                    {/* {!isMobile && (
                    <MoreButton
                      id={
                        course &&
                        course.course &&
                        course.course._id &&
                        course.course._id
                      }
                      // deleteHandler={removeCourseHandler}
                      // deleteLabel={t("course.removeAttendee")}
                      viewId={
                        course &&
                        course.course &&
                        course.course._id &&
                        course.course._id
                      }
                      viewLabel={t("button.viewCourse")}
                      viewHandler={(e) => {
                        navigate(`/events/${course.course._id}`);
                      }}
                    />
                  )} */}

                    {/* <Button
                    bg={primary}
                    color={white}
                    size="lg"
                    fontSize="sm"
                    borderRadius="10rem"
                    isDisabled
                  >
                    {t("publicCourses.view")}
                  </Button> */}
                  </Flex>
                </Flex>
              </Flex>
            );
          })
        ) : (
          <Flex
            w="100%"
            align="center"
            justify="center"
            p="0rem 0"
            bg={white}
            borderRadius="1rem"
            direction="column"
            border={`0px solid ${newBorder}`}
          >
            <Flex justify="center" align="center" direction="column" p="3rem 0">
              <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                m="1rem auto 1rem"
                maxW="300px"
                textAlign="center"
              >
                {t("clientAccount.noEventsText")}
              </Text>
              <Button
                bg={primary}
                color={white}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                onClick={() => {
                  navigate("/events");
                }}
                _hover={{
                  bg: shade(0.3, primary),
                }}
              >
                {t("clientAccount.noEventsButton")}
              </Button>
            </Flex>
          </Flex>
        )}
      </Stack>
    </Flex>
  );
};

export default UserEventsMini;
