import React from "react";
import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { FaCircleInfo } from "react-icons/fa6";
import { primary, white } from "../../../../utils/colors";
import { useTranslation } from "react-i18next";

const XlsxTooltip = () => {
  const { t } = useTranslation();

  // Column headers array
  const columnHeaders = [
    { label: t("record.index") },
    { label: t("record.failFormId") },
    { label: t("record.reportedDate") },
    { label: t("form.country") },
    { label: t("record.personInCharge") },
    { label: t("record.dealerEmail") },
    { label: t("record.clinicName") },
    { label: t("record.dentist") },
    { label: t("record.dentistEmail") },
    { label: t("implantForm.tooth") },
    { label: t("implantForm.fixture") },
    { label: t("implantForm.refCode") },
    { label: t("implantForm.lotNumber") },
    { label: t("implantForm.surgeryDate") },
    { label: t("record.failDate") },
    { label: t("implantForm.notes") },
    { label: t("record.surgeryImage") },
    { label: t("record.failImage") },
    { label: t("record.newRefCode") },
  ];

  return (
    <Tooltip
      p="0.5rem 1rem"
      borderRadius="1rem"
      //   placement="top"
      fontSize="xs"
      label={
        <Flex direction="column" gap="0rem">
          {columnHeaders.map((header, index) => (
            <Text key={index} fontSize="xs" color={white} fontWeight="500">
              {header.label}
            </Text>
          ))}
        </Flex>
      }
    >
      <Box h="20px" pr="0.5rem">
        <Icon as={FaCircleInfo} fontSize="1rem" color={primary} />
      </Box>
    </Tooltip>
  );
};

export default XlsxTooltip;
