import React from "react";
import {
  Button,
  Flex,
  Text,
  Stack,
  Icon,
  Tooltip,
  Box,
  Spinner,
} from "@chakra-ui/react";
import {
  backgroundLight,
  greenVivid,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TbFile,
  TbFileTypePdf,
  TbFileTypeZip,
  TbFileTypeXls,
  TbFileTypePng,
  TbFileTypeJpg,
  TbFileInfo,
} from "react-icons/tb";
import { FaCircleInfo } from "react-icons/fa6";
import XlsxTooltip from "./XlsxTooltip";

const SendRecordModalMain = ({
  loading,
  handleGenerateFiles,
  zipFilePath,
  generatedFiles,
  generateReportFilesSuccess,
  handleDownloadZip,
}) => {
  const { t } = useTranslation();

  // FILE ICONS
  const fileTypes = {
    pdf: { icon: TbFileTypePdf, color: "#FD4949" },
    zip: { icon: TbFileTypeZip, color: "yellow.400" },
    xlsx: { icon: TbFileTypeXls, color: "#02AC51" },
    jpg: { icon: TbFileTypeJpg, color: "#9749FD" },
    png: { icon: TbFileTypePng, color: "purple.400" },
  };

  // GET ICON
  const getFileTypeDetails = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    return fileTypes[extension] || { icon: TbFile, color: "gray.400" }; // Default icon and color
  };

  // EXTRACT NAME PREFIX
  const extractFileName = (path) => {
    return path.split("/").pop();
  };

  // SORTED XLSX

  // Sort the generatedFiles array: move .xlsx files to the top
  const sortedFiles = [...(generatedFiles || [])].sort((a, b) => {
    const aExt = a.split(".").pop().toLowerCase();
    const bExt = b.split(".").pop().toLowerCase();

    if (aExt === "xlsx" && bExt !== "xlsx") return -1; // .xlsx comes first
    if (aExt !== "xlsx" && bExt === "xlsx") return 1; // others after .xlsx
    return 0; // preserve relative order for non-.xlsx files
  });

  // CUSTOM XLXS TOOLTIP
  const xlsxTooltip = () => {
    return <XlsxTooltip />;
  };

  // LOG
  console.log("generatedFiles", generatedFiles);
  console.log("zipFilePath", zipFilePath);

  return (
    <>
      <Flex direction="column" align="center" justify="center">
        {!generateReportFilesSuccess && (
          <Flex
            w="100%"
            align="center"
            justify="center"
            direction="column"
            m="1.5rem 0 0"
          >
            <Text fontSize="lg" fontWeight="600" color={textPrimary}>
              {t("record.attachments")}
            </Text>
          </Flex>
        )}

        {generatedFiles && zipFilePath ? (
          <Flex direction="column" w="100%" mt="0rem">
            <Flex
              w="100%"
              align="center"
              justify="center"
              direction="column"
              m="1.5rem 0 0.5rem"
            >
              <Text fontSize="lg" fontWeight="600" color={textPrimary}>
                {t("record.attachments")}
              </Text>
            </Flex>

            <Flex
              w="100%"
              p="1rem 1rem"
              borderRadius="1rem"
              bg={backgroundLight}
              m="0 0 0.5rem"
            >
              <Flex
                w="100%"
                align="center"
                justify="space-between"
                p="0.5rem"
                bg={white}
                borderRadius="1rem"
              >
                <Flex align="center">
                  <Flex
                    align="center"
                    justify="center"
                    w="2rem"
                    h="2rem"
                    bg={greenVivid}
                    borderRadius="50%"
                    color={textPrimary}
                    mr="0.5rem"
                  >
                    <Icon as={TbFileTypeZip} fontSize="1rem" />
                  </Flex>
                  <Text
                    fontSize="xs"
                    color={textPrimary}
                    fontWeight="500"
                    isTruncated
                  >
                    {extractFileName(zipFilePath)}
                  </Text>
                </Flex>
                <Flex>
                  <Flex align="center" justify="flex-end">
                    {/* <Button
                      bg={primary}
                      color={white}
                      borderRadius="10rem"
                      size="sm"
                      fontSize="xs"
                      _hover={{ bg: textPrimary }}
                      onClick={handleDownloadZip}
                    >
                      {t("koreport.downloadZip")}
                    </Button> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              gap={2}
              bg={backgroundLight}
              borderRadius="1rem"
              p="1rem"
              maxH="200px"
              overflowY="auto"
              className="customScrollbar"
            >
              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                m="0 auto 0rem"
                textAlign="center"
                maxW="80%"
              >
                {/* <Text as="span" color={textPrimary}>
                  {zipFilePath.split("/").pop()}{" "}
                </Text> */}
                {t("record.zipContains")}
                {/* {t("record.zipContains", { count: sortedFiles.length })} */}
              </Text>
              {sortedFiles?.map((file, index) => {
                const { icon, color } = getFileTypeDetails(file);

                // console.log("file", file);

                // Extract file name after the last "/"
                const fileName = file.split("/").pop();

                // if file is .xlsx
                const isXlsx = file.split(".").pop().toLowerCase() === "xlsx";

                return (
                  <Flex
                    key={index}
                    w="100%"
                    align="center"
                    justify="space-between"
                    p="0.5rem"
                    bg={white}
                    borderRadius="1rem"
                  >
                    <Flex align="center">
                      <Flex
                        align="center"
                        justify="center"
                        w="2rem"
                        h="2rem"
                        bg={color}
                        borderRadius="50%"
                        color={white}
                        mr="0.5rem"
                      >
                        <Icon as={icon} fontSize="1rem" />
                      </Flex>
                      <Text
                        fontSize="xs"
                        color={textPrimary}
                        fontWeight="500"
                        isTruncated
                      >
                        {fileName}
                      </Text>
                    </Flex>
                    <Flex align="center" justify="flex-end">
                      {isXlsx && xlsxTooltip()}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex
              align="center"
              justify="center"
              bg={backgroundLight}
              borderRadius="1rem"
              p="3rem 1rem"
              mt="1rem"
              w="100%"
              direction="column"
            >
              {!loading ? (
                <>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textSecondary}
                    m="0 0 1rem"
                  >
                    {t("record.sendRecordGenerateFilesDescription")}
                  </Text>
                  <Button
                    onClick={handleGenerateFiles}
                    bg={primary}
                    color={white}
                    size="lg"
                    fontSize="sm"
                    borderRadius="10rem"
                    _hover={{ bg: textPrimary }}
                  >
                    {t("record.generateFiles")}
                  </Button>
                </>
              ) : (
                <Flex align="center" justify="center" direction="column">
                  <Spinner size="md" color={primary} />
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={primary}
                    m="1rem 0 0"
                  >
                    {t("record.generatingFiles")}
                  </Text>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default SendRecordModalMain;
