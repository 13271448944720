import React from "react";
import { Routes, Route } from "react-router-dom";
import Speakers from "../pages/admin/speakers/list/Speakers";
import SpeakerSingle from "../pages/admin/speakers/single/SpeakerSingle";
import Courses from "../pages/admin/courses/list/Courses";
import CourseSingle from "../pages/admin/courses/single/CourseSingle";
import Attendees from "../pages/admin/attendees/list/Attendees";
import AttendeeSingle from "../pages/admin/attendees/single/AttendeeSingle";
import Login from "../pages/general/Login";
import Clients from "../pages/admin/clients/list/Clients";
import ClientSingle from "../pages/admin/clients/single/ClientSingle";
import Employees from "../pages/admin/employees/list/Employees";
import NotFound from "../pages/general/NotFound";
import StockProduct from "../pages/admin/products/single/StockProduct";
import Products from "../pages/admin/products/list/Products";
import ProfessionList from "../pages/admin/professions/list/ProfessionList";
import ProfessionGroupList from "../pages/admin/professionGroups/list/ProfessionGroupList";
import ImplantFailureReportList from "../pages/admin/reports/list/ImplantFailureReportList";
import SingleImplantFailureReport from "../pages/admin/reports/single/SingleImplantFailureReport";
import CoursesPublic from "../pages/public/courses/CoursesPublic";
import Landing from "../pages/landing/Landing";
import CourseSinglePublic from "../pages/public/courses/single/CourseSinglePublic";
import Register from "../pages/general/Register";
import UserAccount from "../pages/client/account/UserAccount";
import ImplantFailForm from "../pages/client/implant-fail-form/ImplantFailForm/ImplantFailForm";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import Testas from "../pages/admin/test/Testas";
import VerifyRegistration from "../pages/general/VerifyRegistration";
import VerifyPublicRegisterToken from "../pages/public/courses/VerifyPublicRegisterToken";
import ResetPassword from "../pages/general/ResetPassword";
import Offers from "../pages/public/custom/offers/Offers";
import Records from "../pages/admin/records/Records";
import RecordSingle from "../pages/admin/records/single/RecordSingle";
import PublicHqRecordSingle from "../pages/admin/records/single/PublicHqRecordSingle";
import SingleImplantFailureReportKorea from "../pages/admin/reports/single/SingleImplantFailureReportKorea";
import SingleImplantFailureReportKoreaHq from "../pages/admin/reports/single/SingleImplantFailureReportKoreaHq";

const AdminRoutes = () => {
  return (
    <Routes>
      {/* General Routes */}
      <Route path="/" element={<Landing />} />

      <Route path="/hq/reports/:id" element={<PublicHqRecordSingle />} />
      <Route
        path="/hq/reports/:id/:reportId"
        element={<SingleImplantFailureReportKoreaHq />}
      />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify-token" element={<VerifyRegistration />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/verify-event-register"
        element={<VerifyPublicRegisterToken />}
      />

      <Route path="/implant-fail-form" element={<ImplantFailForm />} />

      <Route path="/account" element={<UserAccount />} />

      {/* Admin Routes */}

      <Route path="/events" element={<CoursesPublic />} />
      <Route path="/events/:id" element={<CourseSinglePublic />} />
      <Route path="/admin/test" element={<Testas />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/academy/speakers" element={<Speakers />} />
      <Route path="/admin/academy/speakers/:id" element={<SpeakerSingle />} />

      <Route path="/admin/academy/courses" element={<Courses />} />
      <Route path="/admin/academy/courses/:id" element={<CourseSingle />} />

      <Route path="/admin/academy/attendees" element={<Attendees />} />
      <Route path="/admin/academy/attendees/:id" element={<AttendeeSingle />} />

      <Route path="/admin/clients" element={<Clients />} />
      <Route path="/admin/clients/:id" element={<ClientSingle />} />
      <Route path="/admin/employees" element={<Employees />} />

      <Route path="/admin/stock/products" element={<Products />} />
      <Route path="/admin/stock/products/:id" element={<StockProduct />} />

      <Route path="/admin/stock/implants" element={<Products />} />
      <Route path="/admin/stock/components" element={<Products />} />

      <Route path="admin/settings/professions" element={<ProfessionList />} />
      <Route
        path="admin/settings/profession-groups"
        element={<ProfessionGroupList />}
      />

      <Route
        path="admin/reports/implant-failure-reports"
        element={<ImplantFailureReportList />}
      />
      <Route
        path="admin/reports/implant-failure-reports/:id"
        element={<SingleImplantFailureReport />}
      />

      <Route path="admin/reports/records" element={<Records />} />
      <Route path="admin/reports/records/:id" element={<RecordSingle />} />
      <Route
        path="admin/reports/records/:recordId/implant-failure-report/:id"
        element={<SingleImplantFailureReportKorea />}
      />

      <Route path="/offers" element={<Offers />} />

      {/* Catch-all route for 404 page */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRoutes;
