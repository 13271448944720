import React from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const SendRecordModalTop = ({ recordDetails, loading, isSuccess }) => {
  const { t } = useTranslation();

  return (
    <>
      {!isSuccess && (
        <Flex
          w="100%"
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          m="1.5rem 0 0.5rem"
        >
          <Text
            fontSize="1.25rem"
            fontWeight="600"
            color={textPrimary}
            lineHeight="1.5"
            // m="1rem auto 0"
            maxW="90%"
          >
            {t("record.sendRecordTitle")}
          </Text>
        </Flex>
      )}
      <Flex
        w="100%"
        maxW={isSuccess ? "80%" : "initial"}
        border={`1px solid ${newBorder}`}
        p="1.5rem 1rem"
        borderRadius="1.5rem"
        align="center"
        justify="space-around"
      >
        {!loading ? (
          <>
            <Flex direction="column">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                //   m="0.5rem 0"
              >
                {t("record.recordId")}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                //   m="0.5rem 0"
              >
                {recordDetails.customId}
              </Text>
            </Flex>

            <Flex direction="column">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                //   m="0.5rem 0"
              >
                {t("record.recordDates")}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                //   m="0.5rem 0"
              >
                {dayjs(recordDetails.startDate).utc().format("YYYY, MMMM D")} -{" "}
                {dayjs(recordDetails.endDate).utc().format("YYYY, MMMM D")}
              </Text>
            </Flex>

            <Flex direction="column">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                //   m="0.5rem 0"
              >
                {t("record.reportCount")}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                //   m="0.5rem 0"
              >
                {recordDetails?.reports?.length || 0}
              </Text>
            </Flex>
          </>
        ) : (
          <Box p="0.5rem 0">
            <Spinner size="sm" color={primary} />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default SendRecordModalTop;
