import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Icon,
  Button,
  useToast,
  HStack,
  Image,
} from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import {
  primary,
  newBorder,
  textPrimary,
  white,
} from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import { useTranslation } from "react-i18next";
import ReportInfoKorea from "./partials/ReportInfoKorea";
import logonew2 from "../../../../assets/icons/logonew2.svg";
import { downloadFileAction } from "../../../../actions/recordActions";
import { TbFileTypePdf } from "react-icons/tb";
import LanguageButton from "../../../../components/buttons/LanguageButton";
import dayjs from "dayjs";

const SingleImplantFailureReportKoreaHq = ({ report, loading, close }) => {
  // GENERAL
  const id = useParams().id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const { i18n } = useTranslation();

  // STATE
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");

  // REDUX
  const dispatch = useDispatch();

  // const location = useLocation();
  // const report = location.state?.report;

  const downloadFileHandler = (filename) => {
    console.log("Filename:", filename);
    if (!filename) {
      toast({
        title: t("koreport.error"),
        description: t("koreport.fileNotFound"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    console.log("Filename:", filename);

    // Remove the `/uploads/` prefix if it exists
    const correctedFilename = filename.startsWith("/uploads/")
      ? filename.replace(/^\/uploads\//, "")
      : filename;

    console.log("Original filename:", filename);
    console.log("Corrected filename:", correctedFilename);

    dispatch(downloadFileAction(correctedFilename));
  };

  const [currentLanguage, setCurrentLanguage] = useState("ko");

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  if (!report) {
    return (
      <Flex justify="center" align="center" w="100%" p="8rem 2rem">
        <SinglePageTopBar
          backFunction={() => navigate(-1)}
          title={t("implantFailureReports.singleTitle")}
          // type={report.trackingNumber}
          // loading={loading}
        ></SinglePageTopBar>
        <Text>No report data found.</Text>
      </Flex>
    );
  }

  // LOGS
  // console.log("report", report);

  // RENDER
  return (
    <>
      <Box position="absolute" top="2rem" left="2rem" zIndex={200}>
        <Link to="https://www.mymegagen.com" target="_blank">
          <HStack gap={0} alignItems="flex-end">
            <Image src={logonew2} />
          </HStack>
        </Link>
      </Box>

      <Box
        position={["relative ", "absolute"]}
        zIndex={900}
        display={["none", "block"]}
        right="3%"
        top="1rem"
      >
        <LanguageButton
          currentLanguage={currentLanguage}
          languages={["en", "lt", "ko"]}
          onChangeLanguage={handleChangeLanguage}
          onMenuClick={handleMenuClick}
          isWhite
        />
      </Box>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box
              w="100%"
              maxW="1000px"
              m="0 auto"
              border={`1px solid ${newBorder}`}
              borderRadius="1.5rem"
            >
              <SinglePageTopBar
                backFunction={close}
                title={t("implantFailureReports.singleTitle")}
                type={report.trackingNumber}
                loading={loading}
              >
                <Button
                  size="md"
                  fontSize="sm"
                  bg={textPrimary}
                  color={white}
                  borderRadius="0.875rem"
                  mr="1rem"
                  _hover={{
                    bg: primary,
                  }}
                  onClick={() =>
                    downloadFileHandler(`${report.trackingNumber}.pdf`)
                  }
                >
                  <Icon as={TbFileTypePdf} fontSize="1rem" mr={2} />
                  {t("newTranslation.downloadPdf")}
                </Button>

                <StatusButton
                  isUnclickable
                  currentStatus={report.status}
                  // onUpdateStatus={handleUpdateStatus}
                  options="report"
                />

                {/* <MoreButton
                  id={id}
                  isBig
                  // deleteHandler={deleteCourseHandler}
                  deleteLabel={t("implantFailureReports.deleteReport")}
                  editHandler={(e) => {
                    e.stopPropagation();
                    // openEditSidePanel(courseId);
                  }}
                /> */}
              </SinglePageTopBar>
              {!loading ? (
                <>
                  <Flex direction="column" w="100%">
                    <Flex
                      gap={0}
                      p="2.5rem 2.5rem 1.5rem 2.5rem"
                      alignItems="flex-start"
                      justifyContent="center"
                      w="100%"
                    >
                      <Flex direction="column" w="100%">
                        <ReportInfoKorea data={report} />
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex justify="center" align="center" w="100%" p="8rem 2rem">
                  <Spinner size="xl" color={primary} />
                </Flex>
              )}
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default SingleImplantFailureReportKoreaHq;
