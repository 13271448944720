import {
  CREATE_RECORD_REQUEST,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_FAIL,
  CREATE_RECORD_RESET,
  GET_RECORDS_REQUEST,
  GET_RECORDS_SUCCESS,
  GET_RECORDS_FAIL,
  GET_RECORDS_RESET,
  GET_RECORD_DETAILS_REQUEST,
  GET_RECORD_DETAILS_SUCCESS,
  GET_RECORD_DETAILS_FAIL,
  GET_RECORD_DETAILS_RESET,
  DELETE_RECORD_REQUEST,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  DELETE_RECORD_RESET,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_RESET,
  GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST,
  GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS,
  GET_PUBLIC_RECORD_FULL_DETAILS_FAIL,
  GET_PUBLIC_RECORD_FULL_DETAILS_RESET,
  GENERATE_REPORT_FILES_REQUEST,
  GENERATE_REPORT_FILES_SUCCESS,
  GENERATE_REPORT_FILES_FAIL,
  GENERATE_REPORT_FILES_RESET,
  SEND_RECORD_REQUEST,
  SEND_RECORD_SUCCESS,
  SEND_RECORD_FAIL,
  SEND_RECORD_RESET,
  GET_RECORD_REPORTS_REQUEST,
  GET_RECORD_REPORTS_SUCCESS,
  GET_RECORD_REPORTS_FAIL,
  GET_RECORD_REPORTS_RESET,
  DOWNLOAD_RECORD_FILE_REQUEST,
  DOWNLOAD_RECORD_FILE_SUCCESS,
  DOWNLOAD_RECORD_FILE_FAIL,
  DOWNLOAD_RECORD_FILE_RESET,
  GET_RECORD_CUSTOMID_REQUEST,
  GET_RECORD_CUSTOMID_SUCCESS,
  GET_RECORD_CUSTOMID_FAIL,
  GET_RECORD_CUSTOMID_RESET,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE_RESET,
} from "../constants/recordConstants";

export const createRecordReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_RECORD_REQUEST:
      return { loading: true };
    case CREATE_RECORD_SUCCESS:
      return { loading: false, success: true, record: action.payload };
    case CREATE_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_RECORD_RESET:
      return {};
    default:
      return state;
  }
};

export const getRecordsReducer = (
  state = { records: [], page: 1, pages: 1, totalCount: 0 },
  action
) => {
  switch (action.type) {
    case GET_RECORDS_REQUEST:
      return { loading: true, records: [] };
    case GET_RECORDS_SUCCESS:
      return {
        loading: false,
        records: action.payload.records,
        page: action.payload.page,
        pages: action.payload.pages,
        totalCount: action.payload.totalCount,
      };
    case GET_RECORDS_FAIL:
      return { loading: false, error: action.payload };
    case GET_RECORDS_RESET:
      return { records: [], page: 1, pages: 1, totalCount: 0 };
    default:
      return state;
  }
};

export const getRecordDetailsReducer = (
  state = { recordDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_RECORD_DETAILS_REQUEST:
      return { loading: true, recordDetails: {} };
    case GET_RECORD_DETAILS_SUCCESS:
      return { loading: false, recordDetails: action.payload };
    case GET_RECORD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case GET_RECORD_DETAILS_RESET:
      return { recordDetails: {} };
    default:
      return state;
  }
};

export const deleteRecordReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_RECORD_REQUEST:
      return { loading: true };
    case DELETE_RECORD_SUCCESS:
      return { loading: false, success: true };
    case DELETE_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_RECORD_RESET:
      return {};
    default:
      return state;
  }
};

export const getPublicRecordMinimalDetailsReducer = (
  state = { record: {} },
  action
) => {
  switch (action.type) {
    case GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST:
      return { loading: true, record: {} };
    case GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS:
      return { loading: false, record: action.payload };
    case GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case GET_PUBLIC_RECORD_MINIMAL_DETAILS_RESET:
      return { record: {} };
    default:
      return state;
  }
};

export const getPublicRecordFullDetailsReducer = (
  state = { recordFull: null, countsByFixture: [], countsByNewFixture: [] },
  action
) => {
  switch (action.type) {
    case GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST:
      return {
        loading: true,
        recordFull: null,
        countsByFixture: [],
        countsByNewFixture: [],
      };

    case GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS:
      return {
        loading: false,
        recordFull: action.payload, // Complete record details
        countsByFixture: action.payload.countsByFixture || [], // Fixture counts
        countsByNewFixture: action.payload.countsByNewFixture || [], // New fixture counts
      };

    case GET_PUBLIC_RECORD_FULL_DETAILS_FAIL:
      return { loading: false, error: action.payload, recordFull: null };

    case GET_PUBLIC_RECORD_FULL_DETAILS_RESET:
      return { recordFull: null, countsByFixture: [], countsByNewFixture: [] };

    default:
      return state;
  }
};

export const generateReportFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_REPORT_FILES_REQUEST:
      return { loading: true }; // Start loading state

    case GENERATE_REPORT_FILES_SUCCESS:
      return {
        loading: false, // Stop loading
        success: true, // Mark as successful
        zipFilePath: action.payload.zipFilePath, // Save the zip file path
        generatedFiles: action.payload.generatedFiles, // Save the list of generated files
      };

    case GENERATE_REPORT_FILES_FAIL:
      return {
        loading: false, // Stop loading
        error: action.payload, // Capture error message
      };

    case GENERATE_REPORT_FILES_RESET:
      return {}; // Reset state

    default:
      return state;
  }
};

export const sendRecordReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_RECORD_REQUEST:
      return { loading: true };
    case SEND_RECORD_SUCCESS:
      return { loading: false, success: true };
    case SEND_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case SEND_RECORD_RESET:
      return {
        loading: false,
        success: false,
        error: "",
      };
    default:
      return state;
  }
};

export const getRecordReportsReducer = (state = { reports: [] }, action) => {
  switch (action.type) {
    case GET_RECORD_REPORTS_REQUEST:
      return { loading: true, reports: [] };
    case GET_RECORD_REPORTS_SUCCESS:
      return { loading: false, reports: action.payload };
    case GET_RECORD_REPORTS_FAIL:
      return { loading: false, error: action.payload };
    case GET_RECORD_REPORTS_RESET:
      return { reports: [] };
    default:
      return state;
  }
};

export const downloadRecordFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_RECORD_FILE_REQUEST:
      return { loading: true };
    case DOWNLOAD_RECORD_FILE_SUCCESS:
      return { loading: false, success: true };
    case DOWNLOAD_RECORD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_RECORD_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const getRecordCustomIdReducer = (
  state = { recordCustomId: "" },
  action
) => {
  switch (action.type) {
    case GET_RECORD_CUSTOMID_REQUEST:
      return { loading: true, recordCustomId: "" };
    case GET_RECORD_CUSTOMID_SUCCESS:
      return { loading: false, recordCustomId: action.payload.customId };
    case GET_RECORD_CUSTOMID_FAIL:
      return { loading: false, error: action.payload, recordCustomId: "" };
    case GET_RECORD_CUSTOMID_RESET:
      return { recordCustomId: "" };
    default:
      return state;
  }
};

export const downloadFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE_REQUEST:
      return { loading: true };
    case DOWNLOAD_FILE_SUCCESS:
      return { loading: false, success: true };
    case DOWNLOAD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_FILE_RESET:
      return {};
    default:
      return state;
  }
};
