import React from "react";
import {
  ModalHeader,
  Flex,
  Text,
  IconButton,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import {
  backgroundLight,
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import { FaXmark } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { TbFileDescription } from "react-icons/tb";

const SendRecordModalHeader = ({ onClose, recordDetails, loading }) => {
  const { t } = useTranslation();

  return (
    <ModalHeader p="1rem 1.5rem" borderBottom={`1px solid ${newBorder}`}>
      <Flex w="100%" align="center" justify="space-between">
        <Flex align="center">
          <Flex
            align="center"
            justify="center"
            bg={primary}
            borderRadius="50%"
            w="1.5rem"
            h="1.5rem"
            mr="0.25rem"
          >
            <Icon as={TbFileDescription} fontSize="0.75rem" color={white} />
          </Flex>
          {!loading ? (
            <Text fontSize="1rem" fontWeight="600" color={textPrimary}>
              {t("record.record")} #{recordDetails.customId}
            </Text>
          ) : (
            <Spinner size="sm" color={primary} />
          )}
        </Flex>
        <IconButton
          size="sm"
          bg={backgroundLight}
          color={textSecondary}
          onClick={onClose}
          borderRadius="10rem"
          _hover={{ bg: primary, color: white }}
        >
          <Icon as={FaXmark} />
        </IconButton>
      </Flex>
    </ModalHeader>
  );
};

export default SendRecordModalHeader;
