import React, { useState, useMemo, useEffect, Component } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import { Box, Spinner, Flex, Text, FormControl, Icon } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getUserClientsSimpleList } from "../../actions/userActions";
import dayjs from "dayjs";
import StatusBadge from "../badges/StatusBadge";
import { tint } from "polished";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  buttonBlue,
  buttonBlueText,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaUser, FaSuitcase } from "react-icons/fa";

const height = 48;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    const dynamicHeight = Math.min(children.length, 4) * height;

    return (
      <List
        height={dynamicHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <Flex
            h={`${height}px`}
            style={style}
            borderBottom={`1px solid ${newBorder}`}
            _last={{
              borderBottom: "none",
            }}
          >
            {children[index]}
          </Flex>
        )}
      </List>
    );
  }
}

const FormControlClientSelect = ({
  onChange,
  isError,
  isRequired,
  isTouched,
  w,
  placeholder,
  client,
  autoFocus,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userGetClientsSimple = useSelector(
    (state) => state.userGetClientsSimple
  );

  const { loading, error, clients } = userGetClientsSimple;

  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu open/close

  // Fetch patient data on component mount
  useEffect(() => {
    if (!client) {
      dispatch(getUserClientsSimpleList());
    } else {
      setIsMenuOpen(false);
    }
  }, [dispatch, client]);

  // Update to handle closing the menu upon selection
  const handleOnChange = (selectedOption) => {
    onChange(selectedOption); // Assuming onChange prop is used to handle selection outside
    setIsMenuOpen(false); // Close the menu after selection
  };

  // const filteredOptions = useMemo(() => {
  //   // Normalize the input for case-insensitive comparison
  //   const normalizedInput = inputValue.trim().toLowerCase();

  //   console.log("normalizedInput:", normalizedInput);

  //   const filtered = clients
  //     .filter((patient) => {
  //       // Concatenate and normalize patient's full name for comparison
  //       const search =
  //         `${patient.firstNameNormalized} ${patient.lastNameNormalized} ${patient.companyName}`.toLowerCase();

  //       console.log("search:", search);

  //       // Check if the normalized full name includes the normalized input
  //       return !normalizedInput || search.includes(normalizedInput);
  //     })

  //     .map((patient) => ({
  //       value: patient._id,
  //       label:
  //         patient.firstName && patient.lastName
  //           ? `${patient.firstName} ${patient.lastName}`
  //           : "Loading...",
  //       firstName: patient.firstName,
  //       lastName: patient.lastName,
  //       firstNameNormalized: patient.firstNameNormalized, // Assuming these are pre-normalized fields for search optimization
  //       lastNameNormalized: patient.lastNameNormalized,
  //       companyName: patient.companyName,
  //       companyCode: patient.companyCode,
  //       vatCode: patient.vatCode,
  //       clientType: patient.clientType,
  //       accountType: patient.accountType,
  //       status: patient.status,
  //     }));

  //   console.log("filtered:", filtered);

  //   // Optionally limit the number of results here if needed
  //   return filtered.slice(Math.max(filtered.length - 50, 0));
  // }, [inputValue, clients]);

  const filteredOptions = useMemo(() => {
    const normalizedInput = inputValue.trim().toLowerCase();

    const filtered = clients
      .filter((client) => {
        // console.log("client:", client);
        // Create a search string from the client's name and company name
        const searchString = [
          client.firstNameNormalized,
          client.lastNameNormalized,
          client.companyName,
        ]
          .join(" ")
          .toLowerCase(); // Concatenate and lower case

        // Return true if the search string contains the normalized input
        return searchString.includes(normalizedInput);
      })
      .map((client) => ({
        value: client._id,
        label:
          client.firstName && client.lastName
            ? `${client.firstName} ${client.lastName}`
            : client.companyName || "Loading...",
        ...client,
      }));

    return filtered;
  }, [inputValue, clients]);

  // console.log("clients:", clients);
  // console.log("filteredOptions:", filteredOptions);

  // console.log("client:", client);

  // console.log("inputValue:", inputValue);
  // console.log("isPatientSelected:", isPatientSelected);

  // Custom rendering for options
  const formatOptionLabel = ({
    clientType,
    firstName,
    lastName,
    companyName,
    accountType,
    status,
  }) => (
    <Flex align="center" justify="space-between" w="100%">
      <Flex align="center">
        <Flex
          align="center"
          justify="center"
          w="2rem"
          h="2rem"
          borderRadius="10rem"
          bg={buttonBlue}
          mr={1.5}
        >
          <Icon
            as={clientType === "individual" ? FaUser : FaSuitcase}
            boxSize={2}
            color={buttonBlueText}
          />
        </Flex>
        {clientType === "individual" ? (
          <Flex align="center">
            <Text fontSize="sm" fontWeight="500">
              {firstName} {lastName}
            </Text>
          </Flex>
        ) : (
          <Text fontSize="sm" fontWeight="500">
            {companyName}
          </Text>
        )}
        <Text ml={2} fontSize="xs">
          {t(`implantForm.${accountType}`)}
        </Text>
      </Flex>

      <StatusBadge isSmall options="active" status={status} ml={2} />
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Box>
        <Spinner size="lg" color={primary} />
      </Box>
    );

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <Select
        // autoFocus={client ? false : true}
        autoFocus={autoFocus}
        menuIsOpen={isMenuOpen}
        value={client}
        components={{ MenuList }}
        options={filteredOptions}
        onInputChange={(newInputValue, { action }) => {
          if (action === "input-change") {
            setInputValue(newInputValue);
          }
        }}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        onChange={handleOnChange}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
        filterOption={() => true} // Since you're manually filtering options, always return true here
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textPrimary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          menu: (provided) => ({
            ...provided,
            position: "relative",
            zIndex: 9999,
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: `0 0 0 1px ${newBorder}`,
            border: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            borderRadius: "1rem",

            // maxHeight: "10rem",
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : textSecondary, // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: "white",
              cursor: "pointer",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("placeholder.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlClientSelect;
