import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import BarChart from "../../../../components/stats/BarChart";
import {
  buttonGreen,
  primary,
  textPrimary,
  textSecondary,
  greenVivid,
  buttonRedNew,
  buttonRed,
  buttonYellow,
} from "../../../../utils/colors";
import { getReportStatusCounts } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";
import BarChartFixture from "../../../../components/stats/BarChartFixture";

const FixtureCount = ({ loading, error, recordDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentRecord, setCurrentRecord] = useState(null);

  useEffect(() => {
    if (recordDetails) {
      setCurrentRecord(recordDetails);
    }
  }, [recordDetails]);

  // CUSTOM
  const statusOrder = [
    "mini",
    "anyone",
    "anyridge",
    "bluediamond",
    "st",
    "ezplus",
    "rescue",
  ];
  const statusColors = {
    Mini: buttonRedNew,
    Anyone: buttonRed,
    Anyridge: buttonGreen,
    Bluediamond: buttonYellow,
    St: buttonYellow,
    Ezplus: buttonYellow,
    Rescue: buttonYellow,
  };

  if (loading) {
    return (
      <Flex>
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!currentRecord || !currentRecord.countsByFixture) {
    return (
      <Flex>
        <Text>No data available.</Text>
      </Flex>
    );
  }

  console.log("currentRecord", currentRecord);
  console.log("recordDetails", recordDetails);

  return (
    <Flex direction="column" w="100%">
      <Flex p="0 0rem 0.5rem">
        <Text fontSize="1rem" fontWeight="600" color={textPrimary}>
          {t("record.failedImplants")}{" "}
          <Text as="span" fontSize="md" fontWeight="500" color={textSecondary}>
            (
            {currentRecord.countsByFixture.reduce(
              (total, item) => total + item.count,
              0
            )}
            )
          </Text>
        </Text>
      </Flex>
      <BarChartFixture
        data={currentRecord.countsByFixture}
        statusOrder={statusOrder}
        statusColors={statusColors}
        backgroundLight="gray.200"
        textSecondary="gray.500"
        white="white"
      />
    </Flex>
  );
};

export default FixtureCount;
