export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_STATUS_UPDATE_REQUEST = "USER_STATUS_UPDATE_REQUEST";
export const USER_STATUS_UPDATE_SUCCESS = "USER_STATUS_UPDATE_SUCCESS";
export const USER_STATUS_UPDATE_FAIL = "USER_STATUS_UPDATE_FAIL";
export const USER_STATUS_UPDATE_RESET = "USER_STATUS_UPDATE_RESET";

export const USER_REGISTER_VERIFICATION_REQUEST =
  "USER_REGISTER_VERIFICATION_REQUEST";
export const USER_REGISTER_VERIFICATION_SUCCESS =
  "USER_REGISTER_VERIFICATION_SUCCESS";
export const USER_REGISTER_VERIFICATION_FAIL =
  "USER_REGISTER_VERIFICATION_FAIL";
export const USER_REGISTER_VERIFICATION_RESET =
  "USER_REGISTER_VERIFICATION_RESET";

export const USER_VERIFY_EMAIL_REQUEST = "USER_VERIFY_EMAIL_REQUEST";
export const USER_VERIFY_EMAIL_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS";
export const USER_VERIFY_EMAIL_FAIL = "USER_VERIFY_EMAIL_FAIL";
export const USER_VERIFY_EMAIL_RESET = "USER_VERIFY_EMAIL_RESET";

export const USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST =
  "USER_GET_REGISTERED_COURSES_PUBLIC_REQUEST";
export const USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS =
  "USER_GET_REGISTERED_COURSES_PUBLIC_SUCCESS";
export const USER_GET_REGISTERED_COURSES_PUBLIC_FAIL =
  "USER_GET_REGISTERED_COURSES_PUBLIC_FAIL";
export const USER_GET_REGISTERED_COURSES_PUBLIC_RESET =
  "USER_GET_REGISTERED_COURSES_PUBLIC_RESET";

export const USER_CLIENT_GET_REPORTS_REQUEST =
  "USER_CLIENT_GET_REPORTS_REQUEST";
export const USER_CLIENT_GET_REPORTS_SUCCESS =
  "USER_CLIENT_GET_REPORTS_SUCCESS";
export const USER_CLIENT_GET_REPORTS_FAIL = "USER_CLIENT_GET_REPORTS_FAIL";
export const USER_CLIENT_GET_REPORTS_RESET = "USER_CLIENT_GET_REPORTS_RESET";

export const USER_GET_CLIENTS_REQUEST = "USER_GET_CLIENTS_REQUEST";
export const USER_GET_CLIENTS_SUCCESS = "USER_GET_CLIENTS_SUCCESS";
export const USER_GET_CLIENTS_FAIL = "USER_GET_CLIENTS_FAIL";
export const USER_GET_CLIENTS_RESET = "USER_GET_CLIENTS_RESET";

export const USER_GET_CLIENT_DETAILS_REQUEST =
  "USER_GET_CLIENT_DETAILS_REQUEST";
export const USER_GET_CLIENT_DETAILS_SUCCESS =
  "USER_GET_CLIENT_DETAILS_SUCCESS";
export const USER_GET_CLIENT_DETAILS_FAIL = "USER_GET_CLIENT_DETAILS_FAIL";
export const USER_GET_CLIENT_DETAILS_RESET = "USER_GET_CLIENT_DETAILS_RESET";

export const USER_GET_SINGLE_REPORT_REQUEST = "USER_GET_SINGLE_REPORT_REQUEST";
export const USER_GET_SINGLE_REPORT_SUCCESS = "USER_GET_SINGLE_REPORT_SUCCESS";
export const USER_GET_SINGLE_REPORT_FAIL = "USER_GET_SINGLE_REPORT_FAIL";
export const USER_GET_SINGLE_REPORT_RESET = "USER_GET_SINGLE_REPORT_RESET";

export const USER_GET_SINGLE_EVENT_REQUEST = "USER_GET_SINGLE_EVENT_REQUEST";
export const USER_GET_SINGLE_EVENT_SUCCESS = "USER_GET_SINGLE_EVENT_SUCCESS";
export const USER_GET_SINGLE_EVENT_FAIL = "USER_GET_SINGLE_EVENT_FAIL";
export const USER_GET_SINGLE_EVENT_RESET = "USER_GET_SINGLE_EVENT_RESET";

export const USER_UPDATE_DETAILS_REQUEST = "USER_UPDATE_DETAILS_REQUEST";
export const USER_UPDATE_DETAILS_SUCCESS = "USER_UPDATE_DETAILS_SUCCESS";
export const USER_UPDATE_DETAILS_FAIL = "USER_UPDATE_DETAILS_FAIL";
export const USER_UPDATE_DETAILS_RESET = "USER_UPDATE_DETAILS_RESET";

export const USER_CANCEL_REPORT_REQUEST = "USER_CANCEL_REPORT_REQUEST";
export const USER_CANCEL_REPORT_SUCCESS = "USER_CANCEL_REPORT_SUCCESS";
export const USER_CANCEL_REPORT_FAIL = "USER_CANCEL_REPORT_FAIL";
export const USER_CANCEL_REPORT_RESET = "USER_CANCEL_REPORT_RESET";

export const USER_GET_CLIENTS_SIMPLE_REQUEST =
  "USER_GET_CLIENTS_SIMPLE_REQUEST";
export const USER_GET_CLIENTS_SIMPLE_SUCCESS =
  "USER_GET_CLIENTS_SIMPLE_SUCCESS";
export const USER_GET_CLIENTS_SIMPLE_FAIL = "USER_GET_CLIENTS_SIMPLE_FAIL";
export const USER_GET_CLIENTS_SIMPLE_RESET = "USER_GET_CLIENTS_SIMPLE_RESET";

export const USER_CLIENT_UPDATE_EMPLOYEE_REQUEST =
  "USER_CLIENT_UPDATE_EMPLOYEE_REQUEST";
export const USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS =
  "USER_CLIENT_UPDATE_EMPLOYEE_SUCCESS";
export const USER_CLIENT_UPDATE_EMPLOYEE_FAIL =
  "USER_CLIENT_UPDATE_EMPLOYEE_FAIL";
export const USER_CLIENT_UPDATE_EMPLOYEE_RESET =
  "USER_CLIENT_UPDATE_EMPLOYEE_RESET";

export const USER_GET_CLIENT_EMPLOYEES_REQUEST =
  "USER_GET_CLIENT_EMPLOYEES_REQUEST";
export const USER_GET_CLIENT_EMPLOYEES_SUCCESS =
  "USER_GET_CLIENT_EMPLOYEES_SUCCESS";
export const USER_GET_CLIENT_EMPLOYEES_FAIL = "USER_GET_CLIENT_EMPLOYEES_FAIL";
export const USER_GET_CLIENT_EMPLOYEES_RESET =
  "USER_GET_CLIENT_EMPLOYEES_RESET";

export const USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST =
  "USER_GET_CLIENT_EMPLOYEES_SIMPLE_REQUEST";
export const USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS =
  "USER_GET_CLIENT_EMPLOYEES_SIMPLE_SUCCESS";
export const USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL =
  "USER_GET_CLIENT_EMPLOYEES_SIMPLE_FAIL";
export const USER_GET_CLIENT_EMPLOYEES_SIMPLE_RESET =
  "USER_GET_CLIENT_EMPLOYEES_SIMPLE_RESET";

export const USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST =
  "USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_REQUEST";

export const USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS =
  "USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_SUCCESS";

export const USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL =
  "USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_FAIL";

export const USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET =
  "USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET";

export const USER_VERIFY_EMAIL_TOKEN_REQUEST =
  "USER_VERIFY_EMAIL_TOKEN_REQUEST";
export const USER_VERIFY_EMAIL_TOKEN_SUCCESS =
  "USER_VERIFY_EMAIL_TOKEN_SUCCESS";
export const USER_VERIFY_EMAIL_TOKEN_FAIL = "USER_VERIFY_EMAIL_TOKEN_FAIL";
export const USER_VERIFY_EMAIL_TOKEN_RESET = "USER_VERIFY_EMAIL_TOKEN_RESET";

export const USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST =
  "USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_REQUEST";
export const USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS =
  "USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_SUCCESS";
export const USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL =
  "USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_FAIL";
export const USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET =
  "USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";
export const USER_FORGOT_PASSWORD_RESET = "USER_FORGOT_PASSWORD_RESET";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_RESET_PASSWORD_RESET = "USER_RESET_PASSWORD_RESET";

export const USER_LOGIN_MULTI_REQUEST = "USER_LOGIN_MULTI_REQUEST";
export const USER_LOGIN_MULTI_SUCCESS = "USER_LOGIN_MULTI_SUCCESS";
export const USER_LOGIN_MULTI_FAIL = "USER_LOGIN_MULTI_FAIL";
export const USER_LOGIN_MULTI_RESET = "USER_LOGIN_MULTI_RESET";
