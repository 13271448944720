import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  Avatar,
  HStack,
  Image,
  Button,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbMapPin,
  TbBuilding,
  TbUsers,
  TbMapPinStar,
  TbWallet,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
  primary,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import TooltipText from "../../../../../components/partials/TooltipText";
import { shade } from "polished";
import implant from "../../../../../assets/images/implant.png";
import { FaExchangeAlt } from "react-icons/fa";
import noServices from "../../../../../assets/images/empty-box.png";
import { saveAs } from "file-saver"; // Import file-saver for downloading files
import { useParams } from "react-router-dom";
import { downloadFileAction } from "../../../../../actions/recordActions";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const ReportInfoKorea = ({ data, recordCustomId }) => {
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const [workAddress, setWorkAddress] = useState("");

  const [link, setLink] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const downloadFile = useSelector((state) => state.downloadFile);
  const { loading, success } = downloadFile;

  // STATE

  // HANDLERS

  // const downloadFileHandler = (filename) => {

  //   dispatch(downloadFileAction(filename));
  // };

  const downloadFileHandler = (filename) => {
    if (!filename) {
      toast({
        title: t("koreport.error"),
        description: t("koreport.fileNotFound"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Remove the `/uploads/` prefix if it exists
    const correctedFilename = filename.startsWith("/uploads/")
      ? filename.replace(/^\/uploads\//, "")
      : filename;

    console.log("Original filename:", filename);
    console.log("Corrected filename:", correctedFilename);

    dispatch(downloadFileAction(correctedFilename));
  };

  // USE EFFECT
  useEffect(() => {
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);
    if (data && process.env.NODE_ENV === "development") {
      setLink(`http://localhost:3000/tmp/`);
    } else {
      setLink(`https://mymegagen.com/tmp/`);
    }
  }, [data]);

  // console.log("link", link);
  // console.log("data", data);
  // const { recordId, id } = useParams();

  // console.log("Record ID:", recordId);
  // console.log("Implant Failure Report ID:", id);

  // HANDLERS
  // const downloadFile = (filePath, fileName) => {
  //   if (!filePath) {
  //     toast({
  //       title: t("koreport.error"),
  //       description: t("koreport.fileNotFound"),
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //     return;
  //   }

  //   try {
  //     // Remove `/uploads/` from the file path for display or backend corrections
  //     const correctedPath = filePath.replace(/^\/uploads\//, "");
  //     const fullUrl = `${link}${correctedPath}`;

  //     console.log("filePath", filePath);
  //     console.log("fileName", fileName);
  //     console.log("correctedPath", correctedPath);
  //     console.log("fullUrl", fullUrl);

  //     saveAs(fullUrl, fileName || correctedPath);
  //     toast({
  //       title: t("koreport.downloadSuccess"),
  //       description: fileName || correctedPath,
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } catch (err) {
  //     console.error("File download error:", err);
  //     toast({
  //       title: t("koreport.error"),
  //       description: t("koreport.downloadError"),
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // CUSTOM
  const DataEntry = ({ label, value, isCapitalized }) => {
    return (
      <Flex direction="column">
        <Text
          fontSize="0.8rem"
          fontWeight="500"
          color={textSecondary}
          m="0 0 0.25rem"
        >
          {label}
        </Text>
        <Text
          fontSize="15px"
          fontWeight="500"
          color={textPrimary}
          textTransform={isCapitalized ? "capitalize" : "none"}
          // bg={backgroundLight}
          // borderRadius="1rem"
          // p="0.75rem 1rem"
        >
          {value}
        </Text>
      </Flex>
    );
  };

  // USE EFFECT

  useEffect(() => {
    if (data && data.address) {
      const { street, city, zip, country } = data.address;
      const addressParts = [street, city, zip, country].filter(Boolean);
      setWorkAddress(addressParts.join(", "));
    } else {
      setWorkAddress("-");
    }
  }, [data]);

  // LOGS
  // console.log("data", data);

  return (
    <Flex w="100%" direction="column">
      {/* FAIL FORM & DEALER / SUBSIDIARY INFORMATION */}
      <Box w="100%">
        <Heading
          fontSize="md"
          fontWeight="600"
          color={textPrimary}
          m="0 0 0.75rem"
        >
          {t("koreport.dealerSubsidiaryInfo")}
        </Heading>
        <Flex
          w="100%"
          direction="column"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="1.5rem"
        >
          <Flex w="100%" gap="1rem">
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry
                label={t("koreport.reportedDate")}
                value={
                  data.surgeryDate === null
                    ? "-"
                    : dayjs(data.surgeryDate).format("D MMMM, YYYY")
                }
              />
              <DataEntry
                label={t("koreport.failListNo")}
                value={data.trackingNumber ? data.trackingNumber : "-"}
              />
            </Flex>
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry label={t("form.country")} value="Lithuania" />
              <DataEntry
                label={t("form.email")}
                value="zilvinas@megagenbaltics.com"
              />
            </Flex>
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry
                label={t("koreport.personInCharge")}
                value="Zilvinas Zickus"
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {/* CLINIC AND FAIL INFORMATION */}
      <Box w="100%" m="3rem 0 0">
        <Heading
          fontSize="md"
          fontWeight="600"
          color={textPrimary}
          m="0 0 0.75rem"
        >
          {t("koreport.clinicFailInfo")}
        </Heading>
        <Flex
          w="100%"
          direction="column"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="1.5rem"
        >
          <Flex w="100%">
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry
                label={t("implantForm.clinicName")}
                value={data.clinicName ? data.clinicName : "-"}
              />
              <DataEntry
                label={t("koreport.dentistName")}
                value={
                  data.surgeonFirstName + " " + data.surgeonLastName
                    ? data.surgeonFirstName + " " + data.surgeonLastName
                    : "-"
                }
              />
              <DataEntry
                label={t("form.email")}
                value={data.email ? data.email : "-"}
              />
            </Flex>
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry
                label={t("implantForm.tooth")}
                value={data.tooth ? data.tooth : "-"}
              />
              <DataEntry
                label={t("implantForm.surgeryDate")}
                value={
                  data.surgeryDate === null
                    ? "-"
                    : dayjs(data.surgeryDate).utc().format("YYYY-MM-DD")
                }
              />
              <DataEntry
                label={t("implantForm.failureDate")}
                value={
                  data.surgeryDate === null
                    ? "-"
                    : dayjs(data.surgeryDate).utc().format("YYYY-MM-DD")
                }
              />
            </Flex>
            <Flex w="25%" direction="column" gap="1.5rem">
              <DataEntry
                label={t("implantForm.fixture")}
                value={t(`implants.${data.fixture}`)}
              />
              <DataEntry
                label={t("implantForm.refCode")}
                value={data.refCode}
              />
              <DataEntry
                label={t("implantFailureReports.lotNumberAlt")}
                value={data.lotNumber}
              />
            </Flex>
          </Flex>
          <Box m="1.5rem 0 0">
            <DataEntry
              label={t("koreport.patientRiskInfo")}
              value={data.reason ? data.reason : "-"}
              isCapitalized
            />
          </Box>
        </Flex>
      </Box>

      {/* IMAGES */}
      <Box w="100%" m="3rem 0 0">
        <Heading
          fontSize="md"
          fontWeight="600"
          color={textPrimary}
          m="0 0 0.75rem"
        >
          {t("implantForm.step3Title")}
        </Heading>
        <Flex
          w="100%"
          direction="column"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="1.5rem"
        >
          <Flex w="100%" gap="2rem">
            <Flex direction="column" alignItems="center">
              {data.beforeXrayImage === "" ? (
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  p="1.5rem 1rem"
                >
                  <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                  <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                    {t("implantFailureReports.noXrayImage")}
                  </Text>
                </Flex>
              ) : (
                <Flex direction="column">
                  <Text
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                    mb={2}
                  >
                    {t("implantFailureReports.xRayBefore")}
                  </Text>
                  <Flex
                    w="100%"
                    maxH="200px"
                    borderRadius="1rem"
                    overflow="hidden"
                  >
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      src={data.beforeXrayImage}
                      alt="xray"
                    />
                  </Flex>
                  <Box m="1.5rem 0 0">
                    <Button
                      bg={textPrimary}
                      color={white}
                      borderRadius="10rem"
                      size="md"
                      fontSize="xs"
                      _hover={{ bg: primary }}
                      onClick={() => downloadFileHandler(data?.beforeXrayImage)}
                    >
                      {t("record.downloadImage")}
                    </Button>
                  </Box>
                </Flex>
              )}
            </Flex>

            <Flex direction="column" alignItems="center">
              {data.afterXrayImage === "" ? (
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  p="1.5rem 1rem"
                >
                  <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                  <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                    {t("implantFailureReports.noXrayImage")}
                  </Text>
                </Flex>
              ) : (
                <Flex direction="column">
                  <Text
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                    mb={2}
                  >
                    {t("implantFailureReports.xRayAfter")}
                  </Text>
                  <Flex
                    w="100%"
                    maxH="200px"
                    borderRadius="1rem"
                    overflow="hidden"
                  >
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      src={data.afterXrayImage}
                      alt="xray"
                    />
                  </Flex>
                  <Box m="1.5rem 0 0">
                    <Button
                      bg={textPrimary}
                      color={white}
                      borderRadius="10rem"
                      size="md"
                      fontSize="xs"
                      _hover={{ bg: primary }}
                      onClick={() => downloadFileHandler(data?.afterXrayImage)}
                    >
                      {t("record.downloadImage")}
                    </Button>
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ReportInfoKorea;
