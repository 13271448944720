import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Heading,
  Button,
  Stack,
  useToast,
  Icon,
} from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import {
  primary,
  newBorder,
  textPrimary,
  textSecondary,
  white,
  buttonRedText,
  buttonRed,
  greenVivid,
  buttonYellowText,
} from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import { useTranslation } from "react-i18next";
import MoreButton from "../../../../components/buttons/MoreButton";
// import ToastComponent from "../../../../components/partials/ToastComponent";
import ReportInfo from "./partials/ReportInfoKorea";
import {
  getImplantFailureReportById,
  updateImplantFailureReportStatus,
  deleteImplantFailureReport,
} from "../../../../actions/reportActions";
import { shade } from "polished";
import dayjs from "dayjs";
import ConfirmRejectModal from "../../../../components/modals/ConfirmRejectModal";
import ConfirmApproveModal from "../../../../components/modals/ConfirmApproveModal";
import ConfirmClosedModal from "../../../../components/modals/ConfirmClosedModal";
import {
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET,
  REPORT_IMPLANT_FAILURE_DELETE_RESET,
} from "../../../../constants/reportConstants";
import ReportInfoKorea from "./partials/ReportInfoKorea";
import { TbFileTypePdf } from "react-icons/tb";
import { saveAs } from "file-saver";
import {
  getRecordCustomIdAction,
  downloadFileAction,
} from "../../../../actions/recordActions";

const SingleImplantFailureReportKorea = () => {
  // GENERAL
  // const id = useParams().id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const { recordId, id } = useParams();

  // STATE
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const downloadFile = useSelector((state) => state.downloadFile);
  const { loading, success } = downloadFile;

  const reportImplantFailureDetailsReducer = useSelector(
    (state) => state.reportImplantFailureDetails
  );

  const {
    error: errorImplantFailureReportDetails,
    loading: loadingImplantFailureReportDetails,
    report,
  } = reportImplantFailureDetailsReducer;

  const reportImplantFailureUpdateStatus = useSelector(
    (state) => state.reportImplantFailureUpdateStatus
  );

  const {
    error: errorImplantFailureUpdateStatus,
    loading: loadingImplantFailureUpdateStatus,
    success: successImplantFailureUpdateStatus,
  } = reportImplantFailureUpdateStatus;

  const reportImplantFailureDelete = useSelector(
    (state) => state.reportImplantFailureDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = reportImplantFailureDelete;

  const getRecordCustomId = useSelector((state) => state.getRecordCustomId);
  const { recordCustomId } = getRecordCustomId;

  // HANDLERS

  const downloadFileHandler = (filename) => {
    console.log("Filename:", filename);
    if (!filename) {
      toast({
        title: t("koreport.error"),
        description: t("koreport.fileNotFound"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    console.log("Filename:", filename);

    // Remove the `/uploads/` prefix if it exists
    const correctedFilename = filename.startsWith("/uploads/")
      ? filename.replace(/^\/uploads\//, "")
      : filename;

    console.log("Original filename:", filename);
    console.log("Corrected filename:", correctedFilename);

    dispatch(downloadFileAction(correctedFilename));
  };

  const rejectReportHandler = () => {
    const status = "rejected";
    console.log("id", id);
    console.log("userInfo._id", userInfo._id);
    console.log("cancelReason", cancelReason);
    console.log("status", status);

    if (cancelReason === "other") {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, otherReason, status)
      );
    } else {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
      );
    }
  };

  const approveReportHandler = () => {
    const status = "approved";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
    );
  };

  const closeReportHandler = () => {
    const status = "closed";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, documentNumber, status)
    );
  };

  const deleteCourseHandler = () => {
    dispatch(deleteImplantFailureReport(id));
  };

  // USE EFFECT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!recordCustomId) {
      dispatch(getRecordCustomIdAction(recordId));
    }
  }, [recordCustomId]);

  useEffect(() => {
    if (!report || report._id !== id) {
      dispatch(getImplantFailureReportById(id));
    }
  }, [report, successImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.reportUpdated"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      dispatch({ type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET });
      dispatch(getImplantFailureReportById(id));
    } else if (errorImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successImplantFailureUpdateStatus, errorImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successDelete) {
      toast({
        title: t("implantFailureReports.reportDeleted"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
      navigate("/admin/reports/implant-failure-reports");
    } else if (errorDelete) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successDelete, errorDelete]);

  // LOGS
  console.log("report", report);
  console.log("recordCustomId", recordCustomId);

  console.log("recordId", recordId);
  console.log("id", id);

  // RENDER
  return (
    <>
      {showCancelModal && (
        <ConfirmRejectModal
          report={report}
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          confirmAction={() => {
            rejectReportHandler();
            setShowCancelModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
          otherReason={otherReason}
          setOtherReason={setOtherReason}
        />
      )}

      {showApproveModal && (
        <ConfirmApproveModal
          report={report}
          isOpen={showApproveModal}
          onClose={() => setShowApproveModal(false)}
          confirmAction={() => {
            approveReportHandler();
            setShowApproveModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
        />
      )}

      {showCloseModal && (
        <ConfirmClosedModal
          report={report}
          isOpen={showCloseModal}
          onClose={() => setShowCloseModal(false)}
          confirmAction={() => {
            closeReportHandler();
            setShowCloseModal(false);
          }}
          documentNumber={documentNumber}
          setDocumentNumber={setDocumentNumber}
        />
      )}

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={t("implantFailureReports.singleTitle")}
              type={report.trackingNumber}
              //   loading={loading}
            >
              {" "}
              <Button
                size="md"
                fontSize="sm"
                bg={textPrimary}
                color={white}
                borderRadius="0.875rem"
                mr="1rem"
                _hover={{
                  bg: primary,
                }}
                onClick={() =>
                  downloadFileHandler(`${report.trackingNumber}.pdf`)
                }
              >
                <Icon as={TbFileTypePdf} fontSize="1rem" mr={2} />
                {t("newTranslation.downloadPdf")}
              </Button>
              <StatusButton
                isUnclickable
                currentStatus={report.status}
                //  onUpdateStatus={handleUpdateStatus}
                options="report"
              />
              {/* <MoreButton
                id={id}
                isBig
                deleteHandler={deleteCourseHandler}
                deleteLabel={t("implantFailureReports.deleteReport")}
                // editHandler={(e) => {
                //   e.stopPropagation();
                //   openEditSidePanel(courseId);
                // }}
              /> */}
            </SinglePageTopBar>
            {!loadingImplantFailureReportDetails ? (
              <>
                <Flex direction="column" w="100%">
                  <Flex
                    gap={0}
                    p="2.5rem 2.5rem 1.5rem 2.5rem"
                    alignItems="flex-start"
                    justifyContent="center"
                    w="100%"
                  >
                    <Flex direction="column" w="100%">
                      <ReportInfoKorea
                        data={report}
                        recordCustomId={recordCustomId}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p="8rem 2rem">
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default SingleImplantFailureReportKorea;
