import axios from "axios";
import {
  CREATE_RECORD_REQUEST,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_FAIL,
  GET_RECORDS_REQUEST,
  GET_RECORDS_SUCCESS,
  GET_RECORDS_FAIL,
  GET_RECORD_DETAILS_REQUEST,
  GET_RECORD_DETAILS_SUCCESS,
  GET_RECORD_DETAILS_FAIL,
  DELETE_RECORD_REQUEST,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS,
  GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL,
  GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST,
  GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS,
  GET_PUBLIC_RECORD_FULL_DETAILS_FAIL,
  GENERATE_REPORT_FILES_REQUEST,
  GENERATE_REPORT_FILES_SUCCESS,
  GENERATE_REPORT_FILES_FAIL,
  SEND_RECORD_REQUEST,
  SEND_RECORD_SUCCESS,
  SEND_RECORD_FAIL,
  GET_RECORD_REPORTS_REQUEST,
  GET_RECORD_REPORTS_SUCCESS,
  GET_RECORD_REPORTS_FAIL,
  DOWNLOAD_RECORD_FILE_REQUEST,
  DOWNLOAD_RECORD_FILE_SUCCESS,
  DOWNLOAD_RECORD_FILE_FAIL,
  GET_RECORD_CUSTOMID_REQUEST,
  GET_RECORD_CUSTOMID_SUCCESS,
  GET_RECORD_CUSTOMID_FAIL,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE_RESET,
} from "../constants/recordConstants";

// Create a new record
export const createRecordAction =
  (personInCharge, email, dealer, country, startDate, endDate) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_RECORD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/records`,
        { personInCharge, email, dealer, country, startDate, endDate },
        config
      );

      dispatch({ type: CREATE_RECORD_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_RECORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get all records
export const getRecordsListAction =
  (searchTerm = "", page = 1, pageSize = 10, sortField = "", sortOrder = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_RECORDS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string
      let query = `/api/records?`;
      query += `searchTerm=${encodeURIComponent(searchTerm)}`;
      query += `&pageNumber=${page}`;
      query += `&pageSize=${pageSize}`;
      if (sortField) query += `&sortField=${sortField}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: GET_RECORDS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_RECORDS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get record details by ID
export const getRecordDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_RECORD_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/records/${id}`, config);

    console.log("DATA", data);

    dispatch({ type: GET_RECORD_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_RECORD_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a record by ID
export const deleteRecordAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_RECORD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/records/${id}`, config);

    dispatch({ type: DELETE_RECORD_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get minimal record details (public metadata)
export const getMinimalRecordDetailsHqAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PUBLIC_RECORD_MINIMAL_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/records/hq/reports/${id}`);

    dispatch({
      type: GET_PUBLIC_RECORD_MINIMAL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PUBLIC_RECORD_MINIMAL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get full record details
export const getFullRecordDetailsHqAction =
  (id, email, verificationCode) => async (dispatch) => {
    try {
      dispatch({ type: GET_PUBLIC_RECORD_FULL_DETAILS_REQUEST });

      const { data } = await axios.post(`/api/records/hq/reports/full/${id}`, {
        id,
        email,
        verificationCode,
      });

      dispatch({ type: GET_PUBLIC_RECORD_FULL_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_PUBLIC_RECORD_FULL_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const generateReportFilesAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERATE_REPORT_FILES_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log("USERINFO", userInfo);

    console.log("ACTION GENERATE FILES", id);

    const { data } = await axios.post(
      `/api/records/generate-files/${id}`,
      {},
      config
    );

    console.log("DATA FROM API", data); // Log API response to verify

    dispatch({
      type: GENERATE_REPORT_FILES_SUCCESS,
      payload: data,
    }); // Pass zipFilePath
  } catch (error) {
    dispatch({
      type: GENERATE_REPORT_FILES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendRecordAction =
  (recordId, recipientEmail, link, language, sender) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SEND_RECORD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // id in URL, not body
      await axios.post(
        `/api/records/send/${recordId}`,
        { recordId, recipientEmail, link, language, sender }, // Only these fields in body
        config
      );

      dispatch({ type: SEND_RECORD_SUCCESS });
    } catch (error) {
      dispatch({
        type: SEND_RECORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
// Get record reports with search and sorting
export const getRecordReportsAction =
  (
    id,
    page = 1,
    pageSize = 10,
    sortField = "createdAt",
    sortOrder = "desc",
    keyword = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_RECORD_REPORTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct query parameters for search and sorting
      const queryParams = new URLSearchParams({
        page,
        pageSize,
        sortField,
        sortOrder,
        keyword,
      }).toString();

      console.log("QUERY PARAMS", queryParams);

      const { data } = await axios.get(
        `/api/records/${id}/reports?${queryParams}`,
        config
      );

      console.log("RECORD REPORTS DATA", data);

      dispatch({
        type: GET_RECORD_REPORTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_RECORD_REPORTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// export const downloadRecordFileAction =
//   (recordId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: DOWNLOAD_RECORD_FILE_REQUEST });

//       // no auth
//       const { data } = await axios.get(`/api/records/download/${recordId}`);

//       console.log("DATA", data);

//       dispatch({ type: DOWNLOAD_RECORD_FILE_SUCCESS, payload: data });
//     } catch (error) {
//       dispatch({
//         type: DOWNLOAD_RECORD_FILE_FAIL,
//         payload:
//           error.response && error.response.data.message
//             ? error.response.data.message
//             : error.message,
//       });
//     }
//   };

export const downloadRecordFileAction = (recordId) => async (dispatch) => {
  try {
    dispatch({ type: DOWNLOAD_RECORD_FILE_REQUEST });

    // Set responseType to "blob" for file download
    const response = await axios.get(`/api/records/download/${recordId}`, {
      responseType: "blob",
    });

    // Extract the filename from Content-Disposition
    const contentDisposition = response.headers["content-disposition"];
    let filename = `${recordId}-files.zip`; // Default filename
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match && match[1]) {
        filename = match[1];
      }
    }

    // Create a Blob and initiate the file download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // Use the extracted filename
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch({ type: DOWNLOAD_RECORD_FILE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DOWNLOAD_RECORD_FILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRecordCustomIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_RECORD_CUSTOMID_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/records/${id}/customId`, config);

    dispatch({ type: GET_RECORD_CUSTOMID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_RECORD_CUSTOMID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const downloadFileAction = (filename) => async (dispatch) => {
  try {
    dispatch({ type: DOWNLOAD_FILE_REQUEST });

    // Set responseType to "blob" for file download
    const response = await axios.get(`/api/records/download-file/${filename}`, {
      responseType: "blob",
    });

    // Create a Blob and initiate the file download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch({ type: DOWNLOAD_FILE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DOWNLOAD_FILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
