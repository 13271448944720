import React from "react";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import {
  backgroundLight,
  primary,
  textPrimary,
} from "../../../../utils/colors";
import LanguageButtonLocal from "../../../../components/buttons/LanguageButtonLocal";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const SendRecordMessage = ({
  recordDetails,
  language,
  handleChangeLanguage,
}) => {
  const { t } = useTranslation();

  // CUSTOM
  // Determine the base URL based on the environment
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "http://mymegagen.com"
      : "http://localhost:3000";

  // Construct the full record link
  const recordLink = `${baseUrl}/reports/hq/${recordDetails._id}`;

  // LOG
  //   console.log("recordDetails", recordDetails);

  return (
    <Flex
      bg={backgroundLight}
      p="1.5rem"
      borderRadius="1rem"
      position="relative"
    >
      <Box position="absolute" right="1rem" top="1rem">
        <LanguageButtonLocal
          currentLanguage={language}
          onChangeLanguage={handleChangeLanguage}
          languages={["en", "lt", "ko"]}
          onMenuClick={() => {}}
        />
      </Box>

      {language === "en" && (
        <Flex direction="column" justify="flex-start" align="flex-start">
          <Text
            fontWeight="500"
            color={textPrimary}
            fontSize="sm"
            pr="6rem"
            lineHeight="1.6"
          >
            Hello, <br />
            We have prepared a report on failed implants -{" "}
            {recordDetails.customId}.
            <br />
            Period: {dayjs(recordDetails.startDate)
              .utc()
              .format("YYYY-MM-DD")}{" "}
            - {dayjs(recordDetails.endDate).utc().format("YYYY-MM-DD")}.
          </Text>

          <Flex align="center" m="0.5rem 0 0">
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textPrimary}
              pr={1}
              whiteSpace="nowrap"
            >
              Report link:
            </Text>
            {/* <Link to={`/hq/reports/${recordDetails._id}`} target="_blank">
              <Text
                fontSize="sm"
                color={primary}
                fontWeight="500"
                maxW="80%"
                isTruncated
              >
                {recordLink}
              </Text>
            </Link> */}

            <Tooltip
              p="0.5rem 1rem"
              fontSize="xs"
              borderRadius="1rem"
              placement="top"
              label={t("newTranslation.linkTooltip")}
            >
              <span>
                <Text
                  fontSize="sm"
                  color={primary}
                  fontWeight="500"
                  maxW="80%"
                  isTruncated
                >
                  {recordLink}
                </Text>
              </span>
            </Tooltip>
          </Flex>

          <Tooltip
            p="0.5rem 1rem"
            borderRadius="1rem"
            fontSize="xs"
            placement="top"
            label={t("record.pinTooltip")}
          >
            <span>
              <Box align="center" m="0rem 0 0">
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textPrimary}
                    pr={1}
                    whiteSpace="nowrap"
                  >
                    PIN:
                  </Text>
                  <Text fontSize="sm" color={textPrimary} fontWeight="500">
                    ****
                  </Text>
                </Flex>
              </Box>
            </span>
          </Tooltip>
        </Flex>
      )}

      {language === "lt" && (
        <Flex direction="column" justify="flex-start" align="flex-start">
          <Text
            fontWeight="500"
            color={textPrimary}
            fontSize="sm"
            pr="6rem"
            lineHeight="1.6"
          >
            Sveiki, <br />
            Paruošėme neprigijusių implantų ataskaitą - {recordDetails.customId}
            .
            <br />
            Laikotarpis:{" "}
            {dayjs(recordDetails.startDate).utc().format("YYYY-MM-DD")} -{" "}
            {dayjs(recordDetails.endDate).utc().format("YYYY-MM-DD")}.
          </Text>

          <Flex align="center" m="0.5rem 0 0">
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textPrimary}
              pr={1}
              whiteSpace="nowrap"
            >
              Ataskaitos nuoroda:
            </Text>
            {/* <Link to={`/hq/reports/${recordDetails._id}`} target="_blank">
              <Text
                fontSize="sm"
                color={primary}
                fontWeight="500"
                maxW="80%"
                isTruncated
              >
                {recordLink}
              </Text>
            </Link> */}
            <Tooltip
              p="0.5rem 1rem"
              fontSize="xs"
              borderRadius="1rem"
              placement="top"
              label={t("newTranslation.linkTooltip")}
            >
              <span>
                <Text
                  fontSize="sm"
                  color={primary}
                  fontWeiht="500"
                  maxW="80%"
                  isTruncated
                >
                  {recordLink}
                </Text>
              </span>
            </Tooltip>
          </Flex>
          <Tooltip
            p="0.5rem 1rem"
            borderRadius="1rem"
            fontSize="xs"
            placement="top"
            label={t("record.pinTooltip")}
          >
            <span>
              <Box align="center" m="0rem 0 0">
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textPrimary}
                    pr={1}
                    whiteSpace="nowrap"
                  >
                    PIN:
                  </Text>
                  <Text fontSize="sm" color={textPrimary} fontWeight="500">
                    ****
                  </Text>
                </Flex>
              </Box>
            </span>
          </Tooltip>
        </Flex>
      )}

      {language === "ko" && (
        <Flex direction="column" justify="flex-start" align="flex-start">
          <Text
            fontWeight="500"
            color={textPrimary}
            fontSize="sm"
            pr="6rem"
            lineHeight="1.6"
          >
            안녕하세요, <br />
            임플란트 실패 보고서를 준비했습니다 - {recordDetails.customId}
            .
            <br />
            기간: {dayjs(recordDetails.startDate)
              .utc()
              .format("YYYY-MM-DD")} -{" "}
            {dayjs(recordDetails.endDate).utc().format("YYYY-MM-DD")}.
          </Text>

          <Flex align="center" m="0.5rem 0 0">
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textPrimary}
              pr={1}
              whiteSpace="nowrap"
            >
              보고서 링크:
            </Text>
            {/* <Link to={`/hq/reports/${recordDetails._id}`} target="_blank">
              <Text
                fontSize="sm"
                color={primary}
                fontWeight="500"
                maxW="80%"
                isTruncated
              >
                {recordLink}
              </Text>
            </Link> */}
            <Tooltip
              p="0.5rem 1rem"
              fontSize="xs"
              borderRadius="1rem"
              placement="top"
              label={t("newTranslation.linkTooltip")}
            >
              <span>
                <Text
                  fontSize="sm"
                  color={primary}
                  fontWeight="500"
                  maxW="80%"
                  isTruncated
                >
                  {recordLink}
                </Text>
              </span>
            </Tooltip>
          </Flex>

          <Tooltip
            p="0.5rem 1rem"
            borderRadius="1rem"
            fontSize="xs"
            placement="top"
            label={t("record.pinTooltip")}
          >
            <Box align="center" m="0rem 0 0">
              <Flex align="center">
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textPrimary}
                  pr={1}
                  whiteSpace="nowrap"
                >
                  PIN:
                </Text>
                <Text fontSize="sm" color={textPrimary} fontWeight="500">
                  ****
                </Text>
              </Flex>
            </Box>
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
};

export default SendRecordMessage;
